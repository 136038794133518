import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Router from '@router/Router';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';
import { GlobalProvider } from '@components/Context/GlobalProvider';
import { I18nextProvider } from 'react-i18next';
import { getEmiCommandLineParameterAPI } from '@action/emi/emiAction';
import i18n from './i18n/index';

import { LanguageProvider, MainContainer, ModalProvider } from './components';
import theme from './styles/Themes';
import GlobalStyle from './styles/GlobalStyle';
import GlobalFont from './styles/GlobalFont';

const router = createBrowserRouter(Router);
// Nginx 사용할 수 없는 환경에서 HashRouter 사용
// const router = createHashRouter(Router);

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const param = {};
    getEmiCommandLineParameterAPI(dispatch, param);
  }, []);

  return (
    <ThemeProvider theme={theme.light}>
      <GlobalProvider>
        <LanguageProvider>
          <I18nextProvider i18n={i18n}>
            <ModalProvider>
              <GlobalStyle />
              <GlobalFont />
              <MainContainer>
                <RouterProvider router={router} />
              </MainContainer>
            </ModalProvider>
          </I18nextProvider>
        </LanguageProvider>
      </GlobalProvider>
    </ThemeProvider>
  );
};
export default App;
