import { createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleCurrentIndex = (index) => {
    setCurrentIndex(index);
  };


  const contextValue = useMemo(() => {
    return { currentIndex, handleCurrentIndex };
  }, [currentIndex]); 

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
};

GlobalProvider.propTypes = {
    children: PropTypes.node,
  };

export default GlobalContext;