import { useState, useCallback, useMemo, useEffect, useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import axios from 'axios';
import styled, { css } from 'styled-components';
import { createColumnHelper } from '@tanstack/react-table';

import { BASE_URL, HEADER } from '@TAxiosConfig';
import { setNfOriginalData, setDebuggingFrqValue, setEmiFileMetaData } from '@reducer/emiSlice';
import { Div, ModalContext, Table } from '@components/index';
import EmiHeatMap from '@SAM/components/Chart/EmiHeatMap';
import EmiDebuggingLineChart from '@SAM/components/Chart/EmiDebuggingLineChart';
import ErrorModal from '@SAM/components/Modal/ErrorModal';
import SideControllerDebug from './module/DebuggingSideController';

const columnHelper = createColumnHelper();

const MainContainer = styled(Div)`
  display: flex;
  background-color: #000000;
  width: 100%;
`;

const DataArea = styled(Div)`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: calc(100vw - 330px);
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
`;
const FlexRow = styled(Div)`
  display: flex;
  gap: 10px;
`;
const HiddenInput = styled.input`
  display: none;
`;
const FirstGraph = styled(Div)`
  display: flex;
  flex: 1;
  width: 100%;
`;
const ChartArea = styled(Div)`
  display: flex;
  flex-direction: column;
`;
const YAxis = styled(Div)`
  width: 30px;
  height: 100%;
  background-color: #1d1d1b;
  border: 1px solid #444444;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const YName = styled(Div)`
  transform: rotate(270deg);
  transform-origin: left top 50;
  color: #f9f9f9;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Pretendard';
  white-space: nowrap;
`;
const XAxis = styled(Div)`
  display: flex;
  width: 100%;
  height: 25px;
  background-color: #2a2a2a;
  border-top: 1px solid #444444;
  justify-content: center;
  align-items: center;
  color: #f9f9f9;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Pretendard';
  white-space: nowrap;
`;

const ScrollXContainer = styled(Div)`
  display: block;
  width: 100%;
  max-height: 395px;
  overflow-y: auto;
  white-space: nowrap;
  background-color: #252523;
  padding-right: 10px;

  &::-webkit-scrollbar {
    width: 10px;
    background-color: #343434;
    border-radius: 100px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #366eff;
    width: 10px;
    height: 35px;
    border-radius: 10px;
  }
`;

const TableScrollX = css`
  display: inline-block;
`;
const StickyHeader = css`
  display: sticky;
  top: 0;
  tr {
    background-color: #1d1d1b;
    color: #fff;
  }
`;

const ScrollXBodyContainer = css`
  display: block;
  min-height: 390px;
  overflow-y: auto;
  background-color: #252523;
  &::-webkit-scrollbar {
    width: 10px;
    background-color: #343434;
    border-radius: 100px;
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #21b9fe;
    width: 10px;
    height: 35px;
    border-radius: 10px;
  }
`;

const FORM_KEYS = Object.freeze({
  ETA: 'ETA',
  Weight: 'Weight',
  NPeak: 'NPeak',
  ThresholdUpper: 'ThresholdUpper',
  ThresholdLower: 'ThresholdLower',
  Frq: 'Frq',
  Level: 'Level',
  NF: 'NF',
  FV: 'FV',
  FH: 'FH',
  GV: 'GV',
  GH: 'GH',
  NFIMPORT: 'NFIMPORT',
});

const EmiDebugging = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const searchParamsMasterId = searchParams.get('masterId');
  const { showModal } = useContext(ModalContext);

  const nfOriginalData = useSelector((state) => state.emi.nfOriginalData);
  const [levelByNvName, setLevelByNvName] = useState(null);
  const [clickedData, setClickedData] = useState(null);
  const tableRef = useRef(null);

  const rowSelection = tableRef.current && tableRef.current.remoteControll;
  const methods = useForm();

  const [fileKey, setFileKey] = useState(0); // 동일한 file을 업로드 할 경우 input의 onChange가 호출되지 않아 추가함

  const handleFileChange = useCallback(
    (event) => {
      const file = event.target.files;
      const reader = new FileReader();
      reader.onload = () => {
        dispatch(setEmiFileMetaData(file));
      };
      reader.readAsText(file[0]);
    },
    [fileKey]
  );

  const emiData = useMemo(() => {
    let NF = [];
    const FF = [];
    const GT = [];
    const TableData = [];
    if (levelByNvName) {
      NF = levelByNvName;
    } else {
      nfOriginalData?.forEach((data) => {
        const frq = Number(data.frequency);
        const scannerNvMax = Number(data.nv_max);
        NF.push([frq, scannerNvMax]);
        TableData.push({ frq, FF, passFail: '', GT, NF: scannerNvMax });
      });
    }

    return { NF, TableData };
  }, [nfOriginalData, levelByNvName]);

  /* eslint-disable react/no-unstable-nested-components */
  const COLUMNS_DATA = useMemo(() => {
    let ret = [];
    if (Array.isArray(nfOriginalData) && nfOriginalData.length > 0) {
      const firstObj = nfOriginalData[0];
      ret = Object.keys(firstObj).map((item) => {
        return columnHelper.accessor(item, {
          id: item,
          header: () => <span>{item}</span>,
          cell: ({ getValue }) => {
            return <span>{getValue()}</span>;
          },
        });
      });
    }
    return ret;
  }, [nfOriginalData]);

  const handleNVLevel = useCallback((arr) => {
    setLevelByNvName(arr);
  }, []);
  const handleClickedData = useCallback((row) => {
    setClickedData(row);
  }, []);

  useEffect(() => {
    return () => dispatch(setDebuggingFrqValue({ frq: 0, id: null }));
  }, []);

  useEffect(() => {
    if (searchParamsMasterId) {
      axios
        .get(
          `${BASE_URL}/api/emi/getEMIScannerDataByMasterId`,
          { params: { masterId: searchParamsMasterId } },
          HEADER.GET
        )
        .then((nfDataResponse) => {
          const nfOriginal = { ...nfDataResponse.data.body };

          // Function to replace positions with sequential numbers
          const replaceWithSequentialNumbers = (positions) => {
            const posArray = positions
              .split(',')
              .map((str) => str.trim())
              .filter((str) => str.length > 0);
            const posMap = new Map();
            let counter = 0;

            return posArray.map((pos) => {
              if (!posMap.has(pos)) {
                posMap.set(pos, counter);
                counter++;
              }
              return posMap.get(pos);
            });
          };

          const xPosReplaced = replaceWithSequentialNumbers(nfOriginal.emiCoordinate.x_pos);
          const yPosReplaced = replaceWithSequentialNumbers(nfOriginal.emiCoordinate.y_pos);

          const coordinates = xPosReplaced.map((x, index) => [x, yPosReplaced[index]]);
          nfOriginal.coordinates = coordinates;
          const ret = nfOriginal.emiScannerDataList.map((item) => {
            const arr = item.nv_data
              .split(',')
              .map((str) => str.trim())
              .filter((str) => str.length > 0);
            const nvObj = arr.reduce((obj, value, index) => {
              obj[`nv${index + 1}`] = value;
              return obj;
            }, {});
            return { ...{ frequency: item.frequency, nv_max: item.nv_max }, ...nvObj };
          });
          nfOriginal.emiScannerDataList = ret;
          dispatch(setNfOriginalData(nfOriginal));
        })
        .catch((err) => {
          showModal(<ErrorModal errorMessage={err.response?.data?.message ?? ''} />);
        });
    }
  }, [searchParamsMasterId]);
  return (
    <FormProvider {...methods}>
      <MainContainer>
        <HiddenInput
          key={fileKey}
          id='nf-import'
          name={FORM_KEYS}
          type='file'
          accept='.csv'
          onChange={(e) => {
            handleFileChange(e);
            setFileKey((prevKey) => prevKey + 1);
          }}
        />
        <SideControllerDebug handleClickedData={handleClickedData} />
        <DataArea>
          <FlexRow>
            <EmiHeatMap emiData={nfOriginalData} clickedData={clickedData} handleNVLevel={handleNVLevel} />

            <FirstGraph>
              <YAxis>
                <YName>Near-Field [dB(uV)]</YName>
              </YAxis>
              <ChartArea>
                <EmiDebuggingLineChart
                  id='EMI_line_debug'
                  lineData={emiData.NF}
                  rowSelection={rowSelection}
                  handleClickedData={handleClickedData}
                />
                <XAxis>Frequency [Mhz]</XAxis>
              </ChartArea>
            </FirstGraph>
          </FlexRow>
          <ScrollXContainer>
            <Table
              ref={tableRef}
              data={nfOriginalData ?? []}
              columns={COLUMNS_DATA}
              classes={{ Container: TableScrollX, THead: StickyHeader, TBody: ScrollXBodyContainer }}
              hasTableInfo={false}
              hasPagenation={false}
              hasPageSizeOption={false}
              handleClickedData={handleClickedData}
            />
          </ScrollXContainer>
        </DataArea>
      </MainContainer>
    </FormProvider>
  );
};

EmiDebugging.propTypes = {};

export default EmiDebugging;
