import { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import styled, { css } from 'styled-components';

import Modal from '@components/Context/Modal';
import Input from '@components/Input/Input/Input';
import Button from '@components/Input/Button/Button';
import { LoginBg } from '@images/index';
import { doLoginAPI } from '@action/user/loginAction';
import { Div, Img, ModalContext, Text } from '@components';
import i18n from '@i18n';
import ErrorModal from '@SAM/components/Modal/ErrorModal';
import JoinModal from './JoinModal';
import FindPasswordModal from './FindPasswordModal';

const LoginContainer = styled(Div)`
  display: flex;
  row-gap: 20px;
  width: 800px;
  height: 240px;
`;

const InputFormStyle = css`
  background-color: #363636;
  color: #c1c1c1;
  height: 35px;
  border-radius: 5px;
  border: 0px;
`;

const ImgContainer = styled(Div)`
  position: relative;

  color: #fff;
  background: linear-gradient(#c945db, #fc2574);
`;
const LoginText = styled(Text)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 45px;
  font-weight: 600;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #262626;
  row-gap: 10px;
`;

const LoginImg = styled(Img)`
  width: 300px;
  height: 240px;
  opacity: 0.15;
`;

const LoginButton = styled(Button)`
  background-color: #20b9fd;
  height: 40px;
  text-decoration: none;
  border-radius: 5px;
  padding: 16px 0;
  color: #fff;

  &:hover {
    text-decoration: none;
    color: #fff;
  }
`;

const BGColor = css`
  background-color: #262626;
  flex: 1;
  padding: 0;
`;

const UserAccessContainer = styled(Div)`
  display: flex;
`;

const Join = styled(Div)`
  color: #ccc;
  font-size: 14px;
  &:hover {
    color: white;
    font-weight: 700;
  }
`;

const FindPassword = styled(Div)`
  color: #ccc;
  font-size: 14px;
  &:hover {
    color: white;
    font-weight: 700;
  }
`;

const BottomButton = styled(Button)`
  text-decoration: none;
  font-size: 13px;

  &:hover {
    text-decoration: none;
    color: #fff;
  }
`;

const FORM_KEYS = Object.freeze({
  ID: 'id',
  PASSWORD: 'password',
});

const VALIDATION_REGEXES = {
  EMAIL: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,
};

const schema = yup.object().shape({
  [FORM_KEYS.ID]: yup.string().matches(VALIDATION_REGEXES.EMAIL, i18n.t('VALIDATION.ID')).required(i18n.t('REQUIRED')),
  [FORM_KEYS.PASSWORD]: yup
    .string()
    .matches(VALIDATION_REGEXES.PASSWORD, i18n.t('VALIDATION.PASSWORD'))
    .required(i18n.t('REQUIRED')),
});

const LoginForm = () => {
  const dispatch = useDispatch();
  const methods = useForm({ resolver: yupResolver(schema) });
  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const { showModal, closeModal } = useContext(ModalContext);
  const { t } = useTranslation();

  const handleLogin = ({ id, password }) => {
    const userParams = {
      email: id,
      password,
    };
    doLoginAPI(dispatch, userParams, (resResult) => {
      if (resResult?.status === false) {
        showModal(<ErrorModal errorMessage={t(resResult?.message) ?? ''} />);
      } else {
        closeModal();
      }
    });
  };

  const handleJoinClick = () => {
    showModal(<JoinModal />);
  };

  const handleFindPasswordClick = () => {
    showModal(<FindPasswordModal />);
  };

  return (
    <FormProvider {...methods}>
      <FormContainer>
        <Input
          name={FORM_KEYS.ID}
          classes={{ Variant: InputFormStyle }}
          type='text'
          errorMessage={errors[FORM_KEYS.ID]?.message}
          required
          placeholder={t('PLACEHOLDER.ID')}
        />
        <Input
          name={FORM_KEYS.PASSWORD}
          classes={{ Variant: InputFormStyle }}
          type='password'
          errorMessage={errors[FORM_KEYS.PASSWORD]?.message}
          required
          placeholder={t('PLACEHOLDER.PASSWORD')}
        />
        <LoginButton onClick={handleSubmit(handleLogin)}>{t('COMMON.LOGIN')}</LoginButton>
        <UserAccessContainer>
          <Join>
            <BottomButton onClick={handleJoinClick}>{t('COMMON.SIGNUP')}</BottomButton>
          </Join>
          <FindPassword>
            <BottomButton onClick={handleFindPasswordClick}>{t('COMMON.PASSWORD_RECOVERY')}</BottomButton>
          </FindPassword>
        </UserAccessContainer>
      </FormContainer>
    </FormProvider>
  );
};

const LoginModal = () => {
  return (
    <LoginContainer>
      <ImgContainer>
        <LoginText inner='LOG IN' />
        <LoginImg src={LoginBg} />
      </ImgContainer>
      <Modal classes={{ Container: BGColor, Header: BGColor }} body={<LoginForm />} />
    </LoginContainer>
  );
};

export default LoginModal;
