import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLogined: false,
  // 사용자 정보 (로그인 / 권한)
  userInfo: {
    list: {},
  },
};
/* eslint-disable no-param-reassign */
const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // 사용자 로그인, 로그아웃
    setUserLogin: (state) => {
      state.isLogined = true;
    },
    setUserLogout: (state) => {
      state.isLogined = false;
    },
    setUserInfo: (state, action) => {
      state.userInfo.list = action.payload;
    },
  },
});

export const { setUserLogin, setUserLogout, setUserInfo } = user.actions;
export default user.reducer;
