import { useRef, useState, useCallback, useEffect } from 'react';

import axios from 'axios';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';

import { BASE_URL, HEADER } from '@TAxiosConfig';
import { Div, Text } from '@components/index';
import { setAiData } from '@reducer/rfSlice';
import LoadingModal from '@SAM/components/Modal/LoadingModal';

const LogStyle = styled(Div)`
  overflow-y: scroll;
  color: #20b9fd;
  height: 265px;

  &::-webkit-scrollbar {
    width: 10px;
    background-color: #333;
    height: 10px;
    visibility: hidden;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #666;
    width: 10px;
    border-radius: 10px;
    visibility: hidden;
  }
`;

const RfProgressModal = ({ setShowProgressModal }) => {
  const dispatch = useDispatch();
  const eventSourceRef = useRef(null);
  const currentMasterId = useSelector((state) => state.rf.currentMasterId);
  const [eventLog, setEventLog] = useState(['AI RUN START...']);
  const emitterId = useRef('');

  const closeSSECall = useCallback(() => {
    if (eventSourceRef.current) {
      eventSourceRef.current = null;
      axios
        .get(`${BASE_URL}/api/sse/unSubscribe`, { params: { emitterId: emitterId.current } })
        .then((response) => {
          console.log('###close api', response);
        })
        .catch((error) => {
          console.log(error.response);
        })
        .finally(() => {
          setShowProgressModal && setShowProgressModal(false);
        });
    }
  }, []);

  useEffect(() => {
    const test = () => {
      axios
        .post(`${BASE_URL}/api/rf/executeModule`, { masterId: currentMasterId }, HEADER.POST)
        .then((res) => {
          dispatch(setAiData(res.data.body));
          if (eventSourceRef.current) {
            eventSourceRef.current.close();
            closeSSECall();
          }
        })
        .catch((err) => {
          console.error('err', err.response);
          if (eventSourceRef.current) {
            eventSourceRef.current.close();
            closeSSECall();
          }
        });
    };

    // const eventSource = new EventSource('http://localhost:8080/api/sse/subscribe'); // normal local setting
    // const eventSource = new EventSource('http://10.33.124.63:8080/api/sse/subscribe'); // suwon
    // const eventSource = new EventSource('http://10.114.91.24:8080/api/sse/subscribe'); // seoul
    const eventSource = new EventSource('http://samsungai.t-win.kr/api/sse/subscribe'); // t-win server
    eventSourceRef.current = eventSource;
    eventSourceRef.current.onopen = test;
    eventSourceRef.current.onmessage = (event) => {
      const eventString = event.data;
      const eventStreamCreated = eventString.includes('EventStream Created.');

      if (eventStreamCreated) {
        const match = eventString.match(/emitterId=([\w.@_-]+)/);
        if (match) {
          emitterId.current = match[1];
        } else {
          console.log('emitterId not found');
        }
      }
      const uuid = uuidv4();
      setEventLog((prev) => [...prev, <Text key={uuid} inner={event.data} />]);
    };

    return () => {
      if (eventSourceRef.current && typeof eventSourceRef.current.close === 'function') {
        eventSourceRef.current.close();
        closeSSECall();
      }
    };
  }, [currentMasterId]);

  const containerRef = useRef(null);
  useEffect(() => {
    containerRef.current.scrollTop = containerRef.current.scrollHeight;
  }, [eventLog]);

  return (
    <LoadingModal>
      <LogStyle ref={containerRef}>{eventLog}</LogStyle>
    </LoadingModal>
  );
};

RfProgressModal.propTypes = {
  excuteParam: PropTypes.shape({
    masterId: PropTypes.number,
  }),
  setShowProgressModal: PropTypes.func,
};

export default RfProgressModal;
