import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { getLocalStorage } from '@utils';
import ko from './locales/ko/translation.json';
import en from './locales/en/translation.json';

const resources = {
  ko: {
    translation: ko,
  },
  en: {
    translation: en,
  },
};

i18n
  .use(initReactI18next) // React와 i18next를 연결
  .init({
    whitelist: ['ko', 'en'], // 사용 가능한 언어 설정
    resources,
    lng: getLocalStorage('language') || 'ko', // 초기 언어 설정
    fallbackLng: 'ko', // 기본 언어 설정
    ns: ['translation'],
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false, // React는 XSS 보호 기능을 제공하므로 별도로 이스케이프 하지 않음
    },
    debug: process.env.NODE_ENV === 'development', // 디버그 모드 활성화
  });

export default i18n;
