import { useState, useCallback } from 'react';

const useTablePagination = (initialState) => {
  const [pagination, setPagination] = useState(
    initialState ?? {
      pageIndex: 0,
      pageSize: 10,
    }
  );

  const initializePageIndex = useCallback(() => {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  }, []);

  const handleChangePageIndex = useCallback((pageIndex) => {
    setPagination((prev) => ({ ...prev, pageIndex }));
  }, []);

  const handleChangePageSize = useCallback((pageSize) => {
    setPagination((prev) => ({ ...prev, pageSize }));
  }, []);

  return {
    pagination,
    setPagination,
    initializePageIndex,
    onChangePageIndex: handleChangePageIndex,
    onChangePageSize: handleChangePageSize,
  };
};

export default useTablePagination;
