import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Div } from '@components/Atoms/Atoms';
import { BottomSheetIcon } from '@images/index';

const getWidth = ({ w }) => `${w || 100}px`;
const getHeight = ({ h }) => `${h || 100}px`;

const StyledContainer = styled(Div)`
  position: relative;
  color: ${({ theme }) => theme.gray07};
  background-color: #fff;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
  width: ${getWidth};
  height: ${getHeight};
  ${({ isShow }) => isShow && `border: 1px solid #303030;`}
  ${({ classes }) => classes && classes.Variant.StyledContainer}
  ${({ disabled }) => disabled && `border: 0px; opacity: 0.3;`}
`;

const LabelContainer = styled(Div)`
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.gray08};
  cursor: pointer;

  &:after {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    background: url(${BottomSheetIcon});
    background-size: contain;
  }
  ${({ classes }) => classes && classes.Variant.LabelContainer}
`;

const OptionList = styled.ul`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.gray08};
  position: absolute;
  top: ${getHeight};
  left: 0;
  z-index: 1;
  display: none;
  width: 100%;
  border-radius: 0 0 8px 8px;
  border: 1px solid ${({ theme }) => theme.gray03};
  background: #fff;
  color: #767676;
  overflow: hidden;
  padding: 10px;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.06);
  ${({ isShow }) => isShow && `display:block;`}
  ${({ classes }) => classes && classes.Variant.OptionList}
  ${({ disabled }) => disabled && `display: none !important; height: 0px !important;`}
`;
const OptionItem = styled.li`
  padding: 10px 8px;
  border-radius: 4px;

  &:hover {
    background: #eee;
  }

  ${({ classes }) => classes && classes.Variant.OptionItem}
`;

const TableSelectBox = ({
  table,
  initialLabel,
  onFilterChange,
  required,
  disabled,
  options,
  itemText,
  classes,
  w,
  h,
  ...props
}) => {
  const [currentLabel, setCurrentLabel] = useState(initialLabel);
  const [isShow, setIsShow] = useState(false);

  const onSelectBoxClick = useCallback(() => {
    setIsShow((prev) => !prev);
  }, []);

  return (
    <StyledContainer
      onClick={onSelectBoxClick}
      classes={classes}
      w={w}
      h={h}
      isShow={isShow}
      disabled={disabled}
      {...props}
    >
      <LabelContainer classes={classes}>
        <div>{!currentLabel ? '전체' : currentLabel}</div>
      </LabelContainer>
      {!disabled && (
        <OptionList isShow={isShow} classes={classes} h={h}>
          {options.map((item, index) => (
            <OptionItem
              // eslint-disable-next-line react/no-array-index-key
              key={`${item?.id}-${index}`}
              onClick={() => {
                onFilterChange && onFilterChange(item);
                table && table.setPageSize(Number(item));
                setCurrentLabel(itemText ? `${item}${itemText}` : item);
              }}
              classes={classes}
            >
              {item}
              {itemText && <span>{itemText}</span>}
            </OptionItem>
          ))}
        </OptionList>
      )}
    </StyledContainer>
  );
};

TableSelectBox.propTypes = {
  /** React-Table에서 사용되는 데이터 객체 */
  table: PropTypes.object.isRequired,
  /** 라벨 초기 값 */
  initialLabel: PropTypes.string,
  /** 테이블 selecbox width */
  w: PropTypes.number,
  /** 테이블 selecbox height */
  h: PropTypes.number,
  /** 값 선택 필수 유무 */
  required: PropTypes.bool,
  /** Disabled 여부 */
  disabled: PropTypes.bool,
  /** SelectBox의 목록(list) */
  options: PropTypes.array.isRequired,
  /** SelectBox list의 추가 텍스트 */
  itemText: PropTypes.string,
  /** 커스텀 스타일 오브젝트 */
  classes: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  /** 테이블 컬럼 필터링 함수 */
  onFilterChange: PropTypes.func,
};

export default TableSelectBox;
