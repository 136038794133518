import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Div } from '@components/Atoms/Atoms';

const width = ({ elementWidth }) => elementWidth && `width:${elementWidth};`;
const height = ({ elementHeight }) => elementHeight && `height:${elementHeight};`;
const radius = ({ borderRadius }) => `${borderRadius}`;
const outlineProp = ({ elementOutline }) =>
  elementOutline &&
  css`
    border: 1px solid #dbdbdb;
  `;
const borderColorProp = ({ elementBorderColor }) => `border-color:${elementBorderColor};`;
const classes = ({ elementClasses }) => elementClasses && elementClasses.Box;
const backgroundColor = ({ elementBackgroundColor }) => `background-color:${elementBackgroundColor};`;

const Container = styled(Div)`
  overflow: hidden;
  position: relative;
  vertical-align: top;
  border-radius: ${radius};
  ${width}
  ${height}
  ${outlineProp}
  ${borderColorProp}
  ${backgroundColor}
  ${classes}
`;

const BoxRow = styled(Div)`
  display: flex;
  justify-content: space-between;
`;

const Box = ({
  children,
  outline = false,
  borderRadius = '8px',
  borderColor = '#dbdbdb',
  ripple = false,
  ...props
}) => (
  <Container outline={outline} borderRadius={borderRadius} borderColor={borderColor} ripple={ripple} {...props}>
    {children}
  </Container>
);

Box.propTypes = {
  /** Box 너비 */
  width: PropTypes.string,
  /** Box 높이 */
  height: PropTypes.string,
  /** Box 외곽선 여부 */
  outline: PropTypes.bool,
  /** Box 외곽선 테두리 곡선값 */
  borderRadius: PropTypes.string,
  /** Box 외곽선 테두리 곡선 색 */
  borderColor: PropTypes.string,
  /** Box 외곽선 배경색 */
  backgroundColor: PropTypes.string,
  /** 클릭시 ripple 효과 여부 */
  ripple: PropTypes.bool,
  /** Box 커스텀 스타일 */
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  children: PropTypes.node,
};

export { Box, BoxRow };
