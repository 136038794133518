/* eslint-disable */

import axios from 'axios';

import { createQueryString } from '@utils';
import { getUrl } from './TAxiosConfig';

/**
 * @namespace TAxios
 * @param {string} type Http Method
 * @param {string} path Api 요청 path EX) /api/1.0/app/
 * @param {object} params 요청 params
 * @param {Function} onSuccess 요청 성공 시 호출할 callback function
 * @param {Function} onFail 요청 실패 시 호출할 callback function
 * @param {object} header 요청 실패 시 호출할 callback function
 */

const TAxios = async ({ type, path, params = {}, onSuccess, onFail, header }) => {
  const serverType = 'localhost';

  if (typeof window === 'object') {
    const urlparams = createQueryString(params);
    let url = '';
    if (Array.isArray(path)) {
      url = getUrl(path[0], serverType);
      for (let i = 1; i < path.length; i++) {
        url += `/${path[i]}`;
      }
    } else {
      url = getUrl(path, serverType);
    }
    switch (type) {
      case 'get':
        url += urlparams ? `?${urlparams}` : '';
        break;
    }
    if (serverType !== 'prd') {
    }

    let axiosIns = null;
    switch (type) {
      case 'get':
        axiosIns = axios[type](url, { withCredentials: true });
        break;
      case 'put':
        axiosIns = axios[type](url, params, { ...header, withCredentials: true });
        break;
      case 'post':
        axiosIns = axios[type](url, params, { ...header, withCredentials: true });
        break;
      case 'delete': {
        break;
      }
    }
    if (axiosIns) {
      axiosIns
        .then((res) => {
          if (serverType !== 'prd') {
            console.log('TAxios response', url, res);
          }

          if (onSuccess) {
            onSuccess(res);
          }
        })
        .catch((error) => {
          console.error('TAxios ', url, 'resultCode: ', error);

          if (onFail) {
            onFail(console.log('fail'));
          }
        });
    } else {
      console.warn('TAxios invalid case type ', type);
    }
  }
};

export default TAxios;
