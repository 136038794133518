import { useCallback, useMemo, useRef, useState, forwardRef, useImperativeHandle, useEffect } from 'react';

import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getFacetedUniqueValues,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { Div } from '@components/Atoms/Atoms';
import TableSelectBox from './modules/TableSelectBox';
import useTablePagination from './modules/useTablePagination';
import TablePagination from './modules/TablePagination';

const PAGESIZE_OPTION = Object.freeze({
  pageSize: [5, 10, 25, 30, 50],
});

const hover = ({ isHover }) =>
  isHover ? `background-color: #3E3E3E; color: #fff;  cursor: pointer` : `  color: #777777`;
const selected = ({ isSelected }) => isSelected && `background-color: #3E3E3E; color: #fff;  cursor: pointer`;
const titleTr = ({ isTitle }) => !isTitle && ` background-color: #f5f6fa;color: #fff;`;

const Container = styled(Div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #2a2a2a;
  ${({ classes }) => classes?.Container && classes.Container}
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 0;
`;

const Thead = styled.thead`
  position: sticky;
  top: 0;
  vertical-align: middle;
  height: 60px;
  background-color: #1d1d1b;
  ${({ classes }) => classes?.Thead && classes.Thead}
`;

const Tbody = styled.tbody`
  vertical-align: middle;
  width: 100%;
  ${({ classes }) => classes?.TBody && classes.TBody}
`;

const Tr = styled.tr`
  display: table;
  table-layout: fixed;
  height: 50px;
  width: 100%;
  ${titleTr}
  &:hover {
    ${hover}
  }
  background-color: #2a2a2a;
  ${selected}/* background-color : ${({ isEven }) =>
    typeof isEven !== 'undefined' && isEven ? '#262626' : '#2A2A2A'}; */
`;

const Th = styled.th`
  text-align: center;
  vertical-align: middle;
  font-size: 12px;
  font-weight: 700;
  height: 60px;
  flex: 1;
  background-color: #1d1d1b;
  padding: 0 20px;
  border-right: solid 1px #444444;
  border-bottom: 1px solid #444444;
  &:last-child {
    border-right: none;
  }

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f9f9f9;
    font-size: 18px;
    font-weight: 500;
    &:last-child {
      border-right: none;
    }
  }
`;

const Td = styled.td`
  vertical-align: middle;
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  height: 46px;
  padding: 0 20px;
  border-right: solid 1px #444444;
  border-bottom: 1px solid #444444;

  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  color: #f9f9f9;
  &:last-child {
    border-right: none;
  }
`;

const TableInfoContainer = styled(Div)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 10px;
  padding-left: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
`;

const TotalLength = styled(Div)`
  padding: 4px 30px 4px 0px;
  border-right: 1px solid ${({ theme }) => theme.gray04};
`;

const Footer = styled(Div)`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 42px;
  justify-content: space-between;
`;

const ButtonContainer = styled(Div)`
  display: flex;
  justify-content: end;
`;
const uuid = uuidv4();

const TableCustom = forwardRef(
  (
    {
      data,
      columns,
      classes,
      cellWidthCustom,
      isHover = true,
      buttonList = [],
      hasTableInfo = true,
      hasPagenation = true,
      hasPageSizeOption = true,
      handleSelectedRow,
      handleClickedData,
    },
    ref
  ) => {
    /** 테이블 커스텀 훅 */
    const { pagination, setPagination, onChangePageIndex } = useTablePagination();

    const [selectedRow, setSelectedRow] = useState(null);
    // NOTE: 선택된 Table Row 스크롤 이동 위함.
    const scrollRef = useRef([]);

    const table = useReactTable({
      data,
      columns,
      enableSortingRemoval: false,
      /** NOTE: 페이지네이션 관련 설정 */
      enableRowSelection: false,
      autoResetPageIndex: true,
      onPaginationChange: setPagination,
      getCoreRowModel: getCoreRowModel(),
      state: {
        pagination,
      },
      /** NOTE: Column 정렬 함수 */
      getSortedRowModel: getSortedRowModel(),
      /** NOTE: 해당 함수를 임포트 해야 필터링된 Row Model을 출력 가능 */
      getFilteredRowModel: getFilteredRowModel(),
      /** NOTE: 각 column 안에 있는 unique한 value들을 종류별로 출력해주는 함수 (컬럼별로 필터 목록을 출력) */
      getFacetedUniqueValues: getFacetedUniqueValues(),
      /** NOTE: Table pagination을 위한 함수 */
      getPaginationRowModel: getPaginationRowModel(),
    });

    /** NOTE: 부모 컴포넌트에서 직접 호출할 수 있는 함수 */

    const remoteControll = useCallback(
      (id) => {
        setSelectedRow(id);
        return table.getRow(id).original;
      },
      [selectedRow]
    );
    useImperativeHandle(
      ref,
      () => ({
        remoteControll,
      }),
      [remoteControll]
    );

    useEffect(() => {
      if (selectedRow !== null && scrollRef.current[selectedRow]) {
        scrollRef.current[selectedRow].scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, [selectedRow]);

    /** NOTE: 테이블 Pagination 관련 데이터 모음 */
    const pageLimitOffFinished = useRef(false);

    const pageSizeLimitOff = useCallback((totalLength) => {
      table.setPageSize(totalLength);
      pageLimitOffFinished.current = true;
    }, []);

    const tableData = useMemo(() => {
      const totalLength = table.getPrePaginationRowModel().rows.length;
      const currentPageCount = table.getState().pagination.pageIndex + 1;
      const totalPageCount = table.getPageCount();
      const { pageSize } = table.getState().pagination;
      if (!hasPageSizeOption && !pageLimitOffFinished.current && totalLength > 0) {
        pageSizeLimitOff(totalLength);
      }
      return { totalLength, currentPageCount, totalPageCount, pageSize };
    }, [table, table.getState(), table.getPageCount()]);

    const handleClickRow = useCallback(
      (row) => {
        setSelectedRow(row.id);

        if (handleSelectedRow) {
          handleSelectedRow(row);
        }
        if (handleClickedData) {
          handleClickedData(row.original);
        }
      },
      [handleSelectedRow, selectedRow]
    );

    return (
      <Container classes={classes}>
        {hasTableInfo && (
          <TableInfoContainer>
            <TotalLength>총 {tableData.totalLength}개 항목</TotalLength>
            <TableSelectBox
              table={table}
              options={PAGESIZE_OPTION.pageSize}
              w={126}
              h={40}
              initialLabel='10개씩 보기'
              itemText='개씩 보기'
            />
            <strong>
              {tableData.currentPageCount}/{tableData.totalPageCount} 페이지
            </strong>
          </TableInfoContainer>
        )}
        <StyledTable>
          <Thead classes={classes}>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id} isTitle>
                {headerGroup.headers.map((header) => {
                  const widthCustomTh = cellWidthCustom[header.column.id] || 'auto';
                  return (
                    <Th
                      key={header.id}
                      style={{
                        width: widthCustomTh,
                        cursor: header.column.getCanSort() ? 'pointer' : 'default',
                        color: header.column.getIsSorted() ? '#006eb9' : '#000',
                      }}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <div>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </div>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody classes={classes}>
            {table.getRowModel().rows.map((row) => (
              <Tr
                ref={(el) => {
                  scrollRef.current[row.id] = el;
                }}
                key={row.id + uuid}
                isHover={isHover}
                onClick={() => handleClickRow(row)}
                isSelected={row.id === selectedRow}
                isEven={row.id % 2 === 0}
              >
                {row.getVisibleCells().map((cell) => {
                  const widthCustomTd = cellWidthCustom[cell.column.id] || 'auto';
                  return (
                    <Td key={cell.id} style={{ width: widthCustomTd }}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Td>
                  );
                })}
              </Tr>
            ))}
          </Tbody>
        </StyledTable>
        {hasPagenation && (
          <Footer>
            <TablePagination table={table} onChangePageIndex={onChangePageIndex} />
            {buttonList && <ButtonContainer>{buttonList}</ButtonContainer>}
          </Footer>
        )}
      </Container>
    );
  }
);

TableCustom.propTypes = {
  /** Table에서 사용되는 데이터 객체 */
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /** Table에서 사용되는 column 객체 */
  columns: PropTypes.array.isRequired,
  /** Custom css */
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  /** Table td hover 배경색 유무 */
  isHover: PropTypes.bool,
  /** Table 상단 버튼 배열 */
  buttonList: PropTypes.array,
  /** Table 상단 정보 유무 default true */
  hasTableInfo: PropTypes.bool,
  /** Table 하단 Pagenation 유무 default true */
  hasPagenation: PropTypes.bool,
  /** Table Pagesize 유무 default true */
  hasPageSizeOption: PropTypes.bool,
  /** Selected row data */
  handleSelectedRow: PropTypes.func,
  /** Selected row data */
  handleClickedData: PropTypes.func,
  /** Table cellWidth 수정 name */
  cellWidthCustom: PropTypes.object,
};

export default TableCustom;
