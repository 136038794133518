import { useMemo, useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setFrqValue } from '@reducer/rfSlice';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { Div, Text, Icon, Button, ModalContext } from '@components/index';
import { AIScan, NFImport } from '@SAM/images';
import { startJob } from '@utils';
import RfProgressModal from './RfProgressModal';
import RfImportModal from './RfImportModal';

const Container = styled(Div)`
  display: flex;
  height: calc(100vh - 100px);
  flex-direction: column;
  padding: 30px 30px 0 30px;
  background-color: #1d1d1b;
  width: 330px;
  border: 1px solid #444444;
  border-top: none;
  border-bottom: none;
`;

const FullButtonStyle = css`
  text-decoration: none;
  color: #fff;
  background-color: #366eff;
  height: 45px;
  border-radius: 100px;
  width: 100%;
  margin: 0;
  background-color: ${({ runStatus }) => typeof runStatus !== 'undefined' && !runStatus && '#A1A1A1'};
  :hover {
    text-decoration: none;
    color: #fff;
  }
`;
const AIRunButtonStatus = styled(Text)``;

const MenuBlock = styled(Div)`
  ${({ classes }) => classes?.MenuBlockStyle && classes.MenuBlockStyle}
`;

const BorderBottomStyle = css`
  padding-bottom: 20px;
`;

const NFImportStyle = css`
  border-top: 1px solid #494949;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
`;

const FlexRow = styled(Div)`
  display: flex;
  position: relative;
  gap: 10px;
  margin-bottom: 10px;
  ${({ classes }) => classes && classes.Variant && classes.Variant}
`;
const FlexRowBottomStyle = css`
  margin-bottom: 30px;
`;

const FlexBox = styled(Div)`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ImportControllerDataMaxWidth = styled(Div)`
  background-color: #2a2a2a;
  border: solid 1px #444444;
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  flex: 1;
  height: 40px;
  width: 158px;
  ${({ classes }) => classes && classes.Variant}
`;

const AiScanLabel = styled(Div)`
  display: flex;
  justify-content: space-between;
  padding-top: 0px;
  padding-bottom: 10px;
`;

const ScanRunBox = styled(Div)`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const AiScanInput = styled.input`
  background-color: #2a2a2a;
  height: 30px;
  width: 60px;
  border-radius: 5px;
  border: none;
  text-align: end;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  &:hover {
    border: none;
  }
`;

const ScaleButtonArea = styled(Div)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ScaleButton = styled(Button)`
  background-color: #444444;
  text-decoration: none;
  color: #fff;
  width: 100%;
  margin: 0;
  margin-bottom: 10px;
  height: 40px;
  border-radius: 100px;
  ${({ type, selectedScale }) => type === selectedScale && `background-color: #366EFF;`}
  background-color: ${({ runStatus }) => typeof runStatus !== 'undefined' && !runStatus && '#444444'};
  &:hover {
    text-decoration: none;
    color: rgba(255, 255, 255);
  }
`;

const RfSideController = ({ tableData, rowSelection, excelDownload, imgDownload }) => {
  const dispatch = useDispatch();
  const { showModal } = useContext(ModalContext);
  const currentMasterId = useSelector((state) => state.rf.currentMasterId);
  const isScanReady = typeof currentMasterId !== 'undefined' && currentMasterId !== null;

  const selectedTableRow = useSelector((state) => state.rf.selectedTableRow);
  const [isShowRfProgressModal, setIsShowRfProgressModal] = useState(false);

  const handleERfImportModal = useCallback(() => {
    showModal(<RfImportModal />);
  }, []);

  const handleAiScan = useCallback(() => {
    if (currentMasterId !== null) {
      setIsShowRfProgressModal(true);
    }
  }, [currentMasterId]);

  const saveResultClick = () => {
    excelDownload();
    imgDownload();
  };

  const frqArr = useMemo(() => {
    return tableData?.map((data) => Number(data.frq));
  }, [tableData]);

  // Ai SCAN Frq란에 입력한 숫자 기준으로 가장 가까운 frequency를 찾아 반환함
  const findClosestIndex = (arr, target) =>
    arr.reduce(
      (closestIndex, num, index, array) =>
        Math.abs(num - target) < Math.abs(array[closestIndex] - target) ? index : closestIndex,
      0
    );

  const handleFrqInput = useCallback(
    (e) => {
      const closestIndex = findClosestIndex(frqArr, Number(e.target.value));
      if (frqArr && typeof rowSelection === 'function') {
        if (
          /^\d*\.?\d*$|^\.$/.test(e.target.value) &&
          closestIndex !== -1 &&
          typeof closestIndex !== 'undefined' &&
          closestIndex !== null
        ) {
          const origin = rowSelection(closestIndex.toString());
          dispatch(
            setFrqValue({
              frq: Number(e.target.value),
              real_RC: origin.real_RC ? Number(origin.real_RC) : 0,
              pred_RE: origin.pred_RE ? Number(origin.pred_RE) : 0,
              real_RE: origin.real_RE ? Number(origin.real_RE) : 0,
              id: closestIndex,
            })
          );
        }
      }
    },
    [rowSelection]
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (frqArr && typeof rowSelection === 'function') {
        const closestIndex = findClosestIndex(frqArr, Number(e.target.value));
        if (/^\d*\.?\d*$|^\.$/.test(e.target.value) && e.key === 'Enter') {
          const origin = rowSelection(closestIndex.toString());
          startJob(
            `setSideController-data`,
            () =>
              dispatch(
                setFrqValue({
                  frq: Number(origin.frq),
                  real_RC: origin.real_RC ? Number(origin.real_RC) : 0,
                  pred_RE: origin.pred_RE ? Number(origin.pred_RE) : 0,
                  real_RE: origin.real_RE ? Number(origin.real_RE) : 0,
                  id: closestIndex,
                })
              ),
            100
          );
        }
      }
    },
    [rowSelection]
  );

  return (
    <Container>
      <MenuBlock classes={{ MenuBlockStyle: BorderBottomStyle }}>
        <Text inner='Prediction Display' fontSize={28} fontWeight={600} color='#F5F5F5' />
      </MenuBlock>
      <MenuBlock classes={{ MenuBlockStyle: NFImportStyle }}>
        <Label>
          <Icon icon={NFImport} iconWidth={25} iconHeight={25} />
          <Text inner='RF Import' fontSize={22} fontWeight={600} color='#F5F5F5' />
        </Label>
        <FlexRow classes={{ Variant: FlexRowBottomStyle }}>
          <Button classes={{ Button: FullButtonStyle }} onClick={handleERfImportModal}>
            <Text fontSize={18} fontWeight={600} inner='IMPORT (.eff)' />
          </Button>
        </FlexRow>
        <AiScanLabel>
          <ScanRunBox>
            <Icon icon={AIScan} iconWidth={27} iconHeight={26} />
            <Text inner='Prediction' fontSize={24} fontWeight={600} color='#fff' />
          </ScanRunBox>
        </AiScanLabel>
        <FlexRow classes={{ Variant: FlexRowBottomStyle }}>
          <Button classes={{ Button: FullButtonStyle }} runStatus={isScanReady} onClick={handleAiScan}>
            <AIRunButtonStatus fontSize={18} fontWeight={600} inner='RUN' />
          </Button>
        </FlexRow>
        <FlexRow>
          <ImportControllerDataMaxWidth>
            <Text fontSize={18} fontWeight={600} color='#F1F1F1' inner='Frequency' />
            <FlexBox>
              <AiScanInput
                type='text'
                disabled={!isScanReady}
                value={selectedTableRow?.frq ?? 0}
                onChange={handleFrqInput}
                onKeyDown={handleKeyDown}
              />
              <Text fontSize={16} fontWeight={400} color='#888888' inner='MHz' />
            </FlexBox>
          </ImportControllerDataMaxWidth>
        </FlexRow>
        <FlexRow>
          <ImportControllerDataMaxWidth>
            <Text fontSize={18} fontWeight={600} color='#F1F1F1' inner='Pred_RE' />
            <FlexBox>
              <AiScanInput type='text' required disabled value={selectedTableRow?.pred_RE ?? 0} />
              <Text fontSize={16} fontWeight={400} color='#888888' inner='dBi' />
            </FlexBox>
          </ImportControllerDataMaxWidth>
        </FlexRow>
        <ScaleButtonArea>
          <ScaleButton type='save' runStatus={isScanReady} onClick={saveResultClick}>
            <Text inner='Save result' fontSize={18} fontWeight={500} color='#fff' />
          </ScaleButton>
        </ScaleButtonArea>
      </MenuBlock>
      {currentMasterId && isShowRfProgressModal ? (
        <RfProgressModal excuteParam={{ masterId: currentMasterId }} setShowProgressModal={setIsShowRfProgressModal} />
      ) : null}
    </Container>
  );
};

RfSideController.propTypes = {
  /** Rf 테이블 데이터 */
  tableData: PropTypes.array,
  /** 테이블 데이터 set 함수 */
  rowSelection: PropTypes.func,
  /** 테이블 데이터 excel download 함수 */
  excelDownload: PropTypes.func,
  /** 차트 이미지 download 함수 */
  imgDownload: PropTypes.func,
};

export default RfSideController;
