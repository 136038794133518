/* eslint-disable */

import { useState, createContext, useCallback, useContext } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Div } from '@components/Atoms/Atoms';
import { Box } from '@components/Surface/Box';

const Wrapper = styled(Div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99999999; //echarts tooltip z-index 9999999라서 매우 높음
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
`;
const ModalBox = styled(Div)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  color: #343c47;
  padding: 0;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  opacity: 1;
  transition:
    opacity 0.5s ease-in-out,
    transform 0.5s ease-in-out;
  z-index: 110;
  /* border-top-left-radius: 5px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 5px; */
  overflow: hidden;
`;

const ModalContext = createContext();

const ModalWrapper = ({ children }) => {
  const modal = document.getElementById('modal');
  const { closeModal } = useContext(ModalContext);

  const handleModalBackGroundOnClick = useCallback(() => {
    closeModal();
  }, []);

  const handleModalBodyOnClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return createPortal(
    <Wrapper onClick={handleModalBackGroundOnClick}>
      <ModalBox onClick={handleModalBodyOnClick}>{children}</ModalBox>
    </Wrapper>,
    modal
  );
};

ModalWrapper.propTypes = {
  /** Custom Modal Components */
  children: PropTypes.node,
};

const ModalProvider = ({ children }) => {
  const [modalContent, setModalContent] = useState(null);
  const [targetFile, setTargetFile] = useState(null);
  const [originFile, setOriginFile] = useState(null);
  const [originRfFile, setOriginRfFile] = useState(null);

  const showModal = useCallback((content) => {
    setModalContent(content);
  }, []);

  const closeModal = useCallback(() => {
    setModalContent(null);
  }, []);

  return (
    // eslint-disable-next-line
    <ModalContext.Provider
      value={{
        showModal,
        closeModal,
        targetFile,
        setTargetFile,
        originFile,
        setOriginFile,
        originRfFile,
        setOriginRfFile,
      }}
    >
      {children}
      {modalContent && <ModalWrapper>{modalContent}</ModalWrapper>}
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  /** MainContainer */
  children: PropTypes.node,
};

export { ModalContext, ModalProvider };
