import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '@components/Input/Button/Button';
import { Div } from '@components/Atoms/Atoms';
import { PaginationArrow, PaginationArrowLast } from '@images/index';
import { useCallback } from 'react';

const Container = styled(Div)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 20px;
`;

const PaginationBox = styled(Div)`
  display: flex;
  gap: 20px;
`;

const PaginationButton = styled(Button)`
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  color: ${({ theme }) => theme.gray08};
  width: 28px;
  height: 28px;
  border-radius: 20px;
  background-color: ${(props) => props.selected && props.theme.brown01};

  &:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const PaginationNext = styled(Button)`
  width: 28px;
  height: 28px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${PaginationArrow});
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 20px;
  }
`;

const PaginationLast = styled(PaginationNext)`
  background-image: url(${PaginationArrowLast});
`;

const PaginationPrev = styled(PaginationNext)`
  transform: rotate(180deg);
`;

const PaginationFirst = styled(PaginationPrev)`
  background-image: url(${PaginationArrowLast});
`;

const TablePagination = ({ table, onChangePageIndex }) => {
  const pageButtons = useCallback(() => {
    const tData = table.getState().pagination;
    const pageGroupSize = 5;
    const currentPageGroupIndex = Math.floor(tData.pageIndex / pageGroupSize);
    const remain = table.getPageCount() - pageGroupSize * currentPageGroupIndex;

    return Array.from({ length: Math.min(pageGroupSize, remain) }, (_, i) => {
      const pageNumber = currentPageGroupIndex * pageGroupSize + i;
      return (
        <Div key={`pagination-${i}`}>
          <PaginationButton
            onClick={() => {
              table.setPageIndex(pageNumber);
              onChangePageIndex(pageNumber);
            }}
            selected={table.getState().pagination.pageIndex === pageNumber}
          >
            {pageNumber + 1}
          </PaginationButton>
        </Div>
      );
    });
  }, [table, onChangePageIndex]);
  return (
    <Container>
      <PaginationFirst onClick={() => table.firstPage()} disabled={!table.getCanPreviousPage()} />
      <PaginationPrev onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} />
      <PaginationBox>{pageButtons()}</PaginationBox>
      <PaginationNext onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} />

      <PaginationLast onClick={() => table.lastPage()} disabled={!table.getCanNextPage()} />
    </Container>
  );
};

TablePagination.propTypes = {
  /** React-Table에서 사용되는 데이터 객체 */
  table: PropTypes.object.isRequired,
  /** React-Table pagination 클릭 시, 해당 페이지 이동 함수 */
  onChangePageIndex: PropTypes.func.isRequired,
};

export default TablePagination;
