import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { emiSlice, userSlice, rfSlice, bikeSlice } from '../feature/index';

const reducers = combineReducers({
  emi: emiSlice,
  rf: rfSlice,
  bike: bikeSlice,
  user: userSlice,
});

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['emi/setEmiSubmitData'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['emi/setEmiFileMetaData', 'rf/setRfFileMetaData', 'bike/setBikeFileMetaData', 'payload'],
      },
    }),
});

export default store;

/* persist */
// login 셋업
// import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
// import storage from 'redux-persist/lib/storage/session';

// const persistConfig = {
//   key: 'user',
//   storage,
//   whitelist: ['user'], // 해당 reducer만 저장
// };

// const reducers = combineReducers({
//   common: commonSlice,
// });

// const persistedReducer = persistReducer(persistConfig, reducers);

// const store = configureStore({
//   reducer: persistedReducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: {
//         ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//       },
//     }),
// });
// export const persistor = persistStore(store);
// export default store;
/* persist */

// import { configureStore } from '@reduxjs/toolkit';
