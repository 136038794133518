import React, { useRef, useState } from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from 'swiper/modules';
import { Swiper as SwiperContainer, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Div, Img } from '@components/Atoms/Atoms';
import { PlayIcon, StopIcon } from '@images/index';

const Container = styled(Div)`
  position: relative;
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
  .swiper-pagination-bullet {
    background-color: grey;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background-color: white;
  }
  .swiper-pagination {
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
  }
  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    margin: 0 10px !important;
  }
`;

const ExtendedSwiper = styled(SwiperContainer)`
  width: 100%;
`;

const PlayPauseWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 75px;
  left: 53.5%;
  transform: translateX(-50%);
  z-index: 20;
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

const PlayPauseImg = styled(Img)`
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  -webkit-user-drag: none;
  -moz-user-drag: none;
  -ms-user-drag: none;
  -o-user-drag: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

const CustomSwiper = ({ children, onSlideChange }) => {
  const swiperRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);

  const togglePlayPause = () => {
    if (swiperRef.current?.swiper?.autoplay) {
      if (isPlaying) {
        swiperRef.current.swiper.autoplay.stop();
      } else {
        swiperRef.current.swiper.autoplay.start();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <Container>
      <ExtendedSwiper
        ref={swiperRef}
        navigation
        modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
        className='mySwiper'
        spaceBetween={0}
        observeParents
        onSlideChange={(swiper) => onSlideChange && onSlideChange(swiper)}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        pagination
      >
        {React.Children.map(children, (child) => (
          <SwiperSlide>{child}</SwiperSlide>
        ))}
        <div className='pagination1'>
          <PlayPauseWrapper onClick={togglePlayPause}>
            {isPlaying ? (
              <PlayPauseImg src={StopIcon} style={{ width: '30px' }} />
            ) : (
              <PlayPauseImg src={PlayIcon} style={{ width: '50px' }} />
            )}
          </PlayPauseWrapper>
        </div>
      </ExtendedSwiper>
    </Container>
  );
};

CustomSwiper.propTypes = {
  /** SwiperSlides */
  children: PropTypes.node,
  /** Swiper가 onChage될때 실행할 함수 */
  onSlideChange: PropTypes.func,
};

export default CustomSwiper;
