import TAxios from '@TAxios';
import { HEADER } from '@TAxiosConfig';
import { setUserLogin, setUserLogout, setUserInfo } from '@reducer/userSlice';

const BASE_URL = '/api/member';

/**
 * @namespace DoLoginAPI
 * @param {Function} dispatch - Dispatch 함수
 * @param {object} parms - Get parameters
 */

const doLoginAPI = (dispatch, params, resResult) => {
  const URL = `${BASE_URL}/login`;

  const onSuccess = (res) => {
    if (res.data.status === 'ERROR') {
      // alert('로그인 실패');
      return resResult && resResult({ status: false, message: res.data?.errorCode });
    }
    dispatch(setUserLogin());
    return resResult && resResult({ status: true });
  };

  const onFail = (err) => {
    console.error(`${BASE_URL}/login`, err);
    // alert('로그인 실패');
    return resResult && resResult({ status: false, message: 'ERRORS.COMMON.002' });
  };

  TAxios({ type: 'post', path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

/**
 * @namespace DoLogoutAPI
 * @param {Function} dispatch - Dispatch 함수
 * @param {object} parms - Get parameters
 */

const doLogoutAPI = (dispatch, resResult) => {
  const URL = `${BASE_URL}/logout`;

  const onSuccess = (res) => {
    if (res.data.status === 'ERROR') {
      // alert('로그아웃 실패');
      return resResult && resResult({ status: false, message: res.data?.errorCode });
    }

    dispatch(setUserLogout());
    return resResult && resResult({ status: true });
  };

  const onFail = (err) => {
    console.error(`${BASE_URL}/logout`, err);
    // alert('로그아웃 실패');
    return resResult && resResult({ status: false, message: 'ERRORS.COMMON.003' });
  };

  TAxios({ type: 'post', path: URL, onSuccess, onFail, header: HEADER.POST });

  /**
   * @namespace SignUpAPI
   * @param {Function} dispatch - Dispatch 함수
   * @param {object} parms - Get parameters
   */
};

const signUpAPI = (dispatch, params, resResult) => {
  const URL = `${BASE_URL}/signup`;

  const onSuccess = (res) => {
    if (res.data.status === 'ERROR') {
      // alert('회원가입 실패');
      return resResult && resResult({ status: false, message: res.data?.errorCode });
    }

    dispatch(setUserInfo(res.data.rs));
    return resResult && resResult({ status: true });
  };

  const onFail = (err) => {
    console.error(`${BASE_URL}/signup`, err);
    return resResult && resResult({ status: false, message: 'ERRORS.COMMON.004' });
  };

  TAxios({ type: 'post', path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

export { doLoginAPI, doLogoutAPI, signUpAPI };
