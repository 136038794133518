import { useMemo, useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import { createColumnHelper } from '@tanstack/react-table';
import styled, { css } from 'styled-components';
import { excelDownload, handleImgDownload } from '@utils';

import { setSelectedTableRow } from '@reducer/rfSlice';
import { Div, Table, Text } from '@components/index';
import RfLineChart from '@SAM/components/Chart/RfLineChart';
import ToggleSam from '@SAM/components/ToggleSam';
import RfSideController from './RfSideController';

const columnHelper = createColumnHelper();

const Container = styled(Div)`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  background-color: #000000;
`;

const DataArea = styled(Div)`
  display: flex;
  padding: 30px;
  width: calc(100vw - 330px);
  gap: 10px;
`;

const GraphArea = styled(Div)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const TableArea = styled(Div)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const StickyHeader = css`
  display: sticky;
  top: 0;
  tr {
    background-color: #1d1d1b;
    color: #fff;
  }
`;

const FirstGraph = styled(Div)`
  display: flex;
  flex: 1;
`;

const YAxis = styled(Div)`
  width: 25px;
  height: 100%;
  background-color: #1d1d1b;
  border: 1px solid #444444;
  color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const YName = styled(Div)`
  transform: rotate(270deg);
  transform-origin: left top 50;
  color: #f9f9f9;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Pretendard';
  white-space: nowrap;
`;
const XAxis = styled(Div)`
  display: flex;
  width: 100%;
  height: 25px;
  background-color: #2a2a2a;
  border-top: 1px solid #444444;
  justify-content: center;
  align-items: center;
  color: #f9f9f9;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Pretendard';
  white-space: nowrap;
`;
const ChartArea = styled(Div)`
  display: flex;
  flex-direction: column;
`;
const LegendContainer = styled(Div)`
  display: flex;
  align-items: center;
`;
const LegendButtonGroup = styled(Div)`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  height: 40px;
  gap: 25px;
  padding-right: 36px;
  background-color: #2a2a2a;
  border-top: 1px solid #444444;
  border-right: 1px solid #444444;
`;
const LegendToggle = styled(ToggleSam)``;

const SecondeGraph = styled(FirstGraph)``;

const HeaderContainer = styled(Div)`
  display: flex;
  background-color: rgb(29, 29, 27);
`;
const HeaderTitle = styled(Text)`
  flex: 1;
  text-align: center;
  align-content: center;
  height: 60px;
  &:first-child {
    border-right: 1px solid rgb(0, 0, 0);
  }
`;
const RowContainer = styled(Div)`
  display: flex;
  background-color: rgb(38, 38, 38);
`;
const RowTitle = styled(Text)`
  flex: 1;
  text-align: center;
  align-content: center;
  height: 50px;
  &:first-child {
    border-right: 1px solid rgb(0, 0, 0);
  }
`;

const FlexRow = styled(Div)`
  display: flex;
  height: 100%;
  background-color: #2a2a2a;
`;
const MainTable = styled(Table)``;
const AiSubDataContainer = styled(Div)`
  width: 100%;
  align-items: center;
`;

const ScrollXContainer = css`
  display: block;
  width: 100%;
  height: 100%;
  max-height: 800px;
  overflow-y: auto;
  white-space: nowrap;
  flex: 1;

  &::-webkit-scrollbar {
    width: 10px;
    background-color: #343434;
    border-radius: 100px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #366eff;
    width: 10px;
    height: 35px;
    border-radius: 10px;
  }
`;

const RfSolutionMain = ({ rfData }) => {
  const dispatch = useDispatch();
  const chartRef = useRef(null);
  const tableRef = useRef(null);
  const rowSelection = tableRef.current && tableRef.current.remoteControll;

  const aiSubData = useSelector((state) => state.rf.aiSubData);
  const selectedTableRow = useSelector((state) => state.rf.selectedTableRow);
  const [legendStatus, setLegendStatus] = useState({ Real_RE: true, Pred_RE: true });

  const handleLegendToggle = useCallback(
    (target, value) => {
      setLegendStatus((prev) => ({ ...prev, [target]: value }));
    },
    [legendStatus]
  );

  const chartLegend = useMemo(() => {
    const aiButton = [];
    if (Array.isArray(rfData.RealRE)) {
      aiButton.push(
        <LegendContainer key='Real_RE'>
          <Text color='#BEBEBD' inner='Real_RE' fontSize={20} fontWeight={500} />
          <LegendToggle value={legendStatus.Real_RE} target='Real_RE' callback={handleLegendToggle} />
        </LegendContainer>
      );
      aiButton.push(
        <LegendContainer key='Pred_RE'>
          <Text color='#BEBEBD' inner='Pred_RE' fontSize={20} fontWeight={500} />
          <LegendToggle value={legendStatus.Pred_RE} target='Pred_RE' callback={handleLegendToggle} />
        </LegendContainer>
      );
    }

    return { aiButton };
  }, [rfData, handleLegendToggle]);

  const TABLE_KEYS = Object.freeze({
    FRQ: 'frq',
    REAL_RC: 'real_RC',
    REAL_RE: 'real_RE',
    PRED_RE: 'pred_RE',
    ACCURACY: 'accuracy',
    MARGIN_SCORE: 'margin_score',
  });

  /* eslint-disable react/no-unstable-nested-components */
  const COLUMNS_DATA = useMemo(
    () => [
      columnHelper.accessor(TABLE_KEYS.FRQ, {
        id: TABLE_KEYS.FRQ,
        header: () => (
          <span>
            Frequency <br /> [MHz]
          </span>
        ),
        cell: ({ getValue }) => {
          return <span>{getValue()}</span>;
        },
      }),
      columnHelper.accessor(TABLE_KEYS.REAL_RC, {
        id: TABLE_KEYS.REAL_RC,
        header: () => (
          <span>
            Real <br /> [dBi]
          </span>
        ),
        cell: ({ getValue }) => <span>{getValue()}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.REAL_RE, {
        id: TABLE_KEYS.REAL_RE,
        header: () => (
          <span>
            Prediction <br /> [dBi]
          </span>
        ),
        cell: ({ getValue }) => <span>{getValue()}</span>,
      }),
      columnHelper.accessor(TABLE_KEYS.PRED_RE, {
        id: TABLE_KEYS.PRED_RE,
        header: () => (
          <span>
            Reflection <br /> Coeff. [dB]
          </span>
        ),
        cell: ({ getValue }) => <span>{getValue()}</span>,
      }),
    ],
    [rfData]
  );

  const handleSelectedRow = useCallback(
    (row) => {
      dispatch(setSelectedTableRow({ ...row.original, id: Number(row.id) }));
    },
    [selectedTableRow]
  );

  useEffect(() => {
    return () => {
      dispatch(setSelectedTableRow({ frq: 0, real_RC: 0, real_RE: 0, pred_RE: 0, id: 0 }));
    };
  }, []);
  const excelData = useMemo(() => {
    const newExcelData = [...rfData.tableData];
    newExcelData[0] = { ...newExcelData[0], accuracy: aiSubData.accuracy, margin_score: aiSubData.marginScore };

    return newExcelData;
  }, [rfData.tableData, aiSubData]);

  return (
    <Container>
      <RfSideController
        tableData={rfData.tableData}
        rowSelection={rowSelection}
        excelDownload={() => excelDownload(excelData, 'rf_data', TABLE_KEYS)}
        imgDownload={() => {
          handleImgDownload(chartRef);
        }}
      />
      <DataArea>
        <GraphArea ref={chartRef}>
          <FirstGraph>
            <YAxis>
              <YName>Total Radiation Efficiency [dBi]</YName>
            </YAxis>
            <ChartArea>
              <LegendButtonGroup>{chartLegend.aiButton}</LegendButtonGroup>
              <RfLineChart
                id='RF_line_output'
                predRE={rfData.PredRE}
                realRE={rfData.RealRE}
                remoteTooltipControl={{ type: 'showTip', seriesIndex: 1, dataIndex: selectedTableRow?.id ?? 0 }}
                rowSelection={rowSelection}
                legendStatus={legendStatus}
              />
              <XAxis>Frequency [MHz]</XAxis>
            </ChartArea>
          </FirstGraph>
          <SecondeGraph>
            <YAxis>
              <YName>Reflection Coefficient [dB]</YName>
            </YAxis>
            <ChartArea>
              <LegendButtonGroup />

              <RfLineChart
                id='RF_line_input'
                RC={rfData.RC}
                remoteTooltipControl={{ type: 'showTip', seriesIndex: 0, dataIndex: selectedTableRow?.id ?? 0 }}
                rowSelection={rowSelection}
              />
              <XAxis>Frequency [MHz]</XAxis>
            </ChartArea>
          </SecondeGraph>
        </GraphArea>
        <FlexRow>
          <TableArea>
            <AiSubDataContainer>
              <HeaderContainer>
                <HeaderTitle inner='Accuracy' fontWeight={500} fontSize={18} color='#f9f9f9' />
                <HeaderTitle inner='Margin Score' fontWeight={500} fontSize={18} color='#f9f9f9' />
              </HeaderContainer>
              <RowContainer>
                <RowTitle inner={aiSubData.accuracy.toString()} fontWeight={300} fontSize={18} color='#f9f9f9' />
                <RowTitle inner={aiSubData.marginScore.toString()} fontWeight={300} fontSize={18} color='#f9f9f9' />
              </RowContainer>
            </AiSubDataContainer>

            <MainTable
              ref={tableRef}
              data={rfData.tableData}
              columns={COLUMNS_DATA}
              classes={{ Container: ScrollXContainer, THead: StickyHeader }}
              hasTableInfo={false}
              hasPagenation={false}
              hasPageSizeOption={false}
              handleSelectedRow={handleSelectedRow}
            />
          </TableArea>
        </FlexRow>
      </DataArea>
    </Container>
  );
};

RfSolutionMain.propTypes = {
  rfData: PropTypes.shape({
    /** 원본 데이터 */
    RC: PropTypes.array,
    /** 예측 데이터 상태 */
    PredRE: PropTypes.array,
    /** RE GT 데이터 */
    RealRE: PropTypes.array,
    /** Table 데이터 */
    tableData: PropTypes.array,
    /** Line chart scale변화 */
    handleClickXAxis: PropTypes.func,
  }),
};

export default RfSolutionMain;
