import { useMemo, useState, useCallback, useEffect, useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { createColumnHelper } from '@tanstack/react-table';
import styled, { css } from 'styled-components';

import { setSelectedTableRow, setFrqValue } from '@reducer/emiSlice';
import { Button, Div, Table, Text, TextArea, ModalContext } from '@components/index';
import EmiLineChart from '@SAM/components/Chart/EmiLineChart';
import ToggleSam from '@SAM/components/ToggleSam';
import { putUpdateEMICommentByProcessIdAPI } from '@action/emi/emiAction';
import { FormProvider, useForm } from 'react-hook-form';
import ErrorModal from '@SAM/components/Modal/ErrorModal';
import EmiSideController from './EmiSideController';

const columnHelper = createColumnHelper();

const Container = styled(Div)`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
`;
const DataArea = styled(Div)`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #000;
  padding: 30px;
`;
const GraphArea = styled(Div)`
  display: flex;
  margin-bottom: 10px;
  gap: 10px;
`;
const ChartArea = styled(Div)`
  display: flex;
  flex-direction: column;
`;
const YAxis = styled(Div)`
  width: 30px;
  height: 100%;
  background-color: #1d1d1b;
  border: 1px solid #444444;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const YName = styled(Div)`
  transform: rotate(270deg);
  transform-origin: left top 50;
  color: #f9f9f9;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Pretendard';
  white-space: nowrap;
`;
const XAxis = styled(Div)`
  display: flex;
  width: 100%;
  height: 25px;
  background-color: #2a2a2a;
  border-top: 1px solid #444444;
  justify-content: center;
  align-items: center;
  color: #f9f9f9;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Pretendard';
  white-space: nowrap;
`;
const LegendContainer = styled(Div)`
  display: flex;
  align-items: center;
`;

const LegendButtonGroup = styled(Div)`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  height: 40px;
  gap: 25px;
  padding-right: 36px;
  background-color: #2a2a2a;
  border-top: 1px solid #444444;
  border-right: 1px solid #444444;
`;

const TableScrollX = css`
  display: inline-block;
`;
const TableScrollXPeak = css`
  display: inline-block;
  flex-basis: min-content;
  height: 260px;
`;
const StickyHeader = css`
  display: sticky;
  top: 0;
  tr {
    background-color: #1d1d1b;
    color: #fff;
  }
`;

const FirstGraph = styled(Div)`
  display: flex;
  flex: 1;
  width: 100%;
`;
const SecondeGraph = styled(FirstGraph)``;

const LegendToggle = styled(ToggleSam)``;

const FlexRow = styled(Div)`
  display: flex;
  gap: 10px;
`;
const MainTable = styled(Table)`
  flex-basis: 790px;
`;

const PeakTable = styled(Table)`
  flex-basis: 350px;
  min-width: 350px;
`;

const CommentContainer = styled(Div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 150px;
  margin-top: 10px;
`;

const CommentBodyContainer = styled(Div)`
  background-color: rgb(38, 38, 38);
  color: rgb(132, 132, 132);
  width: 100%;
  height: 250px;
  padding: 15px;
`;

const CommentBody = styled(Div)`
  background-color: #1d1d1b;
  color: rgb(132, 132, 132);
  width: 100%;
  height: 100%;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #424242;
`;

const ScrollXContainer = css`
  display: block;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  white-space: nowrap;
  background-color: #252523;

  &::-webkit-scrollbar {
    width: 10px;
    background-color: #343434;
    border-radius: 100px;
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #21b9fe;
    width: 10px;
    height: 35px;
    border-radius: 10px;
  }
`;

const GridContainer = styled(Div)`
  height: 105px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
`;

const GridLayout = styled(Div)`
  height: 100%;
  display: flex;
  border: 1px solid #444444;
  background: #2a2a2a;

  ${({ isScanDone }) => !isScanDone && 'pointer-events: none; opacity: 0.5;'}
`;

const GridInput = styled(Div)`
  width: 100%;
  height: 100%;
`;

const GridInputRight = styled(GridInput)`
  padding-right: 0px;
`;

const buttonCss = css`
  width: 120px;
  border: 1px solid #444444;
  margin: 10px;
  color: white;
  text-decoration: none;

  &:hover {
    color: white;
    text-decoration: none;
  }

  ${({ isScanDone }) => !isScanDone && 'pointer-events: none; opacity: 0.5;'}
`;

const textAreaCss = css`
  background-color: #1d1d1b;
  min-height: 138px;
  width: 100%;
  height: 100%;
  color: white;
  border: 1px solid #444444;
  font-size: 20px;
`;

const TextAreaBox = styled(Div)`
  ${textAreaCss}
  padding: 14.5px 17.5px;
`;

const MemoTextContainer = styled(Div)`
  color: #bebebd;
  h3 {
    font-size: 20px;
    span {
      color: #ffffff;
    }
  }
`;

const MemoTextDesc = styled(Div)`
  font-size: 18px;
  margin-top: 10px;
`;

const EmiSolutionMain = ({ emiData, FORM_KEYS, errors }) => {
  const dispatch = useDispatch();
  const inputForm = useForm();
  const { getValues, setValue } = inputForm;
  const [searchParams] = useSearchParams();
  const { showModal } = useContext(ModalContext);

  const processId = searchParams.get('processId');

  const selectedTableRow = useSelector((state) => state.emi.selectedTableRow);
  const masterInfoList = useSelector((state) => state.emi.aiData.masterInfo);
  const configInfoList = useSelector((state) => state.emi.aiData.configInfo);
  const comment = useSelector((state) => state.emi.aiData.comment);
  const currentProcessId = useSelector((state) => state.emi.aiData.processId);
  const rejectionMessage = useSelector((state) => state.emi.aiData.rejectionMessage);
  const isScanDone = (typeof currentProcessId !== 'undefined' && currentProcessId !== null) || processId;

  const graphRef = useRef(null);
  const tableRef = useRef(null);
  const rowSelection = tableRef.current && tableRef.current.remoteControll;
  const { t } = useTranslation();

  const [legendStatus, setLegendStatus] = useState({ Fv: true, Fh: true, Gv: false, Gh: false });
  const [xAxisType, setXAxisType] = useState({
    type: 'log',
    logBase: 10,
    min: 30,
    max: 1000,
    axisLabel: {
      show: true,
      color: '#F9F9F9',
      fontSize: 16,
      fontFamily: 'Pretendard',
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: '#000000',
        width: 1,
        type: 'solid',
      },
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: '#444444',
        width: 1,
        type: 'solid',
      },
    },
  });

  const handleClickXAxis = useCallback((type) => {
    if (type === 'Line') {
      setXAxisType({
        type: 'value',
        splitNumber: 10,
        min: 30,
        max: 1000,
        axisLabel: {
          show: true,
          color: '#F9F9F9',
          fontSize: 16,
          fontFamily: 'Pretendard',
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#000000',
            width: 1,
            type: 'solid',
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#444444',
            width: 1,
            type: 'solid',
          },
        },
      });
    } else {
      setXAxisType({
        type: 'log',
        logBase: 10,
        min: 30,
        max: 1000,
        axisLabel: {
          show: true,
          color: '#F9F9F9',
          fontSize: 16,
          fontFamily: 'Pretendard',
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#000000',
            width: 1,
            type: 'solid',
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#444444',
            width: 1,
            type: 'solid',
          },
        },
      });
    }
  }, []);

  /* eslint-disable react/no-unstable-nested-components */
  const COLUMNS_DATA = useMemo(
    () => [
      columnHelper.accessor('frq', {
        id: 'frq',
        header: () => (
          <span>
            Frequency <br />
            [MHz]
          </span>
        ),
        cell: ({ getValue }) => {
          return <span>{getValue()}</span>;
        },
        size: 100,
      }),
      columnHelper.accessor('NF', {
        id: 'NF',
        header: () => (
          <span>
            NF <br />
            [dB(uV)]
          </span>
        ),
        cell: ({ getValue }) => <span>{getValue()}</span>,
        size: 100,
      }),
      columnHelper.accessor('Fv', {
        id: 'Fv',
        header: () => (
          <span>
            Fv <br />
            [dB(uV/m)]
          </span>
        ),
        cell: ({ getValue }) => <span>{getValue()}</span>,
        size: 100,
      }),
      columnHelper.accessor('Fh', {
        id: 'Fh',
        header: () => (
          <span>
            Fh <br />
            [dB(uV/m)]
          </span>
        ),
        cell: ({ getValue }) => <span>{getValue()}</span>,
        size: 100,
      }),
      columnHelper.accessor('Gv', {
        id: 'Gv',
        header: () => (
          <span>
            Gv <br />
            [dB(uV/m)]
          </span>
        ),
        cell: ({ getValue }) => <span>{getValue()}</span>,
        size: 100,
      }),
      columnHelper.accessor('Gh', {
        id: 'Gh',
        header: () => (
          <span>
            Gh <br />
            [dB(uV/m)]
          </span>
        ),
        cell: ({ getValue }) => <span>{getValue()}</span>,
        size: 100,
      }),
      columnHelper.accessor('passFail', {
        id: 'passFail',
        header: () => <span>Pass/Fail</span>,
        cell: ({ getValue }) => {
          if (getValue() === 'fail') {
            return <span style={{ color: 'red' }}>{getValue()}</span>;
          }
          return <span>{getValue()}</span>;
        },
        size: 100,
      }),
    ],
    []
  );
  const COLUMNS_DATA_PEAK_V = useMemo(
    () => [
      columnHelper.accessor('frq', {
        id: 'frq',
        header: () => (
          <span>
            Frequency <br />
            [Mhz]
          </span>
        ),
        cell: ({ getValue }) => {
          return <span>{getValue()}</span>;
        },
        size: 100,
      }),
      columnHelper.accessor('margin_v', {
        id: 'margin_v',
        header: () => (
          <span>
            Margin_v <br />
            [dB(uV/m)]
          </span>
        ),
        cell: ({ getValue }) => {
          return <span>{getValue()}</span>;
        },
        size: 100,
      }),

      columnHelper.accessor('passFail', {
        id: 'passFail',
        header: () => <span>Pass/Fail</span>,
        cell: ({ getValue }) => {
          if (getValue() === 'fail') {
            return <span style={{ color: 'red' }}>{getValue()}</span>;
          }
          return <span>{getValue()}</span>;
        },
        size: 100,
      }),
    ],
    []
  );
  const COLUMNS_DATA_PEAK_H = useMemo(
    () => [
      columnHelper.accessor('frq', {
        id: 'frq',
        header: () => (
          <span>
            Frequency <br />
            [Mhz]
          </span>
        ),
        cell: ({ getValue }) => {
          return <span>{getValue()}</span>;
        },
        size: 100,
      }),
      columnHelper.accessor('margin_h', {
        id: 'margin_h',
        header: () => (
          <span>
            margin_h <br />
            [dB(uV/m)]
          </span>
        ),
        cell: ({ getValue }) => {
          return <span>{getValue()}</span>;
        },
        size: 100,
      }),

      columnHelper.accessor('passFail', {
        id: 'passFail',
        header: () => <span>Pass/Fail</span>,
        cell: ({ getValue }) => {
          if (getValue() === 'fail') {
            return <span style={{ color: 'red' }}>{getValue()}</span>;
          }
          return <span>{getValue()}</span>;
        },
        size: 100,
      }),
    ],
    []
  );

  const handleLegendToggle = useCallback(
    (target, value) => {
      setLegendStatus((prev) => ({ ...prev, [target]: value }));
    },
    [legendStatus]
  );

  const chartLegend = useMemo(() => {
    const aiButton = [];
    Object.keys(emiData).forEach((key, index) => {
      if (
        key.length > 0 &&
        key !== 'TableData' &&
        key !== 'NF' &&
        key !== 'LIMITLINE' &&
        key !== 'peakData' &&
        key !== 'vPeak' &&
        key !== 'hPeak'
      ) {
        aiButton.push(
          <LegendContainer key={`${index + 1}-ff`}>
            <Text color='#BEBEBD' inner={key} fontSize={20} fontWeight={500} />
            <LegendToggle value={legendStatus[key]} target={key} callback={handleLegendToggle} />
          </LegendContainer>
        );
      }
    });
    return { aiButton };
  }, [emiData, handleLegendToggle]);

  const memoText = useMemo(() => {
    return (
      <MemoTextContainer>
        <h3>
          <span>{masterInfoList?.modelName}</span>
          {masterInfoList?.modelName && masterInfoList?.inch && ' | '}
          <span>{masterInfoList?.inch}</span>
          {masterInfoList?.inch && ' Inch'}
          <div
            style={{
              color:
                rejectionMessage ===
                '예측된 결과는 신뢰도가 낮아 챔버에서 측정하세요. NF-FF Pair DATA를 등록해주시면 추후 정확한 예측에 도움이 됩니다.'
                  ? '#faff00'
                  : '#ffffff',
            }}
          >
            {rejectionMessage}
          </div>
        </h3>
        <MemoTextDesc>{masterInfoList?.memo}</MemoTextDesc>
      </MemoTextContainer>
    );
  }, [masterInfoList]);

  const frqArr = useMemo(() => {
    return emiData.TableData.map((data) => Number(data.frq));
  }, [emiData.TableData]);

  const findClosestIndex = (arr, target) =>
    arr.reduce(
      (closestIndex, num, index, array) =>
        Math.abs(num - target) < Math.abs(array[closestIndex] - target) ? index : closestIndex,
      0
    );

  const handleSelectedRow = useCallback(
    (row) => {
      const closestIndex = findClosestIndex(frqArr, Number(row.original.frq));

      if (/^\d*\.?\d*$|^\.$/.test(row.original.frq) && typeof rowSelection === 'function') {
        const origin = rowSelection(closestIndex.toString());

        dispatch(
          setSelectedTableRow({
            ...row.original,
            id: closestIndex,
          })
        );
        dispatch(
          setFrqValue({
            frq: Number(row.original.frq),
            NF: Number(origin.NF),
            Fv: Number(origin.Fv),
            Fh: Number(origin.Fh),
            Gv: Number(origin.Gv),
            Gh: Number(origin.Gh),
            id: closestIndex,
          })
        );
      }
    },
    [rowSelection]
  );

  const handleSelectedRowMain = useCallback(
    (row) => {
      dispatch(setSelectedTableRow({ ...row.original, id: Number(row.id) }));
    },
    [selectedTableRow]
  );

  const onClickUpdateEmiComment = useCallback(() => {
    const params = {
      processId: currentProcessId ?? processId,
      comment: getValues('comment'),
    };
    putUpdateEMICommentByProcessIdAPI(params, (resResult) => {
      if (resResult?.status === false) {
        showModal(<ErrorModal errorMessage={t(resResult?.message) ?? ''} />);
      }
    });
  }, [isScanDone, comment]);

  useEffect(() => {
    return () => dispatch(setSelectedTableRow({ frq: 0, NF: 0, Fv: 0, Fh: 0, Gv: 0, Gh: 0, passFail: '', id: null }));
  }, []);

  useEffect(() => {
    if (comment) {
      console.log('###hello');
      setValue('comment', comment);
    } else {
      setValue('comment', '');
    }
  }, [comment]);

  return (
    <Container>
      <EmiSideController
        FORM_KEYS={FORM_KEYS}
        errors={errors}
        handleClickXAxis={handleClickXAxis}
        tableData={emiData.TableData}
        emiData={emiData}
        configInfo={configInfoList}
        rowSelection={rowSelection}
        graphRef={graphRef}
        masterInfoList={masterInfoList}
        rejectionMessage={rejectionMessage}
      />
      <DataArea>
        <GraphArea ref={graphRef}>
          <FirstGraph>
            <YAxis>
              <YName>Near-Field [dB(uV)]</YName>
            </YAxis>
            <ChartArea>
              <LegendButtonGroup />
              <EmiLineChart
                id='EMI_line_input'
                lineData={emiData.NF}
                xAxisType={xAxisType}
                remoteTooltipControl={{ type: 'showTip', seriesIndex: 0, dataIndex: selectedTableRow?.id ?? 0 }}
                rowSelection={rowSelection}
              />
              <XAxis>Frequency [MHz]</XAxis>
            </ChartArea>
          </FirstGraph>
          <SecondeGraph>
            <YAxis>
              <YName>Far-Field [dB(uV/m)]</YName>
            </YAxis>
            <ChartArea>
              <LegendButtonGroup>{chartLegend.aiButton}</LegendButtonGroup>
              <EmiLineChart
                id='EMI_line_output'
                limitData={emiData.LIMITLINE}
                fvData={emiData.Fv}
                fhData={emiData.Fh}
                gvData={emiData.Gv}
                ghData={emiData.Gh}
                vPeak={emiData.vPeak}
                hPeak={emiData.hPeak}
                legendStatus={legendStatus}
                xAxisType={xAxisType}
                remoteTooltipControl={{ type: 'showTip', seriesIndex: 1, dataIndex: selectedTableRow?.id ?? 0 }}
                rowSelection={rowSelection}
              />
              <XAxis>Frequency [MHz]</XAxis>
            </ChartArea>
          </SecondeGraph>
        </GraphArea>
        <FlexRow>
          <MainTable
            ref={tableRef}
            data={emiData.TableData}
            columns={COLUMNS_DATA}
            classes={{ Container: TableScrollX, THead: StickyHeader, TBody: ScrollXContainer }}
            hasTableInfo={false}
            hasPagenation={false}
            hasPageSizeOption={false}
            handleSelectedRow={handleSelectedRowMain}
          />
          <PeakTable
            // ref={tableRef}
            data={emiData.vPeak ?? []}
            columns={COLUMNS_DATA_PEAK_V}
            classes={{ Container: TableScrollXPeak, THead: StickyHeader, TBody: ScrollXContainer }}
            hasTableInfo={false}
            hasPagenation={false}
            hasPageSizeOption={false}
            handleSelectedRow={handleSelectedRow}
          />
          <PeakTable
            // ref={tableRef}
            data={emiData.hPeak ?? []}
            columns={COLUMNS_DATA_PEAK_H}
            classes={{ Container: TableScrollXPeak, THead: StickyHeader, TBody: ScrollXContainer }}
            hasTableInfo={false}
            hasPagenation={false}
            hasPageSizeOption={false}
            handleSelectedRow={handleSelectedRow}
          />
        </FlexRow>
        <FlexRow>
          <CommentContainer>
            {masterInfoList && masterInfoList.comment && (
              <CommentBodyContainer>
                <CommentBody>{masterInfoList && masterInfoList.comment}</CommentBody>
              </CommentBodyContainer>
            )}
            <FormProvider {...inputForm}>
              <GridContainer>
                <GridLayout isScanDone={isScanDone}>
                  <YAxis>
                    <YName>Memo</YName>
                  </YAxis>
                  <GridInput>
                    <TextAreaBox>{memoText}</TextAreaBox>
                  </GridInput>
                </GridLayout>
                <GridLayout isScanDone={isScanDone}>
                  <YAxis>
                    <YName>Comment</YName>
                  </YAxis>
                  <GridInputRight>
                    <TextArea
                      name='comment'
                      classes={{ Variant: textAreaCss }}
                      placeholder={getValues('comment') ? getValues('comment') : 'Input your Comment'}
                    />
                  </GridInputRight>
                  <Button onClick={onClickUpdateEmiComment} classes={{ Button: buttonCss }} isScanDone={isScanDone}>
                    Enter
                  </Button>
                </GridLayout>
              </GridContainer>
            </FormProvider>
          </CommentContainer>
        </FlexRow>
      </DataArea>
    </Container>
  );
};

EmiSolutionMain.propTypes = {
  emiData: PropTypes.shape({
    /* 데이터 준비 상태 */
    isReady: PropTypes.bool,
    /* 원본 데이터 */
    NF: PropTypes.array,
    /* 리미트 라인 데이터 */
    LIMITLINE: PropTypes.array,
    /* AI FvFF Peak  데이터 */
    Fv: PropTypes.array,
    /* AI Fh Peak  데이터 */
    Fh: PropTypes.array,
    /* AI Gv Peak  데이터 */
    Gv: PropTypes.array,
    /* AI Gh Peak  데이터 */
    Gh: PropTypes.array,
    /* AI vPeak  데이터 */
    vPeak: PropTypes.array,
    /* AI hPeak  데이터 */
    hPeak: PropTypes.array,
    /* AI FF 라인 데이터 */
    FF: PropTypes.array,
    /* AI GT 라인 데이터 */
    GT: PropTypes.array,
    /* AI FF Peak  데이터 */
    PeakData: PropTypes.array,
    /* 2D Table 데이터 */
    TableData: PropTypes.array,
    /* 2D Table 데이터 */
    handleClickXAxis: PropTypes.func,
  }),
  masterInfo: PropTypes.shape({
    masterId: PropTypes.number,
    InputFilename: PropTypes.string,
    createdAt: PropTypes.string,
    email: PropTypes.string,
    serialNo: PropTypes.string,
    inputFilename: PropTypes.string,
  }),
  FORM_KEYS: PropTypes.object,
  errors: PropTypes.object,
};

export default EmiSolutionMain;
