// export const BASE_URL = 'http://localhost:8080'; // normal local setting
// export const BASE_URL = 'http://10.114.91.24:8080'; // seoul pc ip
// export const BASE_URL = 'http://10.33.124.63:8080'; // suwon pc ip
export const BASE_URL = 'http://samsungai.t-win.kr'; // t-win server <= 비상시 사용할 도메인, t-win server 로 전체 검색하면 사용하는 곳 나옵니다.
// export const BASE_URL = 'http://211.221.208.16:8080'; // test server

export const getUrl = (endStr, serverType) => {
  let base = '';
  if (serverType === 'localhost') {
    base = BASE_URL;
  }

  return base + endStr;
};

export const HEADER = {
  GET: { headers: { 'Content-Type': 'application/json' } },
  POST: { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
  MULTIPART: { headers: { 'Content-Type': 'multipart/form-data' } },
  TEXT: { headers: { 'Content-Type': 'text/plain' } },
  OCTET: { headers: { 'Content-Type': 'application/octet-stream' } },
  XML: { headers: { 'Content-Type': 'application/xml' } },
  HTML: { headers: { 'Content-Type': 'text/html' } },
  PDF: { headers: { 'Content-Type': 'application/pdf' } },
  CSV: { headers: { 'Content-Type': 'text/csv' } },
};
