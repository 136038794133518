import { useDispatch } from 'react-redux';
import { getPredictionProcessListAPI } from '@action/emi/emiAction';
import { useFormContext } from 'react-hook-form';

import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { Div, Button, Input } from '@components/index';
import TableMultiSelectBox from '@components/DataDisplay/Table/modules/TableMultiSelectBox';
import { useTranslation } from 'react-i18next';

const Container = styled(Div)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const InputFormStyle = css`
  background-color: #363636;
  color: #c1c1c1;
  height: 40px;
  border-radius: 5px;
  border: 0px;
`;

const SubOptionContainer = styled(Div)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 10px;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.gray08};
`;

const FilterButton = styled(Button)`
  background-color: #366eff;
  height: 40px;
  text-decoration: none;
  border-radius: 5px;
  padding: 16px 0;
  color: #ccc;

  &:hover {
    text-decoration: none;
    color: white;
    font-weight: 700;
  }
`;

const ColumnBox = styled(Div)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Row = styled(Div)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FilterRow = styled(Row)`
  gap: 10px;
  justify-content: flex-start;
`;

const SelectboxCSS = css`
  background-color: #3b3b3b;
  color: #ccc;
`;

const FORM_KEYS = Object.freeze({
  CATEGORY: 'category',
  KEYWORD: 'keyword',
});

const SearchContainer = ({ options, hasColumnFilter, hasInputSelectBox = true }) => {
  const dispatch = useDispatch();
  const { handleSubmit } = useFormContext();
  const { t } = useTranslation();

  const handleSearchClick = ({ category, keyword }) => {
    if (!keyword) {
      getPredictionProcessListAPI(dispatch, {});
    }

    const params = {
      category,
      keyword,
    };

    getPredictionProcessListAPI(dispatch, params);
  };

  return (
    <Container>
      <SubOptionContainer>
        <ColumnBox>
          <Row>
            {hasColumnFilter && (
              <FilterRow>
                {hasInputSelectBox && (
                  <TableMultiSelectBox
                    name={FORM_KEYS.CATEGORY}
                    classes={{ Variant: { StyledContainer: SelectboxCSS, OptionList: SelectboxCSS } }}
                    options={options}
                    w={150}
                    h={40}
                    initialLabel={t('PLACEHOLDER.FILTER')}
                  />
                )}
                <Input
                  type='text'
                  classes={{ Variant: InputFormStyle }}
                  name={FORM_KEYS.KEYWORD}
                  placeholder={t('PLACEHOLDER.INPUT')}
                  w={471}
                  h={40}
                  fontSize={14}
                />
                <FilterButton width={59} height={40} fontSize={14} onClick={handleSubmit(handleSearchClick)}>
                  {t('COMMON.BUTTON.SEARCH')}
                </FilterButton>
              </FilterRow>
            )}
          </Row>
        </ColumnBox>
      </SubOptionContainer>
    </Container>
  );
};

SearchContainer.propTypes = {
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  hasColumnFilter: PropTypes.bool,
  /** Table Input 옆 필터 SelectBox 존재유무 */
  hasInputSelectBox: PropTypes.bool,
};

export default SearchContainer;
