import { useRef, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as echarts from 'echarts';

import { Div } from '@components/index';

const LineChartContainer = styled(Div)`
  background-color: #2a2a2a;
  border-right: 1px solid #444444;
`;

const BikeLineChart = ({ id, bikeDataOriginal, bikeDataAi, chartMaxY, width, height, legendStatus }) => {
  const lineChartRef = useRef(null);
  const controllableRef = useRef(null);

  // eslint-disable-next-line
  const [linChartOption, setLinChartOption] = useState(() => {
    const option = {
      animation: false, // 애니메이션 비활성화
      legend: {
        show: false,

        selected: {
          count: true,
          predictionCount: true,
        },
        data: ['count', 'predictionCount'],
        textStyle: {
          color: 'white',
        },
      },

      grid: {
        show: true,
        top: '10px',
        bottom: '30px',
        left: '30px',
        right: '20px',
        backgroundColor: '#000000',
        borderWidth: 1,
        borderColor: '#000000',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        backgroundColor: 'rgba(50,50,50,0.7)',
        borderWidth: 0,
        textStyle: {
          color: 'white',
        },
      },
      xAxis: {
        type: 'value',
        min: 0,
        max: 23,
        splitNumber: 24,
        axisLabel: {
          show: true,
          color: '#F9F9F9',
          fontSize: 16,
          fontFamily: 'Pretendard',
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#000000',
            width: 1,
            type: 'solid',
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#444444',
            width: 1,
            type: 'solid',
          },
        },
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          show: true,
          color: '#F9F9F9',
          fontSize: 16,
          fontFamily: 'Pretendard',
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#000000',
            width: 1,
            type: 'solid',
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#444444',
            width: 1,
            type: 'solid',
          },
        },
      },

      dataZoom: [
        {
          id: 'dataZoomX',
          type: 'inside',
          xAxisIndex: 0,
          start: 0,
          end: 100,
        },
        {
          id: 'dataZoomY',
          type: 'inside',
          yAxisIndex: [0],
          filterMode: 'filter',
        },
      ],
      series: [],
    };
    return option;
  });
  const chartInstance = useRef(null);
  useEffect(() => {
    if (lineChartRef.current) {
      chartInstance.current = echarts;
      lineChartRef.current = chartInstance.current.init(lineChartRef.current);
      const updatedOption = { ...linChartOption };

      const nomalizeLine = ['atemp', 'casual', 'humidity', 'temp', 'windSpeed'];
      const colors = [
        '#FF5733', // bright red-orange
        '#33FF57', // bright green
        '#3357FF', // bright blue
        '#FF33A8', // bright pink
        '#FFEB33', // bright yellow
        '#33FFF3', // bright cyan
        '#F333FF', // bright magenta
        '#FF8F33', // bright orange
        '#8FFF33', // bright lime green
        '#FF3333', // bright red
        '#33B5E5', // bright sky blue
      ];

      if (
        bikeDataOriginal &&
        Array.isArray(Object.keys(bikeDataOriginal)) &&
        Object.keys(bikeDataOriginal).length > 0
      ) {
        updatedOption.series = Object.keys(bikeDataOriginal).map((key, index) => {
          return {
            name: key,
            type: 'line',
            showSymbol: false,
            data: bikeDataOriginal[key],
            colorBy: 'data',
            triggerLineEvent: true,
            triggerAreaEvent: true,
            smooth: false,
            itemStyle: {
              color: colors[index],
            },
            lineStyle: {
              width: 2,
              color: colors[index],
              type: nomalizeLine.includes(key) ? 'dashed' : 'solid',
            },
          };
        });
        updatedOption.yAxis.max = chartMaxY;
        updatedOption.yAxis.min = -2;
        updatedOption.yAxis.name = 'Condition Embedding [nomalized]';
      }
      if (bikeDataAi && Array.isArray(Object.keys(bikeDataAi)) && Object.keys(bikeDataAi).length > 0) {
        updatedOption.series = Object.keys(bikeDataAi).map((key, index) => {
          return {
            name: key,
            type: 'line',
            symbol: index === 0 ? 'emptyRect' : 'emptyCircle',
            symbolSize: 8,

            data: bikeDataAi[key],
            colorBy: 'data',
            triggerLineEvent: true,
            triggerAreaEvent: true,
            smooth: false,
            itemStyle: {
              color: colors[index],
            },
            lineStyle: {
              width: 2,
              color: colors[index],
              type: nomalizeLine.includes(key) ? 'dashed' : 'solid',
            },
          };
        });

        updatedOption.yAxis.name = 'Number of bycycles';
      }

      if (legendStatus) {
        updatedOption.legend.selected = legendStatus;
      }
      lineChartRef.current.setOption(updatedOption);
    }
    controllableRef.current = lineChartRef.current;
    return () => {
      if (chartInstance.current && lineChartRef.current) {
        chartInstance.current.dispose(lineChartRef.current);
      }
    };
  }, [id, linChartOption, bikeDataOriginal, bikeDataAi, chartMaxY, legendStatus]);

  return <LineChartContainer id={id} ref={lineChartRef} style={{ width, height }} />;
};

BikeLineChart.propTypes = {
  /** Eventlistener를 부착할 id */
  id: PropTypes.string.isRequired,
  /** 원본 그래프 데이터 */
  bikeDataOriginal: PropTypes.shape({
    season: PropTypes.array,
    holiday: PropTypes.array,
    workingDay: PropTypes.array,
    weather: PropTypes.array,
    temp: PropTypes.array,
    atemp: PropTypes.array,
    humidity: PropTypes.array,
    windSpeed: PropTypes.array,
    casual: PropTypes.array,
  }),
  bikeDataAi: PropTypes.shape({
    count: PropTypes.array,
    predictionCount: PropTypes.array,
  }),
  /** 라인 노출여부를 외부 상태로 제어함 */
  legendStatus: PropTypes.shape({
    count: PropTypes.bool,
    predictionCount: PropTypes.bool,
  }),
  chartMaxY: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default BikeLineChart;
