import { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import styled, { css } from 'styled-components';

import { BASE_URL, HEADER } from '@TAxiosConfig';
import { getFileList, setCurrentMasterId, setBikeOriginalData } from '@reducer/bikeSlice';
import { convertDateToString } from '@utils';
import { Div, Img, ModalContext, Text, Modal, Input, Button } from '@components/index';
import { NFImportBg } from '@SAM/images';
import ErrorModal from '@SAM/components/Modal/ErrorModal';

const ImportContainer = styled(Div)`
  display: flex;
  row-gap: 20px;
  width: 800px;
  height: 200px;
`;

const disabledStyle = css`
  background-color: #353535;
  color: #c1c1c1;
  height: 35px;
  border-radius: 5px;
  border: 0px;
  width: 215px;
  border-radius: 5px;
  border: 0px;
  :hover {
    border-radius: 5px;
    border: 0px;
  }
`;

const FileFormStyle = css`
  background-color: #e0e0e0;
  color: #5e5e5e;
  height: 35px;
  border-radius: 5px;
  border: 0px;
  flex: 1;
  width: 300px;
  border-radius: 5px;
  border: 0px;
  :hover {
    border-radius: 5px;
    border: 0px;
  }
`;

const ImgContainer = styled(Div)`
  position: relative;
  color: #fff;
  background: #20b9fd;
`;
const ImportText = styled(Text)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 45px;
  font-weight: 600;
`;
const FormContainer = styled(Div)`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  height: 230px;
  margin-top: 30px;
  padding: 30px;
  width: 500px;
`;

const FormRow = styled(Div)`
  display: flex;
  background-color: #262626;
  gap: 10px;
`;

const ImportImg = styled(Img)`
  width: 300px;
  height: 390px;
  opacity: 0.15;
`;

const NFButton = styled(Button)`
  background-color: #20b9fd;
  height: 35px;
  text-decoration: none;
  border-radius: 5px;
  padding: 16px 0;
  color: #fff;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  &:hover {
    text-decoration: none;
    color: white;
    font-weight: 700;
  }
`;
const SelectFile = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #20b9fd;
  height: 35px;
  width: 130px;
  text-decoration: none;
  border-radius: 5px;
  padding: 16px 0;
  color: #fff;

  &:hover {
    text-decoration: none;
    color: #fff;
    font-weight: 700;
  }
`;

const BGColor = css`
  background-color: #262626;
  flex: 1;
  padding: 0;
`;

const ModalBody = css`
  padding: 0;
`;

const Label = styled.label`
  cursor: pointer;
`;

const FORM_KEYS = Object.freeze({
  USERID: 'USERID',
  DATE: 'DATE',
  FILENAME: 'FILENAME',
});

const BikeInputForm = () => {
  const dispatch = useDispatch();
  const { showModal, closeModal } = useContext(ModalContext);
  const methods = useForm();
  const { handleSubmit } = methods;
  const { t } = useTranslation();

  const fileMetaData = useSelector((state) => state.bike.fileMetaData);

  const submitCallback = useCallback(
    // eslint-disable-next-line no-unused-vars
    (data) => {
      const targetFile = getFileList()[0];
      const formData = new FormData();
      // 서버 수정되면 아래 반영 File 객체는 non-serializable임
      formData.append('file', targetFile);
      formData.append('fileName', fileMetaData.name);

      axios
        .post(`${BASE_URL}/api/bike/uploadInputFile`, formData, HEADER.MULTIPART)
        .then((res) => {
          const masterId = res.data.body.masterId;
          dispatch(setCurrentMasterId(masterId));
          // 업로드 시 반환받은 targetFile의 id를 가지고 서버에 저장된 원본 데이터를 불러옴
          axios
            .get(`${BASE_URL}/api/bike/getBikeInputDataByMasterId`, { params: { masterId } }, HEADER.GET)
            .then((inputDataResponse) => {
              dispatch(setBikeOriginalData(inputDataResponse.data.body));
            });
          closeModal();
        })
        .catch((err) => {
          showModal(<ErrorModal errorMessage={t(err.response.data?.message) ?? ''} />);
        });
    },
    [fileMetaData]
  );

  return (
    <FormProvider {...methods}>
      <FormContainer>
        <FormRow>
          <Input
            name={FORM_KEYS.USERID}
            classes={{ Variant: disabledStyle }}
            type='text'
            value='chamberless@samsung.com'
          />
          <Input
            name={FORM_KEYS.DATE}
            classes={{ Variant: disabledStyle }}
            type='text'
            required
            value={convertDateToString(new Date(), 'dot')}
          />
        </FormRow>
        <FormRow>
          <Input
            classes={{ Variant: FileFormStyle }}
            type='text'
            name={FORM_KEYS.FILENAME}
            required
            value={fileMetaData.name ?? ''}
            placeholder={t('PLACEHOLDER.FILE')}
            disabled
          />
          <Label htmlFor='bike-import'>
            <SelectFile>{t('COMMON.BUTTON.FILE')}</SelectFile>
          </Label>
        </FormRow>
        <NFButton onClick={handleSubmit(submitCallback)}>{t('COMMON.BUTTON.CONFIRM')}</NFButton>
      </FormContainer>
    </FormProvider>
  );
};

const BikeImportModal = () => {
  return (
    <ImportContainer>
      <ImgContainer>
        <ImportText inner='Bike Import' />
        <ImportImg src={NFImportBg} />
      </ImgContainer>
      <Modal classes={{ Container: BGColor, Header: BGColor, Body: ModalBody }} body={<BikeInputForm />} />
    </ImportContainer>
  );
};

export default BikeImportModal;
