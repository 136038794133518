import { useEffect, useCallback, useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import styled, { css } from 'styled-components';
import { createColumnHelper } from '@tanstack/react-table';

import { Div, ModalContext } from '@components/index';
import SearchContainer from '@SAM/components/SearchContainer';
import { getPredictionProcessListAPI } from '@action/emi/emiAction';
import ErrorModal from '@SAM/components/Modal/ErrorModal';
import TableCustom from '@components/DataDisplay/Table/TableCustom';
import { useTranslation } from 'react-i18next';

const columnHelper = createColumnHelper();

const ScrollXContainer = css`
  display: block;
  width: 100%;
  max-height: 860px;
  overflow-y: auto;
  white-space: nowrap;
  flex: 1;
  padding-right: 20px;

  &::-webkit-scrollbar {
    width: 10px;
    background-color: #343434;
    border-radius: 100px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #366eff;
    width: 10px;
    height: 35px;
    border-radius: 10px;
  }
`;

const EmiContainer = styled(Div)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const MainContainer = styled(Div)`
  position: relative;
  width: 100%;
  padding: 30px 30px 0 30px;
  background-color: #272727;
`;
const MainContentArea = styled(Div)``;
const DataGridArea = styled(Div)`
  background-color: #272727;
`;

const TableArea = styled(Div)``;

const StickyHeader = css`
  display: sticky;
  top: 0;
  tr {
    background-color: #1d1d1b;
    color: #fff;
  }
`;

const EmiDatamanagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const { showModal, closeModal } = useContext(ModalContext);

  const inputForm = useForm();
  const predictProcessList = useSelector((state) => state.emi.predictProcessList);
  const { t } = useTranslation();

  const handleNavigate = (pathname) => {
    navigate(pathname);
  };

  const TABLE_KEYS = Object.freeze({
    EMAIL: 'email',
    MEMBER_NAME: 'memberName',
    MODEL_NAME: 'modelName',
    // INCH: 'inch',
    MEMO: 'memo',
  });

  const TABLE_OPTIONS = [
    { name: TABLE_KEYS.EMAIL, title: 'email', checked: false },
    { name: TABLE_KEYS.MEMBER_NAME, title: 'member name', checked: false },
    { name: TABLE_KEYS.MODEL_NAME, title: 'model name', checked: false },
    { name: TABLE_KEYS.MEMO, title: 'memo', checked: false },
  ];

  /* eslint-disable react/no-unstable-nested-components */
  const COLUMNS_DATA = useMemo(
    () => [
      columnHelper.accessor('createdAt', {
        id: 'createdAt',
        header: () => <span>Date</span>,
        cell: ({ getValue }) => {
          return <span>{getValue().substring(0, 10)}</span>;
        },
      }),
      columnHelper.accessor('memberName', {
        id: 'memberName',
        header: () => <span>User</span>,
        cell: ({ getValue }) => {
          return <span>{getValue()}</span>;
        },
      }),
      columnHelper.accessor('modelName', {
        id: 'modelName',
        header: () => <span>Model Name</span>,
        cell: ({ getValue }) => <span>{getValue()}</span>,
      }),
      columnHelper.accessor('inputFilePath', {
        id: 'inputFilePath',
        header: () => <span>Input File Path</span>,
        cell: ({ getValue }) => <span style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{getValue()}</span>,
      }),
      columnHelper.accessor('inch', {
        id: 'inch',
        header: () => <span>Inch</span>,
        cell: ({ getValue }) => <span>{getValue()}</span>,
      }),
      columnHelper.accessor('memo', {
        id: 'memo',
        header: () => <span>Memo</span>,
        cell: ({ getValue }) => <span>{getValue()}</span>,
      }),
      columnHelper.accessor('email', {
        id: 'email',
        header: () => <span>Email</span>,
        cell: ({ getValue }) => <span>{getValue()}</span>,
      }),
    ],
    []
  );
  /* eslint-enable react/no-unstable-nested-components */

  const handleSelectedRow = useCallback(
    (row) => {
      const newParams = { masterId: row?.original?.masterId, processId: row?.original?.processId };
      setSearchParams(newParams);

      const queryString = new URLSearchParams(newParams).toString();
      handleNavigate(`/solution/emi?${queryString}`);
    },
    [navigate, setSearchParams]
  );

  useEffect(() => {
    const params = {};
    getPredictionProcessListAPI(dispatch, params, (resResult) => {
      if (resResult?.status === false) {
        showModal(<ErrorModal errorMessage={t(resResult?.message) ?? ''} />);
      } else {
        closeModal();
      }
    });
  }, []);

  return (
    <EmiContainer>
      <FormProvider {...inputForm}>
        <MainContainer>
          <MainContentArea>
            <DataGridArea>
              <TableArea>
                <SearchContainer options={TABLE_OPTIONS} hasColumnFilter />
                {predictProcessList && (
                  <TableCustom
                    data={predictProcessList}
                    columns={COLUMNS_DATA}
                    cellWidthCustom={{ inputFilePath: '40%' }}
                    classes={{ Container: ScrollXContainer, THead: StickyHeader }}
                    hasTableInfo={false}
                    hasPagenation={false}
                    hasPageSizeOption={false}
                    hasColumnFilter
                    handleSelectedRow={handleSelectedRow}
                  />
                )}
              </TableArea>
            </DataGridArea>
          </MainContentArea>
        </MainContainer>
      </FormProvider>
    </EmiContainer>
  );
};

EmiDatamanagement.propTypes = {};

export default EmiDatamanagement;
