const routerStructure = {
  solution: {
    name: 'solution',
    pathname: 'solution',
    routes: [
      { name: 'emi', pathname: 'emi' },
      { name: 'rf', pathname: 'rf' },
      { name: 'bike', pathname: 'bike' },
    ],
  },
  debugging: {
    name: 'debugging',
    pathname: 'debugging/emi3d',
    routes: [{ name: '', pathname: 'emi3d' }],
  },
  dataManagement: {
    name: 'datamanagement',
    pathname: 'datamanagement',
    routes: [{ name: '', pathname: '' }],
  },
};

export default Object.freeze(routerStructure);
