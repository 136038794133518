import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { Div, Text, Button, Span } from '@components/index';
import {
  EMISolutionFirst,
  EMISolutionSecond,
  RFSolutionFirst,
  RFSolutionSecond,
  BikeSolutionFirst,
  BikeSolutionSecond,
  ContentBG1,
} from '@SAM/images';
import { Trans, useTranslation } from 'react-i18next';

const MainContainer = styled(Div)`
  position: relative;
  display: flex;
  padding: 0 60px;
  padding-top: 97px;
`;

const ComtentsContainer = styled(Div)`
  display: flex;
  align-items: center;
  width: 100%;
`;

const SolutionArea = styled(ComtentsContainer)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Introduction = styled(Div)`
  display: flex;
  height: 100%;
`;

const IntroductionTextContainer = styled(Div)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 80px;
  flex: 1;
  gap: 210px;
  background: url(${ContentBG1}) 50% / cover no-repeat;
`;

const Title = styled(Text)`
  margin-bottom: 35px;
  line-height: normal;
`;

const Desc = styled(Span)`
  line-height: 28px;
  font-size: 18px;
  color: #888;
`;

const ListSDesc = styled.ul`
  line-height: 28px;
  font-size: 18px;
  color: #888;
`;

const LiDesc = styled.li`
  list-style: circle;
`;

const HighlightText = styled(Span)`
  color: #fff;
  font-weight: bold;
`;

const MainSubTitle = styled.h3`
  display: flex;
  justify-content: center;
  background: linear-gradient(90deg, #e757aa, #e063ad, #d28bc2, #83a6dc, #abd3f0);
  color: var(--text-style-h, transparent);
  background-clip: text;
  -webkit-background-clip: text;
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 55px;
  transform: skewX(-10deg);
`;

const CardItem = styled(Div)`
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  flex: 1;
`;

const FirstImgArea = styled(Div)`
  background: ${({ img }) => (img ? `url(${img}) 50% / cover no-repeat` : 'none')};
  transition: transform 0.3s ease;
  width: 100%;
  height: 100%;
  padding-right: 50px;
  flex: 1;
  &:hover {
    transform: scale(1.1);
  }
`;
const SecondImgArea = styled(Div)`
  background: ${({ img }) => (img ? `url(${img}) 50% / cover no-repeat` : 'none')};
  transition: transform 0.3s ease;
  width: 100%;
  height: 100%;
  padding-right: 50px;
  flex: 1;
  &:hover {
    transform: scale(1.1);
  }
`;

const RecommendItem = styled(Div)`
  width: 100%;
  flex: 1;
  margin-bottom: 60px;
`;
const FunctionItem = styled(Div)`
  width: 100%;
  flex: 1;
`;

const ItemsTitle = css`
  font-size: 40px;
  color: #888;
  width: 100%;
  border-bottom: 1px solid #1e1e1e;
  font-weight: 600;
  padding: 110px 0 35px 0;
`;

const ButtonStyle = css`
  width: 50%;
  margin-bottom: 125px;
  justify-content: center;
  text-decoration: none;
  width: 300px;
  height: 60px;
  background-color: #366eff;
  font-size: 20px;
  font-weight: 500;
  border-radius: 100px;
  color: #fff;

  &:hover {
    text-decoration: none;
    color: #fff;
  }
`;

const contents = [
  {
    type: 'EMI',
    firstImg: EMISolutionFirst,
    secondImg: EMISolutionSecond,
    title: <Trans i18nKey='LANDING_CONTENT.EMI.TITLE' components={{ highlighted: <HighlightText /> }} />,
    desc: <Trans i18nKey='LANDING_CONTENT.EMI.DESC' components={{ highlighted: <HighlightText /> }} />,
    secondDesc: (
      <>
        <LiDesc>
          <Trans i18nKey='LANDING_CONTENT.EMI.SECOND_DESC.001' components={{ highlighted: <HighlightText /> }} />
        </LiDesc>
        <LiDesc>
          <Trans i18nKey='LANDING_CONTENT.EMI.SECOND_DESC.002' components={{ highlighted: <HighlightText /> }} />
        </LiDesc>
        <LiDesc>
          <Trans i18nKey='LANDING_CONTENT.EMI.SECOND_DESC.003' components={{ highlighted: <HighlightText /> }} />
        </LiDesc>
        <LiDesc>
          <Trans i18nKey='LANDING_CONTENT.EMI.SECOND_DESC.004' components={{ highlighted: <HighlightText /> }} />
        </LiDesc>
      </>
    ),
  },
  {
    type: 'RF',
    firstImg: RFSolutionFirst,
    secondImg: RFSolutionSecond,
    title: <Trans i18nKey='LANDING_CONTENT.RF.TITLE' components={{ highlighted: <HighlightText /> }} />,
    desc: <Trans i18nKey='LANDING_CONTENT.RF.DESC' components={{ highlighted: <HighlightText /> }} />,
    secondDesc: (
      <>
        <LiDesc>
          <Trans i18nKey='LANDING_CONTENT.RF.SECOND_DESC.001' components={{ highlighted: <HighlightText /> }} />
        </LiDesc>
        <LiDesc>
          <Trans i18nKey='LANDING_CONTENT.RF.SECOND_DESC.002' components={{ highlighted: <HighlightText /> }} />
        </LiDesc>
        <LiDesc>
          <Trans i18nKey='LANDING_CONTENT.RF.SECOND_DESC.003' components={{ highlighted: <HighlightText /> }} />
        </LiDesc>
        <LiDesc>
          <Trans i18nKey='LANDING_CONTENT.RF.SECOND_DESC.004' components={{ highlighted: <HighlightText /> }} />
        </LiDesc>
      </>
    ),
  },
  {
    type: 'BIKE',
    firstImg: BikeSolutionFirst,
    secondImg: BikeSolutionSecond,
    title: <Trans i18nKey='LANDING_CONTENT.BIKE.TITLE' components={{ highlighted: <HighlightText /> }} />,
    desc: <Trans i18nKey='LANDING_CONTENT.BIKE.DESC' components={{ highlighted: <HighlightText /> }} />,
    secondDesc: (
      <>
        <LiDesc>
          <Trans i18nKey='LANDING_CONTENT.BIKE.SECOND_DESC.001' components={{ highlighted: <HighlightText /> }} />
        </LiDesc>
        <LiDesc>
          <Trans i18nKey='LANDING_CONTENT.BIKE.SECOND_DESC.002' components={{ highlighted: <HighlightText /> }} />
        </LiDesc>
        <LiDesc>
          <Trans i18nKey='LANDING_CONTENT.BIKE.SECOND_DESC.003' components={{ highlighted: <HighlightText /> }} />
        </LiDesc>
      </>
    ),
  },
];

const LandingContent = ({ currentIndex }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleNavigate = useCallback((path) => {
    if (path) {
      navigate(path);
    }
  }, []);

  const content = contents[currentIndex];

  return (
    <MainContainer>
      <SolutionArea>
        <MainSubTitle>{content.type === 'RF' ? '' : content.type} SOLUTION</MainSubTitle>
        <RecommendItem>
          <Introduction>
            <CardItem>
              <FirstImgArea img={content.firstImg} />
            </CardItem>
            <CardItem>
              <IntroductionTextContainer>
                <Div>
                  <Title classes={{ Text: ItemsTitle }}>{content.title}</Title>
                  <Desc>{content.desc}</Desc>
                </Div>
                <Button classes={{ Button: ButtonStyle }} onClick={() => handleNavigate(`solution/${content.type}`)}>
                  {t(content.type === 'RF' ? 'LANDING_CONTENT.RFBUTTON' : 'LANDING_CONTENT.BUTTON', {
                    type: content.type,
                  })}
                </Button>
              </IntroductionTextContainer>
            </CardItem>
          </Introduction>
        </RecommendItem>
        <FunctionItem>
          <Introduction>
            <CardItem>
              <IntroductionTextContainer>
                <Div>
                  <Title classes={{ Text: ItemsTitle }} inner={t('LANDING_CONTENT.INTRO_TITLE')} />
                  <ListSDesc>{content.secondDesc}</ListSDesc>
                </Div>
                <Button classes={{ Button: ButtonStyle }} onClick={() => handleNavigate(`solution/${content.type}`)}>
                  {t(content.type === 'RF' ? 'LANDING_CONTENT.RFBUTTON' : 'LANDING_CONTENT.BUTTON', {
                    type: content.type,
                  })}
                </Button>
              </IntroductionTextContainer>
            </CardItem>
            <CardItem>
              <SecondImgArea img={content.secondImg} />
            </CardItem>
          </Introduction>
        </FunctionItem>
      </SolutionArea>
    </MainContainer>
  );
};

LandingContent.propTypes = {
  /** 현재 몇번째 Swiper인지 index를 나타냄 */
  currentIndex: PropTypes.number,
};

export default LandingContent;
