import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { Div } from '@components/Atoms/Atoms';

const getWidth = ({ width }) => `${width || 440}px`;
const getHeight = ({ height }) => `${height || 115}px`;

const styles = css`
  box-sizing: border-box;
  border-radius: 5px;
  resize: none;
  background-color: #e0e0e0;
  padding: 14.5px 17.5px;
  font-family: 'Pretendard';
  font-size: 13px;
  color: rgb(94, 94, 94);
  width: ${getWidth};
  height: ${getHeight};

  &::placeholder {
    color: ${({ theme }) => theme.gray06};
  }
`;

const Container = styled(Div)`
  height: 100%;
`;

const StyledTextArea = styled.textarea`
  ${styles}
  ${({ classes }) => classes && classes.Variant}
`;

const ErrorMsg = styled.p`
  margin-top: 5px;
  color: ${({ theme }) => theme.red};
  font-size: 14px;
  font-weight: 400;
`;

const TextArea = ({ className, classes, name, required, disabled, errorMessage, ...rest }) => {
  const { register } = useFormContext();

  return (
    <Container classes={classes}>
      <StyledTextArea className={className} classes={classes} {...register(name, { required, disabled })} {...rest} />
      {errorMessage && <ErrorMsg>{errorMessage}</ErrorMsg>}
    </Container>
  );
};

TextArea.propTypes = {
  /** HandleSubmit에서 사용되는 고유 key 값 */
  name: PropTypes.string,
  /** 값 선택 필수 유무 */
  required: PropTypes.bool,
  /** 비활성화 유무 */
  disabled: PropTypes.bool,
  /** 읽기전용 유무 */
  readOnly: PropTypes.bool,
  /** ClassName */
  className: PropTypes.string,
  /** 커스텀 스타일 오브젝트 */
  classes: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  /** 유효성 검사 에러 메세지 */
  errorMessage: PropTypes.string,
};

export default TextArea;
