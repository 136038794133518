import { useCallback, useEffect, useRef, useState } from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Div } from '@components/Atoms/Atoms';
import { BottomArrow } from '@images/index';
import { useController, useFormContext } from 'react-hook-form';

const getWidth = ({ w }) => `${w || 100}px`;
const getHeight = ({ h }) => `${h || 100}px`;

const StyledContainer = styled(Div)`
  position: relative;
  /* color: ${({ theme }) => theme.gray07}; */
  background-color: #fff;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
  width: ${getWidth};
  height: ${getHeight};
  ${({ isShow }) => isShow && `border: 1px solid #303030;`}
  ${({ classes }) => classes && classes.Variant.StyledContainer}
  ${({ disabled }) => disabled && `border: 0px; opacity: 0.3;`}
`;

const LabelContainer = styled(Div)`
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  /* color: ${({ theme }) => theme.gray08}; */
  cursor: pointer;
  user-select: none;
  &:after {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    background: url(${BottomArrow});
    background-size: contain;
    background-repeat: no-repeat;
  }
  ${({ classes }) => classes && classes.Variant.LabelContainer}
`;

const OptionList = styled.ul`
  font-size: 14px;
  font-weight: 600;
  /* color: ${({ theme }) => theme.gray08}; */
  position: absolute;
  top: ${getHeight};
  left: 0;
  z-index: 1;
  display: none;
  width: 100%;
  border-radius: 0 0 8px 8px;
  border: 1px solid ${({ theme }) => theme.gray03};
  background: #fff;
  color: #767676;
  overflow: hidden;
  padding: 10px;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.06);
  ${({ isShow }) => isShow && `display:block;`}
  ${({ classes }) => classes && classes.Variant.OptionList}
  ${({ disabled }) => disabled && `display: none !important; height: 0px !important;`}
`;
const OptionItem = styled.li`
  padding: 10px 8px;
  border-radius: 4px;

  &:hover {
    background: rgb(29, 29, 27);
  }

  ${({ isSelected }) => isSelected && `background: #F2EBE0;`}
  ${({ classes }) => classes && classes.Variant.OptionItem}
`;

const TableMultiSelectBox = ({
  name,
  initialLabel,
  w,
  h,
  onUpdateFilterChip,
  checkState,
  required,
  disabled,
  options,
  itemText,
  classes,
  ...props
}) => {
  const { control } = useFormContext();
  const { field } = useController({
    name,
    control,
  });

  const labelBoxRef = useRef();
  const selectBoxRef = useRef();
  const [isShow, setIsShow] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(null);

  const onSelectBoxClick = useCallback(() => {
    setIsShow((prev) => !prev);
  }, []);

  const handlelistItemStatus = useCallback((item) => {
    setSelectedLabel(item.name);
    setIsShow(false);
  }, []);

  const handleOptionItemClick = useCallback((item) => {
    field.onChange(item.name);
    handlelistItemStatus(item);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        selectBoxRef.current &&
        !selectBoxRef.current.contains(event.target) &&
        !labelBoxRef.current.contains(event.target)
      ) {
        setIsShow(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectBoxRef]);

  return (
    <StyledContainer classes={classes} w={w} h={h} isShow={isShow} disabled={disabled} {...props}>
      <LabelContainer ref={labelBoxRef} classes={classes} onMouseDown={onSelectBoxClick}>
        <Div>{selectedLabel ?? initialLabel}</Div>
      </LabelContainer>
      {!disabled && (
        <OptionList ref={selectBoxRef} isShow={isShow} classes={classes} h={h}>
          {options.map((item, index) => {
            return (
              <OptionItem
                // eslint-disable-next-line react/no-array-index-key
                key={`${item?.name}-${index}`}
                onClick={() => {
                  handleOptionItemClick(item);
                }}
                classes={classes}
              >
                {item.title}
              </OptionItem>
            );
          })}
        </OptionList>
      )}
    </StyledContainer>
  );
};

TableMultiSelectBox.propTypes = {
  /** Selectbox 이름 */
  name: PropTypes.string,
  /** 라벨 초기 값 */
  initialLabel: PropTypes.string,
  /** 테이블 selecbox width */
  w: PropTypes.number,
  /** 테이블 selecbox height */
  h: PropTypes.number,
  /** 값 선택 필수 유무 */
  required: PropTypes.bool,
  /** Disabled 여부 */
  disabled: PropTypes.bool,
  /** SelectBox의 목록(list) */
  options: PropTypes.array.isRequired,
  /** SelectBox list의 추가 텍스트 */
  itemText: PropTypes.string,
  /** 커스텀 스타일 오브젝트 */
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  /** 테이블 필터링 키워드 추가 함수 */
  onUpdateFilterChip: PropTypes.func,
  /** 테이블 필터링 키워드 초기화 함수 */
  // onResetFilteredChip: PropTypes.func,
  /** 필터링 아이템 체크 여부 상태 객체 */
  checkState: PropTypes.object,
};

export default TableMultiSelectBox;
