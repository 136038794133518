import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fileMetaData: {
    lastModified: '',
    name: '',
    size: '',
    type: '',
  },
  rfOriginalData: null,
  currentMasterId: null,
  rfPredictionData: [],
  aiSubData: {
    accuracy: 0,
    marginScore: 0,
  },
  selectedTableRow: { frq: 0, real_RC: 0, real_RE: 0, pred_RE: 0 },
};

let fileList = [];
export const getFileList = () => fileList;

/* eslint-disable no-param-reassign */
const rf = createSlice({
  name: 'rf',
  initialState,
  reducers: {
    setRfOriginalData: (state, action) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.rfOriginalData = action.payload.map((data) => {
          return { frequency: Math.floor(data.frequency), real_RC: Math.floor(data.real_RC * 100) / 100 };
        });
      }
    },
    setCurrentMasterId: (state, action) => {
      state.currentMasterId = action.payload;
      state.currentProcess = 'IMPORT';
    },
    setAiData: (state, action) => {
      state.rfPredictionData = action.payload.rfPredictionData;
      state.aiSubData = {
        accuracy: Math.floor(action.payload.accuracy * 100) / 100,
        marginScore: Math.floor(action.payload.marginScore * 100) / 100,
      };
    },
    setSelectedTableRow: (state, action) => {
      state.selectedTableRow = action.payload;
    },
    setFrqValue: (state, action) => {
      state.selectedTableRow.frq = action.payload?.frq ?? 0;
      state.selectedTableRow.real_RC = action.payload.real_RC;
      state.selectedTableRow.real_RE = action.payload.real_RE;
      state.selectedTableRow.pred_RE = action.payload.pred_RE;
      state.selectedTableRow.id = action.payload.id;
    },
    setRfFileMetaData: (state, action) => {
      fileList = action.payload; // Save the FileList outside the state
      [state.fileMetaData] = Array.from(action.payload).map((file) => ({
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified,
      }));
    },
  },
});

export const { setRfOriginalData, setCurrentMasterId, setAiData, setSelectedTableRow, setFrqValue, setRfFileMetaData } =
  rf.actions;
export default rf.reducer;
