import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fileMetaData: {
    lastModified: '',
    name: '',
    size: '',
    type: '',
  },
  bikeOriginalData: null,
  currentMasterId: null,
  bikeResultList: [],
};

let fileList = [];
export const getFileList = () => fileList;

/* eslint-disable no-param-reassign */
const bike = createSlice({
  name: 'bike',
  initialState,
  reducers: {
    setBikeOriginalData: (state, action) => {
      state.bikeOriginalData = action.payload;
    },
    setCurrentMasterId: (state, action) => {
      state.currentMasterId = action.payload;
      state.currentProcess = 'IMPORT';
    },
    setAiData: (state, action) => {
      state.bikeResultList = action.payload;
    },
    setBikeFileMetaData: (state, action) => {
      fileList = action.payload; // Save the FileList outside the state
      [state.fileMetaData] = Array.from(action.payload).map((file) => ({
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified,
      }));
    },
  },
});

export const { setBikeOriginalData, setCurrentMasterId, setAiData, setBikeFileMetaData } = bike.actions;
export default bike.reducer;
