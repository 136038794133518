import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import i18n from '@i18n';
import styled, { css } from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { JoinBg } from '@images/index';
import { signUpAPI } from '@action/user/loginAction';
import { Div, Input, Modal, Button, SelectBox, Img, Text, ModalContext } from '@components';
import ErrorModal from '@SAM/components/Modal/ErrorModal';

const LoginContainer = styled(Div)`
  display: flex;
  width: 850px;
  height: 327px;
`;

const ImgContainer = styled(Div)`
  position: relative;

  color: #fff;
  background: linear-gradient(to right, #a646db, #667eff);
`;

const LoginText = styled(Text)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  font-weight: 600;
`;

const LoginImg = styled(Img)`
  width: 300px;
  height: 327px;
  opacity: 0.15;
`;
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #262626;
  gap: 10px;
`;

const JoinButton = styled(Button)`
  background-color: #20b9fd;
  height: 40px;
  text-decoration: none;
  border-radius: 5px;
  padding: 16px 0;
  color: #fff;

  &:hover {
    text-decoration: none;
    color: #fff;
  }
`;

const InputFormStyle = css`
  background-color: #363636;
  color: #c1c1c1;
  height: 40px;
  border-radius: 5px;
  border: 0px;
`;

const SelectBoxStyle = css`
  background-color: #363636;
  color: #c1c1c1;
  border-radius: 5px;
  border: 0px;
`;

const BGColor = css`
  background-color: #262626;
  flex: 1;
  padding: 0;
`;

const FORM_KEYS = Object.freeze({
  ID: 'id',
  PASSWORD: 'password',
  NAME: 'name',
  DEPARTMENT: 'department',
});

const VALIDATION_REGEXES = {
  EMAIL: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,
};

const schema = yup.object().shape({
  [FORM_KEYS.ID]: yup
    .string()
    .matches(VALIDATION_REGEXES.EMAIL, i18n.t('VALIDATION.ID'))
    .required(i18n.t('VALIDATION.REQUIRED')),
  [FORM_KEYS.PASSWORD]: yup
    .string()
    .matches(VALIDATION_REGEXES.PASSWORD, i18n.t('VALIDATION.PASSWORD'))
    .required(i18n.t('VALIDATION.REQUIRED')),
  [FORM_KEYS.NAME]: yup.string().min(2, i18n.t('VALIDATION.NAME.MIN')).max(17, i18n.t('VALIDATION.NAME.MAX')),
  [FORM_KEYS.DEPARTMENT]: yup.string().required(i18n.t('VALIDATION.REQUIRED')),
});

const departmentOptions = {
  guide: i18n.t('PLACEHOLDER.DEPARTMENT'),
  list: [
    { id: 'ADMIN', title: i18n.t('COMMON.DEPARTMENT.001') },
    { id: 'TV', title: i18n.t('COMMON.DEPARTMENT.002') },
    { id: 'MOBILE', title: i18n.t('COMMON.DEPARTMENT.003') },
  ],
};

const JoinForm = () => {
  const dispatch = useDispatch();
  const methods = useForm({ resolver: yupResolver(schema) });
  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const { showModal, closeModal } = useContext(ModalContext);
  const { t } = useTranslation();

  const handleJoin = (data) => {
    const { id, password, name } = data;
    const userParams = {
      email: id,
      password,
      memberName: name,
      memberRole: 'ADMIN',
    };
    signUpAPI(dispatch, userParams, (resResult) => {
      if (resResult?.status === false) {
        showModal(<ErrorModal errorMessage={t(resResult?.message) ?? ''} />);
      } else {
        closeModal();
      }
    });
  };

  return (
    <FormProvider {...methods}>
      <FormContainer>
        <Input
          name={FORM_KEYS.ID}
          classes={{ Variant: InputFormStyle }}
          type='text'
          errorMessage={errors[FORM_KEYS.ID]?.message}
          placeholder={t('PLACEHOLDER.ID')}
          required
        />
        <Input
          name={FORM_KEYS.PASSWORD}
          classes={{ Variant: InputFormStyle }}
          type='password'
          errorMessage={errors[FORM_KEYS.PASSWORD]?.message}
          placeholder={t('PLACEHOLDER.PASSWORD')}
          required
        />
        <SelectBox
          name={FORM_KEYS.DEPARTMENT}
          classes={{ Variant: { LabelContainer: SelectBoxStyle } }}
          h={40}
          options={departmentOptions}
          errorMessage={errors[FORM_KEYS.DEPARTMENT]?.message}
        />
        <Input
          name={FORM_KEYS.NAME}
          classes={{ Variant: InputFormStyle }}
          type='text'
          errorMessage={errors[FORM_KEYS.NAME]?.message}
          placeholder={t('PLACEHOLDER.NAME')}
          required
        />
        <JoinButton onClick={handleSubmit(handleJoin)}>{t('COMMON.SIGNUP')}</JoinButton>
      </FormContainer>
    </FormProvider>
  );
};

const JoinModal = () => {
  return (
    <LoginContainer>
      <ImgContainer>
        <LoginText inner='SIGN UP' />
        <LoginImg src={JoinBg} />
      </ImgContainer>
      <Modal classes={{ Container: BGColor }} body={<JoinForm />} />
    </LoginContainer>
  );
};

export default JoinModal;
