import TAxios from '@TAxios';
import { HEADER } from '@TAxiosConfig';
import { setPredictProcessList, setGraphDataByProcessId, setCommandLineParameter } from '@reducer/emiSlice';

const BASE_URL = '/api/emi';

/**
 * @namespace GetPredictionProcessListAPI
 * @param {Function} dispatch - Dispatch 함수
 * @param {object} parms - Get parameters
 */

const getPredictionProcessListAPI = (dispatch, params, resResult) => {
  const URL = `${BASE_URL}/getPredictionProcessList`;

  const onSuccess = (res) => {
    if (res.data.status === 'ERROR') {
      // alert('유저 별 모듈 실행 이력 전체 조회 실패');
      return resResult && resResult({ status: false, message: res.data?.errorCode });
    }
    dispatch(setPredictProcessList(res.data.body));
    return resResult && resResult({ status: true });
  };

  const onFail = (err) => {
    console.error(`${BASE_URL}/getPredictionProcessList`, err);
    // alert('유저 별 모듈 실행 이력 전체 조회 실패');
    return resResult && resResult({ status: false, message: err?.message });
  };

  TAxios({ type: 'get', path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

/**
 * @namespace Get2dGraphDataByProcessIdAPI
 * @param {Function} dispatch - Dispatch 함수
 * @param {object} parms - Get parameters
 */

const get2dGraphDataByProcessIdAPI = (dispatch, params, resResult) => {
  const URL = `${BASE_URL}/get2dGraphDataByProcessId`;

  const onSuccess = (res) => {
    if (res.data.status === 'ERROR') {
      // alert('ProcessId 에 매핑된 2d 그래프 데이터 조회 실패');
      return resResult && resResult({ status: false, message: res.data?.errorCode });
    }
    dispatch(setGraphDataByProcessId(res.data.body));
    return resResult && resResult({ status: true });
  };

  const onFail = (err) => {
    console.error(`${BASE_URL}/get2dGraphDataByProcessId`, err);
  };

  TAxios({ type: 'get', path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

const putUpdateEMICommentByProcessIdAPI = (params, resResult) => {
  const URL = `${BASE_URL}/updateEMICommentByProcessId`;

  const onSuccess = (res) => {
    if (res.data.status === 'ERROR') {
      // alert('업데이트에 실패하였습니다.');
      return resResult && resResult({ status: false, message: res.data?.errorCode });
    }
    return resResult && resResult({ status: true });
  };
  const onFail = (err) => {
    console.error(`${BASE_URL}/updateEMICommentByProcessId`, err);
  };
  TAxios({ type: 'put', path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

/**
 * @namespace GetEmiCommandLineParameterAPI
 * @param {Function} dispatch - Dispatch 함수
 * @param {object} parms - Get parameters
 */

const getEmiCommandLineParameterAPI = (dispatch, params, resResult) => {
  const URL = `${BASE_URL}/getEMICommandLineParameter`;

  const onSuccess = (res) => {
    if (res.data.status === 'ERROR') {
      // alert('EMI 모듈 실행용 파라미터 조회 실패');
      return resResult && resResult({ status: false, message: res.data?.errorCode });
    }
    dispatch(setCommandLineParameter(res.data.body));
    return resResult && resResult({ status: true });
  };

  const onFail = (err) => {
    console.error(`${BASE_URL}/getEMICommandLineParameter`, err);
    // alert('EMI 모듈 실행용 파라미터 조회 실패');
    return resResult && resResult({ status: false, message: 'ERRORS.COMMON.001' });
  };

  TAxios({ type: 'get', path: URL, params, onSuccess, onFail, header: HEADER.GET });
};

/**
 * @namespace UpdateEmiCommandLineParameterAPI
 * @param {Function} dispatch - Dispatch 함수
 * @param {object} parms - Get parameters
 */

const updateEmiCommandLineParameterAPI = (dispatch, params, closeModal, resResult) => {
  const URL = `${BASE_URL}/updateEMICommandLineParameter`;

  const onSuccess = (res) => {
    if (res.data.status === 'ERROR') {
      // alert('EMI 모듈 실행용 파라미터 업데이트 실패');
      return resResult && resResult({ status: false, message: res.data?.errorCode });
    }
    dispatch(setCommandLineParameter(res.data.body));
    closeModal();
    getEmiCommandLineParameterAPI(dispatch);
    return resResult && resResult({ status: true });
  };

  const onFail = (err) => {
    console.error(`${BASE_URL}/updateEMICommandLineParameter`, err);
    // alert('EMI 모듈 실행용 파라미터 업데이트 실패');
  };

  TAxios({ type: 'put', path: URL, params, onSuccess, onFail, header: HEADER.PUT });
};

export {
  getPredictionProcessListAPI,
  get2dGraphDataByProcessIdAPI,
  putUpdateEMICommentByProcessIdAPI,
  getEmiCommandLineParameterAPI,
  updateEmiCommandLineParameterAPI,
};
