import styled from 'styled-components';
import Loading from '@components/Feedback/Loading';
import ScrollableContainer from '@components/Layout/ScrollableContainer';
import PropTypes from 'prop-types';
import * as Utils from '@utils';

const Container = styled(ScrollableContainer)`
  min-height: 100vh;
  height: 100vh;
  margin: 0 auto;
  background: #1d1d1b;
  &::-webkit-scrollbar {
    width: 10px;
    background-color: #343434;
    border-radius: 100px;
    display: none;
  }

  ${({ platform }) => platform === 'ios' && 'overflow: hidden;'}
  ${({ platform }) => platform === 'android' && 'height: 0;'}

  & > img {
    display: block;
    width: 100%;
  }

  & legend {
    display: none;
  }

  & fieldset {
    border: 0;
  }
`;

const MainContainer = ({ children }) => (
  <>
    <Loading
    // show={!initContext.initialized || isLoading || infoContext.isLanding}
    // requiredText={loadingMessage || null}
    // comment={(enableMessage && loadingMessage) || null}
    />
    <Container id='main_container' platform={Utils.appOS}>
      {children}
    </Container>
  </>
);

MainContainer.propTypes = {
  /** Loading 화면 표시 */
  children: PropTypes.node.isRequired,
};

export default MainContainer;
