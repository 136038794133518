import { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';

import styled from 'styled-components';

import { setRfFileMetaData } from '@reducer/rfSlice';
import { Div } from '@components/index';
import RfSolutionMain from './module/RfSolutionMain';

const MainContainer = styled(Div)`
  flex: 1;
  background-color: #272727;
`;

const HiddenInput = styled.input`
  display: none;
`;

const MainContentArea = styled(Div)`
  background-color: #2b2b29;
  position: relative;
  display: flex;
`;
const DataGridArea = styled(Div)`
  position: relative;
  flex: 1;
  background-color: #272727;
`;

const RfSolution = () => {
  const dispatch = useDispatch();
  const rfOriginalData = useSelector((state) => state.rf.rfOriginalData);
  const rfPredictionData = useSelector((state) => state.rf.rfPredictionData);
  const methods = useForm();

  const [fileKey, setFileKey] = useState(0); // 동일한 file을 업로드 할 경우 input의 onChange가 호출되지 않아 추가함

  const handleFileChange = useCallback(
    (event) => {
      const file = event.target.files;
      const reader = new FileReader();
      reader.onload = () => {
        dispatch(setRfFileMetaData(file));
      };
      reader.readAsText(file[0]);
    },
    [fileKey]
  );

  const rfData = useMemo(() => {
    let RC = [];
    const RealRE = [];
    const PredRE = [];
    let tableData = [];

    if (Array.isArray(rfOriginalData) && rfOriginalData.length > 0) {
      RC = rfOriginalData.map((rc) => {
        return [rc.frequency, rc.real_RC];
      });
      rfOriginalData.forEach((item) => {
        tableData.push({
          frq: item.frequency,
          real_RC: item.real_RC,
          real_RE: item.real_RE,
          pred_RE: item.pred_RE,
        });
      });
    }
    if (rfPredictionData.length > 0) {
      tableData = [];

      rfPredictionData.forEach((data) => {
        const frq = data.frequency;
        RealRE.push([frq, data.real_RE]);
        PredRE.push([frq, data.pred_RE]);
        tableData.push({ frq, real_RC: data.real_RC, real_RE: data.real_RE, pred_RE: data.pred_RE });
      });
    }

    return { RC, RealRE, PredRE, tableData };
  }, [rfPredictionData, rfOriginalData]);

  return (
    <FormProvider {...methods}>
      <MainContainer>
        <MainContentArea>
          <DataGridArea>
            <HiddenInput
              key={fileKey}
              id='rf-import'
              type='file'
              accept='.csv'
              onChange={(e) => {
                handleFileChange(e);
                setFileKey((prevKey) => prevKey + 1);
              }}
            />
            <RfSolutionMain rfData={rfData} />
          </DataGridArea>
        </MainContentArea>
      </MainContainer>
    </FormProvider>
  );
};

RfSolution.propTypes = {};

export default RfSolution;
