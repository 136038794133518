import PropTypes from 'prop-types';
import { useContext } from 'react';

import styled, { css } from 'styled-components';
import { Trans } from 'react-i18next';

import { Div, Modal, Button, ModalContext, Img } from '@components/index';
import { ErrorModalIcon } from '@images/index';

const Container = styled(Div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ErrorImg = styled(Img)`
  width: 30px;
  height: 30px;
`;

const ErrorMsg = styled(Div)`
  color: #fff;
  font-family: 'Pretendard';
  font-size: 16px;
  text-align: center;
  line-height: 1.3;
`;

const ErrorButton = styled(Button)`
  background-color: #20b9fd;
  text-decoration: none;
  border-radius: 100px;
  padding: 16px 0;
  color: #fff;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  &:hover {
    text-decoration: none;
    color: white;
    font-weight: 700;
  }
`;

const ModalContainerStyle = css`
  min-width: 464px;
  min-height: 184px;
  background-color: #181818;
  border: 1px solid #20b9fd;
  border-radius: 0px;
  padding: 0px;
`;

const ErrorModal = ({ errorMessage }) => {
  const { closeModal } = useContext(ModalContext);

  const handleConfirm = () => {
    closeModal();
  };

  return (
    <Modal
      classes={{ Container: ModalContainerStyle }}
      body={
        <Container>
          <ErrorImg src={ErrorModalIcon} />
          {errorMessage ? (
            <ErrorMsg>{errorMessage}</ErrorMsg>
          ) : (
            <ErrorMsg>
              <Trans i18nKey='ERRORS.COMMON.UNKNOWN' />
            </ErrorMsg>
          )}
        </Container>
      }
      btns={
        <ErrorButton onClick={handleConfirm} width={60} height={24} fontSize={15}>
          확인
        </ErrorButton>
      }
      hasCloseBtn={false}
    />
  );
};

ErrorModal.propTypes = {
  errorMessage: PropTypes.string,
};

export default ErrorModal;
