import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const NF = css`
  background: #366eff;
`;
const LIMITLINE = css`
  background: #fff;
`;
const Fv = css`
  background: #ff0000;
`;
const Fh = css`
  background: #05ff00;
`;
const Gv = css`
  background: #fff;
`;
const Gh = css`
  background: #faff00;
`;
const gtCount = css`
  background: #ff5733;
`;
const predictionCount = css`
  background: #33ff57;
`;

/* eslint-disable camelcase */
const Real_RE = css`
  background: #fff;
`;
const Pred_RE = css`
  background: #ff0000;
`;
const LegendColor = {
  NF,
  LIMITLINE,
  Fv,
  Fh,
  Gv,
  Gh,
  gtCount,
  predictionCount,
  Real_RE,
  Pred_RE,
};
/* eslint-enable camelcase */

const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
`;

const ToggleSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 6px;
  left: 4px;
  right: 0;
  bottom: 0;
  background: #6b6b6b;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  border-radius: 34px;
  width: 40px;
  height: 10px;
  ${({ target, value }) => {
    return value && target && LegendColor[target] && LegendColor[target][0];
  }}

  &:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 0;
    bottom: -5px;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    border-radius: 50%;
    border: 1px solid #313131;

    ${({ target }) => {
      return target && LegendColor[target] && LegendColor[target][0];
    }}
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${ToggleSlider} {
  }

  &:checked + ${ToggleSlider}:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
`;

const ToggleSam = ({ target, value, callback }) => {
  const handleCheckboxChange = () => {};

  return (
    <ToggleSwitch>
      <CheckBox
        type='checkbox'
        checked={value}
        onChange={handleCheckboxChange}
        onClick={() => callback(target, !value)}
      />
      <ToggleSlider target={target} value={value} />
    </ToggleSwitch>
  );
};

ToggleSam.propTypes = {
  target: PropTypes.string,
  value: PropTypes.bool,
  /** Table Input 옆 필터 SelectBox 존재유무 */
  callback: PropTypes.func,
};

export default ToggleSam;
