import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import i18n from '@i18n';
import styled, { css } from 'styled-components';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Div, Input, Modal, Button, SelectBox, Img, Text } from '@components';
import { FindPwBg } from '@images/index';

const LoginContainer = styled(Div)`
  display: flex;
  row-gap: 20px;
  width: 800px;
  height: 400px;
`;

const ImgContainer = styled(Div)`
  position: relative;

  color: #fff;
  background: linear-gradient(to right, #4a67fb, #24d8ed);
`;

const LoginText = styled(Text)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  font-weight: 600;
`;

const LoginImg = styled(Img)`
  width: 300px;
  height: 400px;
  opacity: 0.15;
`;
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #262626;
  row-gap: 10px;
`;

const LoginButton = styled(Button)`
  background-color: #20b9fd;
  height: 40px;
  text-decoration: none;
  border-radius: 5px;
  padding: 16px 0;
  color: #fff;

  &:hover {
    text-decoration: none;
    color: #fff;
  }
`;
const BGColor = css`
  background-color: #262626;
  flex: 1;
  padding: 0;
`;

const InputFormStyle = css`
  background-color: #363636;
  color: #c1c1c1;
  height: 40px;
  border-radius: 5px;
  border: 0px;
`;

const SelectBoxStyle = css`
  background-color: #363636;
  color: #c1c1c1;
  border-radius: 5px;
  border: 0px;
`;

// FIXME: 비밀번호 찾기 화면 정의서 없음
const FORM_KEYS = Object.freeze({
  ID: 'id',
  NAME: 'name',
  DEPARTMENT: 'department',
  PASSWORD: 'password',
  PASSWORD_NEW: 'passwordNew',
});

const VALIDATION_REGEXES = {
  EMAIL: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,
};

const schema = yup.object().shape({
  [FORM_KEYS.ID]: yup
    .string()
    .matches(VALIDATION_REGEXES.EMAIL, i18n.t('VALIDATION.ID'))
    .required(i18n.t('VALIDATION.REQUIRED')),
  [FORM_KEYS.PASSWORD]: yup
    .string()
    .matches(VALIDATION_REGEXES.PASSWORD, i18n.t('VALIDATION.PASSWORD'))
    .required(i18n.t('VALIDATION.REQUIRED')),
  [FORM_KEYS.PASSWORD_NEW]: yup
    .string()
    .matches(VALIDATION_REGEXES.PASSWORD, i18n.t('VALIDATION.PASSWORD'))
    .required(i18n.t('VALIDATION.REQUIRED')),
  [FORM_KEYS.NAME]: yup.string().min(2, i18n.t('VALIDATION.NAME.MIN')).max(17, i18n.t('VALIDATION.NAME.MAX')),
  [FORM_KEYS.DEPARTMENT]: yup.string().required(i18n.t('VALIDATION.REQUIRED')),
});

const departmentOptions = {
  guide: i18n.t('PLACEHOLDER.DEPARTMENT'),
  list: [
    { id: 'ADMIN', title: i18n.t('COMMON.DEPARTMENT.001') },
    { id: 'TV', title: i18n.t('COMMON.DEPARTMENT.002') },
    { id: 'MOBILE', title: i18n.t('COMMON.DEPARTMENT.003') },
  ],
};

const FindPasswordForm = () => {
  const methods = useForm({ resolver: yupResolver(schema) });
  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const { t } = useTranslation();

  // eslint-disable-next-line
  const handleFindPw = (data) => {};

  return (
    <FormProvider {...methods}>
      <FormContainer>
        <Input
          name={FORM_KEYS.ID}
          classes={{ Variant: InputFormStyle }}
          type='text'
          errorMessage={errors[FORM_KEYS.ID]?.message}
          placeholder={t('PLACEHOLDER.ID')}
          required
        />
        <Input
          name={FORM_KEYS.NAME}
          classes={{ Variant: InputFormStyle }}
          type='text'
          errorMessage={errors[FORM_KEYS.NAME]?.message}
          placeholder={t('PLACEHOLDER.NAME')}
          required
        />
        <SelectBox
          name={FORM_KEYS.DEPARTMENT}
          classes={{ Variant: { LabelContainer: SelectBoxStyle } }}
          h={40}
          options={departmentOptions}
          errorMessage={errors[FORM_KEYS.DEPARTMENT]?.message}
        />
        <Input
          name={FORM_KEYS.PASSWORD}
          classes={{ Variant: InputFormStyle }}
          type='password'
          errorMessage={errors[FORM_KEYS.PASSWORD]?.message}
          placeholder={t('PLACEHOLDER.NEW_PASSWORD')}
          required
        />
        <Input
          name={FORM_KEYS.PASSWORD_NEW}
          classes={{ Variant: InputFormStyle }}
          type='password'
          errorMessage={errors[FORM_KEYS.PASSWORD_NEW]?.message}
          placeholder={t('PLACEHOLDER.NEW_PASSWORD_CONFIRM')}
          required
        />

        <LoginButton onClick={handleSubmit(handleFindPw)}>비밀번호 재설정</LoginButton>
      </FormContainer>
    </FormProvider>
  );
};

const FindPasswordModal = () => {
  return (
    <LoginContainer>
      <ImgContainer>
        <LoginText inner='FIND PW' />
        <LoginImg src={FindPwBg} />
      </ImgContainer>
      <Modal classes={{ Container: BGColor, Header: BGColor }} body={<FindPasswordForm />} />
    </LoginContainer>
  );
};

export default FindPasswordModal;
