import { useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
// import { CloseBlue, ModalCloseBtnIcon } from '@images/index';
import { CloseBlue } from '@images/index';
import { Div, Img, Span, Text } from '@components/Atoms/Atoms';
import { ModalContext } from '@components/Context/ModalProvider';
import Divider from '@components/Surface/Divider';

const LoginModalContainer = styled(Div)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 370px;
  min-height: 240px;

  ${({ classes }) => classes?.Container && classes.Container}
`;

const LoginModalHeader = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  color: #343c47;
  ${({ classes }) => classes?.Header && classes.Header}
`;

const LoginModalCloseButton = styled(Span)`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 0;
  cursor: pointer;
  width: 15px;
  height: 15px;
  ${({ classes }) => classes?.CloseBtn && classes.CloseBtn}
`;

const LoginModalBody = styled(Div)`
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  padding: 20px;
  ${({ classes }) => classes?.Body && classes.Body}
`;

const DividerDefault = css`
  margin-top: 16px;
  margin-bottom: 36px;
`;

// const Label = styled(Div)`
//   display: block;
//   margin-bottom: 8px;
// `;

// const SubmitButtonContainer = styled(Div)`
//   display: flex;
//   flex-direction: column;
// `;

// const SubmitButtonSet = styled(Div)`
//   display: flex;
//   margin: 15px 0 0 0;
// `;
// const SubmitButton = styled(Div)`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: #006eb9;
//   width: 100%;
//   border-top-left-radius: 3px;
//   border-top-right-radius: 10px;
//   border-bottom-left-radius: 10px;
//   border-bottom-right-radius: 3px;
//   height: 60px;
//   font-size: 18px;
//   font-weight: bold;
//   border: none;
//   color: #fff;
//   margin: 0 2px;
//   cursor: pointer;
// `;
const Modal = ({ title, hasCloseBtn = true, body, btns, classes }) => {
  const { closeModal } = useContext(ModalContext);
  const containerRef = useRef(null);

  // useEffect(() => {
  //   containerRef.current.scrollTop = containerRef.current.scrollHeight;
  // }, [body]);

  return (
    <LoginModalContainer classes={classes}>
      {hasCloseBtn && (
        <LoginModalCloseButton onClick={closeModal}>
          {/* <Img src={ModalCloseBtnIcon} alt='닫기' /> */}
          <Img src={CloseBlue} alt='닫기' />
        </LoginModalCloseButton>
      )}
      {title && (
        <LoginModalHeader classes={classes}>
          <Text classes={{ Text: classes?.Text }} fontSize={20} fontWeight={700} color='#202020' innerHtml={title} />
          <Divider classes={{ Divider: classes?.Text ?? DividerDefault }} />
        </LoginModalHeader>
      )}
      <LoginModalBody ref={containerRef} classes={classes}>
        {body}
      </LoginModalBody>
      {btns && btns}
    </LoginModalContainer>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  hasCloseBtn: PropTypes.bool,
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object, PropTypes.node]),
  btns: PropTypes.node,
};

export default Modal;
