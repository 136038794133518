import { useCallback, useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import styled, { css } from 'styled-components';

import { Div, Text, Icon, Button, ModalContext } from '@components/index';
import { AIScan, NFImport } from '@SAM/images';
import BikeProgressModal from './BikeProgressModal';
import BikeImportModal from './BikeImportModal';

const Container = styled(Div)`
  display: flex;
  height: calc(100vh - 100px);
  flex-direction: column;
  padding: 30px 30px 0 30px;
  background-color: #1d1d1b;
  width: 330px;
  border: 1px solid #444444;
  border-top: none;
  border-bottom: none;
`;

const FullButtonStyle = css`
  text-decoration: none;
  color: #fff;
  background-color: #366eff;
  height: 45px;
  border-radius: 100px;
  width: 100%;
  margin: 0;
  background-color: ${({ runStatus }) => typeof runStatus !== 'undefined' && !runStatus && '#A1A1A1'};
  :hover {
    text-decoration: none;
    color: #fff;
  }
`;
const AIRunButtonStatus = styled(Text)``;

const MenuBlock = styled(Div)`
  ${({ classes }) => classes?.MenuBlockStyle && classes.MenuBlockStyle}
`;

const BorderBottomStyle = css`
  border-bottom: 1px solid #494949;
  padding-bottom: 20px;
`;

const NFImportStyle = css`
  border-bottom: 1px solid #494949;
  border-top: 1px solid #494949;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 15px;
  padding-top: 20px;
  padding-bottom: 10px;
  cursor: pointer;
`;

const FlexRow = styled(Div)`
  display: flex;
  position: relative;
  gap: 10px;
  margin-bottom: 10px;
  ${({ classes }) => classes && classes.Variant && classes.Variant}
`;
const FlexRowBottomStyle = css`
  margin-bottom: 30px;
`;

const FlexBox = styled(Div)`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ImportControllerDataMaxWidth = styled(Div)`
  background-color: #2a2a2a;
  border: solid 1px #444444;
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  flex: 1;
  height: 40px;
  width: 158px;
  margin-bottom: 30px;
  ${({ classes }) => classes && classes.Variant}
`;

const AiScanLabel = styled(Div)`
  display: flex;
  justify-content: space-between;
  padding-top: 0px;
  padding-bottom: 10px;
`;

const ScanRunBox = styled(Div)`
  display: flex;
  align-items: center;
  gap: 10px;
`;

// 서버에 보낼 input이 아님
const AiScanInput = styled.input`
  background-color: #2a2a2a;
  height: 30px;
  width: 145px;
  border-radius: 5px;

  border: none;
  text-align: end;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  &:hover {
    border: none;
  }
`;

const BikeSideController = () => {
  const { showModal } = useContext(ModalContext);
  const currentMasterId = useSelector((state) => state.bike.currentMasterId);
  const fileName = useSelector((state) => state.bike.fileMetaData.name);
  const isScanReady = typeof currentMasterId !== 'undefined' && currentMasterId !== null;
  const [isShowBikeProgressModal, setIsShowBikeProgressModal] = useState(false);

  const handleBikemportModal = useCallback(() => {
    showModal(<BikeImportModal />);
  }, []);

  const handleAiScan = useCallback(() => {
    if (currentMasterId !== null) {
      setIsShowBikeProgressModal(true);
    }
  }, [currentMasterId, fileName]);

  return (
    <Container>
      <MenuBlock classes={{ MenuBlockStyle: BorderBottomStyle }}>
        <Text inner='Prediction Display' fontSize={28} fontWeight={600} color='#F5F5F5' />
      </MenuBlock>
      <MenuBlock classes={{ MenuBlockStyle: NFImportStyle }}>
        <Label>
          <Icon icon={NFImport} iconWidth={25} iconHeight={25} />
          <Text inner='BIKE Import' fontSize={22} fontWeight={600} color='#F5F5F5' />
        </Label>
        <FlexRow classes={{ Variant: FlexRowBottomStyle }}>
          <Button classes={{ Button: FullButtonStyle }} onClick={handleBikemportModal}>
            <Text fontSize={18} fontWeight={600} inner='IMPORT File' />
          </Button>
        </FlexRow>
        <FlexRow>
          <ImportControllerDataMaxWidth>
            <Text fontSize={18} fontWeight={600} color='rgba(255, 255, 255, 0.5)' inner='FileName' />
            <FlexBox>
              <AiScanInput type='text' required disabled value={fileName ?? ''} />
            </FlexBox>
          </ImportControllerDataMaxWidth>
        </FlexRow>
        <AiScanLabel>
          <ScanRunBox>
            <Icon icon={AIScan} iconWidth={27} iconHeight={26} />
            <Text inner='Ai SCAN' fontSize={22} fontWeight={600} color='#F5F5F5' />
          </ScanRunBox>
        </AiScanLabel>
        <FlexRow classes={{ Variant: FlexRowBottomStyle }}>
          <Button classes={{ Button: FullButtonStyle }} runStatus={isScanReady} onClick={handleAiScan}>
            <AIRunButtonStatus fontSize={18} fontWeight={600} inner='RUN' />
          </Button>
        </FlexRow>
      </MenuBlock>
      {currentMasterId && isShowBikeProgressModal ? (
        <BikeProgressModal setShowProgressModal={setIsShowBikeProgressModal} />
      ) : null}
    </Container>
  );
};

export default BikeSideController;
