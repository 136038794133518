import { useRef, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as echarts from 'echarts';
// eslint-disable-next-line import/no-extraneous-dependencies

import { setFrqValue } from '@reducer/rfSlice';
import { Div } from '@components/index';
import { useSelector, useDispatch } from 'react-redux';

const LineChartContainer = styled(Div)`
  background-color: #2a2a2a;
  height: 390px;
  width: 850px;
  border-right: 1px solid #444444;
`;

const RfLineChart = ({
  id,
  xAxisType,
  RC,
  predRE,
  realRE,
  legendStatus,
  remoteTooltipControl = false,
  rowSelection,
}) => {
  const dispatch = useDispatch();
  const lineChartRef = useRef(null);
  const controllableRef = useRef(null);
  const currentProcess = useSelector((state) => state.emi.currentProcess);

  // eslint-disable-next-line
  const [linChartOption, setLinChartOption] = useState(() => {
    const original = [];
    const ai = [];
    const gt = [];

    const option = {
      animation: false, // 애니메이션 비활성화
      legend: {
        show: false,

        selected: {},
        data: [],
        textStyle: {
          color: 'white',
        },
      },
      grid: {
        show: true,
        top: '10px',
        bottom: '30px',
        left: '40px',
        right: '20px',
        backgroundColor: '#000000',
        borderColor: '#000000',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        backgroundColor: 'rgba(50,50,50,0.7)',
        borderWidth: 0,
        textStyle: {
          color: 'white',
        },
      },
      xAxis: {
        type: 'value',
        min: 600,
        max: 4000,

        silent: true,
        axisLabel: {
          color: '#F9F9F9',
          fontSize: 16,
          fontFamily: 'Pretendard',
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#000000', // x축 구분선 너비
            width: 1, // x축 구분선 너비
            type: 'solid', // x축 구분선 타입: 'solid', 'dashed', 'dotted'
            interval: 100,
          },
        },
        splitLine: {
          show: true, // x축 격자선 표시 안함
          lineStyle: {
            color: '#444444', // x축 구분선 너비
            width: 1, // x축 구분선 너비
            type: 'solid', // x축 구분선 타입: 'solid', 'dashed', 'dotted'
          },
          interval: 100,
        },
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          color: '#F9F9F9',
          fontSize: 16,
          fontFamily: 'Pretendard',
        },
        axisLine: {
          show: true, // x축 라인 표시 안함
          lineStyle: {
            color: '#000000', // x축 구분선 너비
            width: 1, // x축 구분선 너비
            type: 'solid', // x축 구분선 타입: 'solid', 'dashed', 'dotted'
          },
        },
        splitLine: {
          show: true, // x축 격자선 표시 안함
          lineStyle: {
            color: '#444444', // x축 구분선 너비
            width: 1, // x축 구분선 너비
            type: 'solid', // x축 구분선 타입: 'solid', 'dashed', 'dotted'
          },
        },
      },

      dataZoom: [
        {
          id: 'dataZoomX',
          type: 'inside',
          xAxisIndex: 0,
          start: 0,
          end: 100,
          rangeMode: ['value'],
        },
        {
          id: 'dataZoomY',
          type: 'inside',
          yAxisIndex: [0],
          filterMode: 'filter',
        },
      ],
      series: [
        {
          name: 'Real_RC',
          type: 'line',
          showSymbol: false,
          data: original,
          colorBy: 'data',
          triggerLineEvent: true,
          triggerAreaEvent: true,
          itemStyle: {
            color: '#366EFF', // 원하는 색상으로 변경
          },
          lineStyle: {
            width: 1, // 선의 굵기 설정 (기본값은 1)
            color: '#366EFF', // x: 0,y: 0, x2: 0, y2: 1,가 전역좌표으로 취급되지 않음
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1, // Y값이 높을수록 밝은 색상이 그려지도록 수정
              colorStops: [
                { offset: 0, color: 'rgb(255, 255, 255,0)' },
                { offset: 1, color: 'rgb(255, 255, 255,0)' },
              ],
              global: false,
            },
          },
        },

        {
          name: 'Real_RE',
          type: 'line',
          showSymbol: false,
          data: gt,
          colorBy: 'data',
          triggerLineEvent: true,
          triggerAreaEvent: true,
          itemStyle: {
            color: '#fff', // 원하는 색상으로 변경
          },
          lineStyle: {
            width: 2, // 선의 굵기 설정 (기본값은 1)
            color: '#fff',
          },

          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1, // Y값이 높을수록 밝은 색상이 그려지도록 수정
              colorStops: [
                { offset: 0, color: 'rgb(255, 255, 255,0)' },
                { offset: 1, color: 'rgb(255, 255, 255,0)' },
              ],
              global: false,
            },
          },
        },
        {
          name: 'Pred_RE',
          type: 'line',
          showSymbol: false,
          data: ai,
          colorBy: 'data',
          triggerLineEvent: true,
          triggerAreaEvent: true,
          itemStyle: {
            color: '#FF0101', // 원하는 색상으로 변경
          },
          lineStyle: {
            width: 1, // 선의 굵기 설정 (기본값은 1)
            color: '#FF0101',
          },

          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1, // Y값이 높을수록 밝은 색상이 그려지도록 수정
              colorStops: [
                { offset: 0, color: 'rgb(255, 255, 255,0)' },
                { offset: 1, color: 'rgb(255, 255, 255,0)' },
              ],
              global: false,
            },
          },
        },
      ],
    };
    return option;
  });
  const chartInstance = useRef(null);
  useEffect(() => {
    if (lineChartRef.current) {
      chartInstance.current = echarts;
      lineChartRef.current = chartInstance.current.init(lineChartRef.current);
      const updatedOption = { ...linChartOption };
      if (Array.isArray(RC) && RC.length > 0) {
        updatedOption.series[0].data = RC;
        const nfIndex = updatedOption.legend.data.indexOf('RC');
        updatedOption.yAxis.min = -25;
        updatedOption.yAxis.max = 0;
        if (nfIndex === -1) {
          updatedOption.legend.data.push('RC');
        }
      }
      if (Array.isArray(predRE)) {
        if (predRE.length > 0) {
          updatedOption.series[1].data = predRE;
          updatedOption.legend.data.push('Pred_RE');
          updatedOption.yAxis.min = -50;
          updatedOption.yAxis.max = 0;
        } else {
          updatedOption.series[1].data = [];
          const gtDataIndex = updatedOption.legend.data.indexOf('Pred_RE');
          if (gtDataIndex > -1) {
            updatedOption.legend.data.splice(gtDataIndex, 1);
          }
        }
      }
      if (Array.isArray(realRE)) {
        if (realRE.length > 0) {
          updatedOption.series[2].data = realRE;
          updatedOption.legend.data.push('Real_RE');
        } else {
          updatedOption.series[1].data = [];
          const gtDataIndex = updatedOption.legend.data.indexOf('Real_RE');
          if (gtDataIndex > -1) {
            updatedOption.legend.data.splice(gtDataIndex, 1);
          }
        }
      }

      if (xAxisType) {
        updatedOption.xAxis = xAxisType;
      }
      if (legendStatus) {
        updatedOption.legend.selected = legendStatus;
      }
      lineChartRef.current.setOption(updatedOption);
      lineChartRef.current.on('click', (params) => {
        const { offsetX, offsetY } = params.event;
        let dataPoint;
        if (typeof lineChartRef.current.convertFromPixel === 'function') {
          dataPoint = lineChartRef.current.convertFromPixel({ seriesIndex: params.seriesIndex }, [offsetX, offsetY]);
        } else {
          const instance = chartInstance.current.getInstanceByDom(lineChartRef.current);
          dataPoint = instance.convertFromPixel({ seriesIndex: params.seriesIndex }, [offsetX, offsetY]);
        }
        const xAxisIndex = Math.round(dataPoint[0]);
        const xAxisData = updatedOption.series[params.seriesIndex].data.map((item) => {
          return item[0];
        });

        const findClosestIndex = (arr, target) =>
          arr.reduce(
            (closestIndex, num, index, array) =>
              Math.abs(num - target) < Math.abs(array[closestIndex] - target) ? index : closestIndex,
            0
          );
        const clickedIndex = findClosestIndex(xAxisData, xAxisIndex);
        if (typeof rowSelection === 'function') {
          const origin = rowSelection(clickedIndex.toString());
          dispatch(
            setFrqValue({
              frq: xAxisData[clickedIndex] ?? 0,
              real_RC: origin.real_RC ?? 0,
              real_RE: origin.real_RE ?? 0,
              pred_RE: origin.pred_RE ?? 0,
              id: clickedIndex,
            })
          );
        }
      });
    }

    controllableRef.current = lineChartRef.current;
    return () => {
      if (chartInstance.current && lineChartRef.current) {
        chartInstance.current.dispose(lineChartRef.current);
      }
    };
  }, [id, xAxisType, RC, predRE, realRE, rowSelection, linChartOption, currentProcess, legendStatus]);

  useEffect(() => {
    if (controllableRef.current && remoteTooltipControl) {
      controllableRef.current.dispatchAction(remoteTooltipControl);
    }
  }, [remoteTooltipControl]);

  return <LineChartContainer id={id} ref={lineChartRef} />;
};

RfLineChart.propTypes = {
  /** Eventlistener를 부착할 id */
  id: PropTypes.string.isRequired,
  /** X축 scale log || linear */
  xAxisType: PropTypes.object,
  /** 원본 그래프 데이터 */
  RC: PropTypes.array,
  /** 예측 AI 그래프 데이터 */
  predRE: PropTypes.array,
  /** GT 그래프 데이터 */
  realRE: PropTypes.array,
  /** 그래프 Tooltip을 외부 상태로 제어함 */
  remoteTooltipControl: PropTypes.shape({
    type: PropTypes.string,
    x: PropTypes.number,
  }),
  /** 그래프 legend을 외부 상태로 제어함 */
  legendStatus: PropTypes.shape({
    NF: PropTypes.bool,
    LIMITLINE: PropTypes.bool,
    FF: PropTypes.bool,
    GT: PropTypes.bool,
  }),
  rowSelection: PropTypes.func,
};

export default RfLineChart;
