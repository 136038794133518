import Layout from '@SAM/components/Layout/Layout';
import Home from '@SAM/pages/Home';
import EmiSolution from '@SAM/pages/EmiSolution';
import RfSolution from '@SAM/pages/RfSolution';
import EmiDatamanagement from '@SAM/pages/EmiDatamanagement';

// 404
import NotFound from '@SAM/pages/NotFound';
import LandingLayout from '@SAM/components/Layout/LandingLayout';
import EmiDebugging from '@SAM/pages/EmiDebugging';
import BikeSolution from '@SAM/pages/BikeSolution';

const Router = [
  {
    path: '',
    element: <LandingLayout />,
    children: [
      {
        path: '',
        element: <Home />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
  {
    path: 'solution',
    element: <Layout />,
    children: [
      {
        path: 'emi',
        element: <EmiSolution />,
      },
      {
        path: 'rf',
        element: <RfSolution />,
      },
      {
        path: 'bike',
        element: <BikeSolution />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
  {
    path: 'debugging',
    element: <Layout />,
    children: [
      {
        path: 'emi3d',
        element: <EmiDebugging />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
  {
    path: 'datamanagement',
    element: <Layout />,
    children: [
      {
        path: '',
        element: <EmiDatamanagement />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];
export default Router;
