import reset from 'styled-reset';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  ${reset}
  #root {
    font-family: 'Pretendard';
  }

  html, body {
    font-family: 'Pretendard';
  }

  * {
    box-sizing: border-box;
  }
`;

export default GlobalStyle;
