import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { Div } from '@components/index';
import { EffectImg } from '@SAM/images';
import { Trans } from 'react-i18next';
import { useEffect, useRef } from 'react';

const LandingContainer = styled(Div)`
  position: relative;
  /* REVIEW: 높이는 임의로 지정 */
  height: 100vh;
`;

const MainIntro = styled(Div)`
  position: absolute;
  top: 289px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-size: 20px;
`;

const IntroSection = styled(Div)`
  margin: 0 auto;
  width: fit-content;
  ${({ classes }) => classes?.MB && classes.MB}
`;

const TopText = styled.h3`
  font-weight: 200;
  font-size: 39px;
  letter-spacing: -0.7px;
  color: #ffffff;
  display: flex;
  align-items: center;
  transform: skewX(-10deg);
`;
const BottomText = styled.h1`
  margin-right: 5px;
  font-weight: 600;
  font-size: 70px;
  letter-spacing: -1.2px;
  color: #fff;
  display: flex;
  align-items: center;
  transform: skewX(-10deg);
`;
const HighlightedText = styled.p`
  margin-right: 5px;
  background: linear-gradient(90deg, #e757aa, #e063ad, #d28bc2, #83a6dc, #abd3f0);
  color: var(--text-style-h, transparent);
  background-clip: text;
  -webkit-background-clip: text;
`;

const MarginBottom = css`
  margin-bottom: 23px;
`;
const VideoArea = styled.video``;
const Landing = ({ title = 'EMI 예측', img = EffectImg, video }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoEl = videoRef.current;

    if (videoEl) {
      // 비디오를 음소거하고 loop 설정
      videoEl.muted = true;
      videoEl.loop = true;
      videoEl.play();
    }
  }, []);

  return (
    <LandingContainer>
      <VideoArea ref={videoRef} poster={img} playsinline>
        <source src={video} type='video/mp4' />
      </VideoArea>
      <MainIntro>
        <IntroSection classes={{ MB: MarginBottom }}>
          <TopText>
            <Trans i18nKey='LANDING.001' components={{ highlighted: <HighlightedText /> }} />
          </TopText>
        </IntroSection>
        <IntroSection>
          <BottomText>
            <Trans
              i18nKey='LANDING.002'
              values={{ title }}
              components={{ highlighted: <HighlightedText />, nonBreakingSpace: <>&nbsp;</> }}
            />
          </BottomText>
        </IntroSection>
      </MainIntro>
    </LandingContainer>
  );
};

Landing.propTypes = {
  /** Swiper에 의해 변경되는 타이틀 */
  title: PropTypes.string,
  /** Swiper video element */
  video: PropTypes.string,
  /** Swiper에 의해 변경되는 타이틀 */
  img: PropTypes.string,
};

export default Landing;
