import { useRef, useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as echarts from 'echarts';

import { Div } from '@components/index';

const HeatMap2DContainer = styled(Div)`
  flex: 1;
  height: 525px;
`;

const EmiHeatMap = ({ emiData, clickedData, handleNVLevel }) => {
  const heatMap2DChartRef = useRef(null);
  const chartInstance = useRef(null);

  const [targetNV, setTargetNV] = useState(null);
  const nfOriginalCoordinate = useSelector((state) => state.emi.nfOriginalCoordinate);
  const [xCount, setXCount] = useState(10);
  const [yCount, setYCount] = useState(5);
  const generateData = useCallback(
    (value) => {
      const ret = [];
      let count = 0;

      let maxX = 10; // x default
      let maxY = 5; // y default
      let isIncreasing = true; // x 값이 증가하는지 여부를 나타내는 변수
      if (value) {
        const yPos = nfOriginalCoordinate.y_pos
          .split(',')
          .map((item) => item.trim()) // 각 항목의 양쪽 공백을 제거
          // eslint-disable-next-line no-restricted-globals
          .filter((item) => item !== '' && !isNaN(Number(item))) // 빈 문자열과 NaN을 제거
          .map(Number); // 숫자로 변환

        for (let i = 0; i < yPos.length; i++) {
          if (yPos[0] !== yPos[i]) {
            maxX = i;
            break;
          }
        }

        maxY = yPos.length / maxX;
        setXCount(maxX - 1);
        setYCount(maxY - 1);
        for (let y = maxY - 1; y >= 0; --y) {
          if (isIncreasing) {
            for (let x = 0; x <= maxX - 1; ++x) {
              ret.push([x, y, value[count]]);
              count++;
            }
          } else {
            for (let x = maxX - 1; x >= 0; --x) {
              ret.push([x, y, value[count]]);
              count++;
            }
          }
          isIncreasing = !isIncreasing;
        }
        // y 값이 감소할 때 x 값의 변화 방향 변경
      } else {
        for (let y = 5; y >= 0; --y) {
          if (isIncreasing) {
            for (let x = 0; x <= maxX; ++x) {
              ret.push([x, y, 0]);
            }
          } else {
            for (let x = maxX; x >= 0; --x) {
              ret.push([x, y, 0]);
            }
          }
        }
        // y 값이 감소할 때 x 값의 변화 방향 변경
        isIncreasing = !isIncreasing;
        // x 값의 변화 방향에 따라 최대값 설정
      }
      const retObject = {};
      ret.forEach((item, index) => {
        const name = `nv${index + 1}`;
        if (!retObject[name]) {
          retObject[name] = {};
          retObject[name].name = name;
          retObject[name].value = item;
        }
      });
      return Object.values(retObject);
    },
    [nfOriginalCoordinate]
  );

  const createArray = useCallback((n) => {
    return Array.from({ length: n }, (_, i) => i + 1);
  }, []);

  // eslint-disable-next-line
  const [heatMap2DOption, setHeatMap2DOption] = useState(() => {
    const option = {
      animation: false,
      tooltip: {
        position: 'top',
      },
      grid: {
        width: '730px',
        top: '0',
        left: '15px',
        right: '10px',
        bottom: '20px',
      },
      xAxis: {
        type: 'category',
        data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], // default category array
        splitArea: {
          show: true,
        },
        axisLabel: {
          show: true,
          color: '#F9F9F9',
          fontSize: 14,
          fontFamily: 'Pretendard',
        },
      },
      yAxis: {
        type: 'category',
        data: [1, 2, 3, 4, 5, 6], // default category array
        splitArea: {
          show: true,
        },
        axisLabel: {
          show: true,
          color: '#F9F9F9',
          fontSize: 14,
          fontFamily: 'Pretendard',
        },
      },
      emphasis: {
        disabled: true,
      },
      visualMap: {
        show: false,
        min: 0,
        max: 40,
        calculable: true,
        realtime: false,
      },
      select: {
        selectedMode: 'single',
      },
      series: [
        {
          type: 'heatmap',
          data: generateData(),
          label: {
            show: true,
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };
    return option;
  });

  const targetFrq = useMemo(() => {
    const maxValues = {};
    let targetArray;
    if (clickedData) {
      if (Array.isArray(clickedData)) {
        setTargetNV(clickedData);
        return;
      }

      const retArr = [];
      const filteredTarget = Object.keys(clickedData).filter(
        (key) => key.includes('nv') && key !== 'nv_max' && clickedData[key]
      );
      filteredTarget.forEach((key) => {
        retArr.push(Number(clickedData[key]));
      });
      const NV = Object.values(retArr);
      setTargetNV(NV);
    } else {
      targetArray = emiData;
      if (targetArray && Array.isArray(targetArray) && targetArray.length > 0) {
        targetArray.forEach((obj) => {
          for (let i = 1; i <= targetArray.length; i++) {
            const key = `nv${i}`;
            const value = parseFloat(obj[key]);
            if (!Number.isNaN(value) && obj[key] !== '') {
              // 현재 키의 최대값이 없거나, 현재 값이 최대값보다 크면 업데이트
              if (!maxValues[key] || value > maxValues[key]) {
                maxValues[key] = value;
              }
            }
          }
        });
        const NV = Object.values(maxValues);
        setTargetNV(NV);
      }
    }
  }, [emiData, clickedData]);

  const handleHeatmapDataClick = useCallback(
    (params) => {
      const originalData = emiData;
      if (Array.isArray(originalData) && originalData.length > 0) {
        const key = params.data.name;
        const ret = originalData.map((item) => {
          return [item.frequency, item[key]];
        });
        handleNVLevel(ret);
      }
    },
    [emiData]
  );

  useEffect(() => {
    if (heatMap2DChartRef.current) {
      chartInstance.current = echarts;
      heatMap2DChartRef.current = chartInstance.current.init(heatMap2DChartRef.current);
      const updatedOption = { ...heatMap2DOption };
      if (targetNV && targetNV.length > 0) {
        updatedOption.series[0].data = generateData(targetNV);
        const target = generateData(targetNV);
        let maxValue = target[0]?.value[2];
        let minValue = target[0]?.value[2];

        for (let i = 1; i < target.length; i++) {
          if (target[i].value[2] > maxValue) {
            maxValue = target[i].value[2];
          }
          if (target[i].value[2] < minValue) {
            minValue = target[i].value[2];
          }
        }
        updatedOption.visualMap.max = maxValue;
        updatedOption.visualMap.min = minValue;
      }
      updatedOption.xAxis.max = xCount;
      updatedOption.yAxis.max = yCount;
      updatedOption.xAxis.data = createArray(xCount + 1);
      updatedOption.yAxis.data = createArray(yCount + 1);

      heatMap2DChartRef.current.setOption(updatedOption);
      heatMap2DChartRef.current.on('click', handleHeatmapDataClick);
    }
    return () => {
      if (chartInstance.current && heatMap2DChartRef.current) {
        chartInstance.current.dispose(heatMap2DChartRef.current);
      }
    };
  }, [heatMap2DOption, targetFrq, targetNV, handleHeatmapDataClick, xCount, yCount, nfOriginalCoordinate]);

  return <HeatMap2DContainer style={{ width: '745px', height: '500px' }} id='heatmap2d' ref={heatMap2DChartRef} />;
};

EmiHeatMap.propTypes = {
  emiData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  clickedData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /* handleNVLevel 콜백함수 NV히트맵을 클릭시 라인그래프에 해당 NV 레벨을 주파수 별로 전달함 데이터 */
  handleNVLevel: PropTypes.func,
};

export default EmiHeatMap;
