import { useRef, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as echarts from 'echarts';

import { Div } from '@components/index';
import { useSelector, useDispatch } from 'react-redux';
import { setDebuggingFrqValue } from '@reducer/emiSlice';

const LineChartContainer = styled(Div)`
  background-color: #232323;
  height: 500px;
  width: 730px;
`;

const EmiDebuggingLineChart = ({
  id,
  title = '',
  xAxisType,
  lineData,
  limitData,
  aiData,
  gtData,
  legendStatus,
  remoteTooltipControl = false,
  rowSelection,
  handleClickedData,
}) => {
  const dispatch = useDispatch();
  const lineChartRef = useRef(null);
  const controllableRef = useRef(null);
  const currentProcess = useSelector((state) => state.emi.currentProcess);
  // eslint-disable-next-line
  const [linChartOption, setLinChartOption] = useState(() => {
    const original = [];

    const option = {
      animation: false, // 애니메이션 비활성화

      legend: {
        show: false,

        selected: {
          NF: true,
        },
        data: [],
        textStyle: {
          color: 'white',
        },
      },
      grid: {
        show: true,
        top: '20px',
        bottom: '30px',
        left: '30px',
        right: '20px',
        borderWidth: 1,
        borderColor: '#000000',
        backgroundColor: '#000000',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      xAxis: {
        logBase: 10,
        min: 30,
        max: 1000,
        axisLabel: {
          show: true,
          color: '#F9F9F9',
          fontSize: 16,
          fontFamily: 'Pretendard',
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#000000',
            width: 1,
            type: 'solid',
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#444444',
            width: 1,
            type: 'solid',
          },
        },
      },
      yAxis: {
        type: 'value',
        min: 0,
        max: 55,
        splitNumber: 10,
        axisLabel: {
          show: true,
          color: '#F9F9F9',
          fontSize: 16,
          fontFamily: 'Pretendard',
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#000000',
            width: 1,
            type: 'solid',
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#444444',
            width: 1,
            type: 'solid',
          },
        },
      },

      dataZoom: [
        {
          id: 'dataZoomX',
          type: 'inside',
          xAxisIndex: 0,
          start: 0,
          end: 100,
        },
        {
          id: 'dataZoomY',
          type: 'inside',
          yAxisIndex: [0],
          filterMode: 'filter',
        },
      ],
      series: [
        {
          name: 'NF',
          type: 'line',
          showSymbol: false,
          data: original,
          colorBy: 'data',
          triggerLineEvent: true,
          triggerAreaEvent: true,
          itemStyle: {
            color: '#366EFF',
          },
          lineStyle: {
            width: 2,
            color: '#366EFF',
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                { offset: 0, color: 'rgba(0, 0, 0,0)' },
                { offset: 1, color: 'rgba(0, 0, 0,0)' },
              ],
              global: false,
            },
          },
          markPoint: {
            data: [
              {
                type: 'max',
              },
            ],
            symbol: 'pin',
          },
        },
      ],
    };
    return option;
  });
  const chartInstance = useRef(null);

  useEffect(() => {
    if (lineChartRef.current) {
      chartInstance.current = echarts;
      lineChartRef.current = chartInstance.current.init(lineChartRef.current);
      const updatedOption = { ...linChartOption };
      if (Array.isArray(lineData) && lineData.length > 0) {
        updatedOption.series[0].data = lineData;
        const nfIndex = updatedOption.legend.data.indexOf('NF');
        if (nfIndex === -1) {
          updatedOption.legend.data.push('NF');
        }
      }

      lineChartRef.current.setOption(updatedOption);
      lineChartRef.current.on('click', (params) => {
        const { offsetX, offsetY } = params.event;
        let dataPoint;
        if (typeof lineChartRef.current.convertFromPixel === 'function') {
          dataPoint = lineChartRef.current.convertFromPixel({ seriesIndex: params.seriesIndex }, [offsetX, offsetY]);
        } else {
          const instance = chartInstance.current.getInstanceByDom(lineChartRef.current);
          dataPoint = instance.convertFromPixel({ seriesIndex: params.seriesIndex }, [offsetX, offsetY]);
        }
        const xAxisIndex = Math.round(dataPoint[0]);
        const xAxisData = updatedOption.series[params.seriesIndex].data.map((item) => {
          return item[0];
        });

        const findClosestIndex = (arr, target) =>
          arr.reduce(
            (closestIndex, num, index, array) =>
              Math.abs(num - target) < Math.abs(array[closestIndex] - target) ? index : closestIndex,
            0
          );
        const clickedIndex = findClosestIndex(xAxisData, xAxisIndex);
        if (typeof rowSelection === 'function') {
          const origin = rowSelection(clickedIndex.toString());
          handleClickedData(origin);
          dispatch(
            setDebuggingFrqValue({
              frq: xAxisData[clickedIndex],
              id: clickedIndex,
            })
          );
        }
      });
    }
    controllableRef.current = lineChartRef.current;

    return () => {
      if (chartInstance.current && lineChartRef.current) {
        chartInstance.current.dispose(lineChartRef.current);
      }
    };
  }, [
    id,
    title,
    xAxisType,
    lineData,
    limitData,
    aiData,
    gtData,
    linChartOption,
    currentProcess,
    legendStatus,
    rowSelection,
  ]);

  useEffect(() => {
    if (controllableRef.current && remoteTooltipControl) {
      controllableRef.current.dispatchAction(remoteTooltipControl);
    }
  }, [remoteTooltipControl]);

  return <LineChartContainer id={id} ref={lineChartRef} />;
};

EmiDebuggingLineChart.propTypes = {
  /** Eventlistener를 부착할 id */
  id: PropTypes.string.isRequired,
  /** 차트 상단에 노출될 그래프 이름 */
  title: PropTypes.string,
  /** X축 눈금 type ex) value, time */
  xAxisType: PropTypes.object,
  /** 그래프 데이터 */
  lineData: PropTypes.array.isRequired,
  /** 서브 그래프 데이터 */
  limitData: PropTypes.array,
  /** Ai 그래프 데이터 */
  aiData: PropTypes.array,
  /** Chember 그래프 데이터 */
  gtData: PropTypes.array,
  /** 그래프 Tooltip을 외부 상태로 제어함 */
  remoteTooltipControl: PropTypes.shape({
    type: PropTypes.string,
    x: PropTypes.number,
  }),
  /** 그래프 Tooltip을 외부 상태로 제어함 */
  legendStatus: PropTypes.shape({
    NF: PropTypes.bool,
    LIMITLINE: PropTypes.bool,
    FF: PropTypes.bool,
    GT: PropTypes.bool,
  }),
  rowSelection: PropTypes.func,
  handleClickedData: PropTypes.func,
};

export default EmiDebuggingLineChart;
