import { useCallback, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Div, Text } from '@components/index';
import BikeLineChart from '@SAM/components/Chart/BikeLineChart';
import ToggleSam from '@SAM/components/ToggleSam';
import BikeSideController from './BikeSideController';

const Container = styled(Div)`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  background-color: #000000;
`;

const GraphArea = styled(Div)`
  display: flex;
  padding-left: 30px;
  padding-top: 30px;
  gap: 20px;
`;

const FirstGraph = styled(Div)`
  display: flex;
  flex: 1;
`;

const FlexRowDrectionCol = styled(Div)`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

const YAxis = styled(Div)`
  width: 25px;
  height: 100%;
  background-color: #1d1d1b;
  border: 1px solid #444444;
  color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 915px;
`;
const YName = styled(Div)`
  transform: rotate(270deg);
  transform-origin: left top 50;
  color: #f9f9f9;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Pretendard';
  white-space: nowrap;
`;
const XAxis = styled(Div)`
  display: flex;
  width: 100%;
  height: 25px;
  background-color: #2a2a2a;
  border-top: 1px solid #444444;
  justify-content: center;
  align-items: center;
  color: #f9f9f9;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Pretendard';
  white-space: nowrap;
  border-bottom: 1px solid #444444;
  border-right: 1px solid #444444;
`;
const LegendContainer = styled(Div)`
  display: flex;
  align-items: center;
`;
const LegendButtonGroup = styled(Div)`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  height: 40px;
  gap: 25px;
  padding-right: 36px;
  background-color: #2a2a2a;
  border-top: 1px solid #444444;
  border-right: 1px solid #444444;
`;
const LegendToggle = styled(ToggleSam)``;

const SecondeGraph = styled(FirstGraph)``;

const BikeSolutionMain = ({ bikeData }) => {
  const [legendStatus, setLegendStatus] = useState({ gtCount: true, predictionCount: true });

  const handleLegendToggle = useCallback(
    (target, value) => {
      setLegendStatus((prev) => ({ ...prev, [target]: value }));
    },
    [legendStatus]
  );

  const chartLegend = useMemo(() => {
    const aiButton = [];
    if (Array.isArray(Object.keys(bikeData.bikeAiDataObj)) && Object.keys(bikeData.bikeAiDataObj).length > 0)
      Object.keys(bikeData.bikeAiDataObj).forEach((key, index) => {
        aiButton.push(
          <LegendContainer key={`${index + 1}-ff`}>
            <Text color='#BEBEBD' inner={key} fontSize={20} fontWeight={500} />
            <LegendToggle value={legendStatus[key]} target={key} callback={handleLegendToggle} />
          </LegendContainer>
        );
      });

    return { aiButton };
  }, [bikeData, handleLegendToggle]);

  return (
    <Container>
      <BikeSideController />
      <GraphArea>
        <FirstGraph>
          <YAxis>
            <YName>Condition embedding [normalized]</YName>
          </YAxis>
          <FlexRowDrectionCol>
            <LegendButtonGroup />
            <BikeLineChart
              id='Bike_line_input'
              bikeDataOriginal={bikeData.bikeOriginalObj}
              chartMaxY={bikeData.chartMaxY}
              width='660px'
              height='850px'
            />
            <XAxis>Time [hour]</XAxis>
          </FlexRowDrectionCol>
        </FirstGraph>
        <SecondeGraph>
          <YAxis>
            <YName>Number of bicycles</YName>
          </YAxis>
          <FlexRowDrectionCol>
            <LegendButtonGroup>{chartLegend.aiButton}</LegendButtonGroup>
            <BikeLineChart
              id='Bike_line_output'
              width='800px'
              height='850px'
              bikeDataAi={bikeData.bikeAiDataObj}
              legendStatus={legendStatus}
            />
            <XAxis>Time [hour]</XAxis>
          </FlexRowDrectionCol>
        </SecondeGraph>
      </GraphArea>
    </Container>
  );
};

// eslint-disable-next-line
BikeSolutionMain.propTypes = {
  bikeData: PropTypes.shape({
    bikeOriginalObj: PropTypes.shape({
      season: PropTypes.array,
      holiday: PropTypes.array,
      workingDay: PropTypes.array,
      weather: PropTypes.array,
      temp: PropTypes.array,
      atemp: PropTypes.array,
      humidity: PropTypes.array,
      windSpeed: PropTypes.array,
      casual: PropTypes.array,
    }),
    bikeAiDataObj: PropTypes.shape({
      predictionCount: PropTypes.array,
      count: PropTypes.array,
    }),
    chartMaxY: PropTypes.number,
  }),
};

export default BikeSolutionMain;
