import { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useController, useFormContext } from 'react-hook-form';
import { Div } from '@components/Atoms/Atoms';
import { BottomSheetIcon } from '@images';

const getHeight = ({ h }) => `${h || 50}px`;

const HasScrollStyle = css`
  height: 380px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgray;
    border-radius: 100px;
    box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
    border-radius: 100px;
  }
`;

const StyledContainer = styled(Div)`
  position: relative;
  font-family: 'Pretendard';
  font-size: 13px;
  min-width: 96px;
  height: ${getHeight};
  border-radius: 2px;
  cursor: pointer;
  color: ${({ theme }) => theme.gray06};
  ${({ classes }) => classes && classes.Variant.StyledContainer}
`;

const LabelContainer = styled(Div)`
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  padding: 16px 22px;
  border-radius: 2px;
  font-size: 13px;
  white-space: nowrap;
  color: ${({ theme }) => theme.gray06};
  cursor: pointer;

  &:after {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    content: '';
    display: block;
    width: 14px;
    height: 7px;
    background: url(${BottomSheetIcon});
    background-size: contain;
    background-repeat: no-repeat;
  }
  height: ${getHeight};
  ${({ classes }) => classes && classes.Variant.LabelContainer}
`;

const OptionList = styled.ul`
  position: absolute;
  top: ${getHeight};
  left: 0;
  z-index: 1;
  display: none;
  width: 100%;
  border-radius: 0 0 2px 2px;
  background: #fff;
  color: #767676;
  /* overflow: hidden; */
  line-height: 140%;
  padding: 0;
  margin: 0;
  list-style: none;
  color: ${({ theme }) => theme.gray06};

  ${({ isShow }) => isShow && `display:block;`}
  ${({ disabled }) => disabled && `display: none !important; height: 0px !important;`}
  ${({ hasScroll }) => hasScroll && HasScrollStyle};
  ${({ classes }) => classes && classes.Variant.LabelContainer}
`;
const OptionItem = styled.li`
  padding: 9px 16px;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;

  &:first-child {
    border-top: 1px solid #ddd;
  }

  &:last-child {
    border-radius: 0 0 2px 2px;
    border-bottom: 1px solid #ddd;
  }

  ${({ classes }) => classes && classes.Variant.OptionItem}
  ${({ hasScroll }) => hasScroll && ` border-right: 0px`}
  ${({ hover }) => hover && `background: #eee;`}
`;

const ErrorMsg = styled.p`
  color: ${({ theme }) => theme.red};
  font-size: 13px;
  font-weight: 400;
`;

const SelectBox = ({ name, required, disabled, hasScroll = false, errorMessage, options, classes, ...rest }) => {
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [isShow, setIsShow] = useState(false);
  const { control } = useFormContext();
  const { field } = useController({
    name,
    defaultValue: !options.guide ? options?.list[0].id : null,
    control,
    rules: {
      required,
      disabled,
    },
  });

  const label = useMemo(() => {
    if (!options?.guide) {
      return currentIndex === -1 ? options?.list[0]?.title : options?.list[currentIndex]?.title;
    }
    return currentIndex === -1 ? options.guide : options?.list[currentIndex]?.title;
  }, [currentIndex, options.guide, options.list]);

  const onSelectBoxClick = useCallback(() => {
    setIsShow((prev) => !prev);
  }, []);

  const onOptionClick = useCallback(
    ({ item }) => {
      field.onChange(item.id);
    },
    [field]
  );

  useEffect(() => {
    if (options.list && field.value) {
      const index = options.list?.findIndex(({ id }) => id === field.value);
      setCurrentIndex(index);
    }
  }, [field.value, options.list]);

  return (
    <>
      <StyledContainer onClick={onSelectBoxClick} {...rest} classes={classes}>
        <LabelContainer {...rest} classes={classes}>
          {label}
        </LabelContainer>

        <OptionList isShow={isShow} hasScroll={hasScroll} {...rest} classes={classes}>
          {!disabled &&
            options?.list.map((item, index) => (
              <OptionItem
                // eslint-disable-next-line react/no-array-index-key
                key={`${item?.id}-${index}`}
                onClick={(event) => onOptionClick({ event, item })}
                classes={classes}
              >
                {item.title}
              </OptionItem>
            ))}
        </OptionList>
      </StyledContainer>
      {errorMessage && <ErrorMsg>{errorMessage}</ErrorMsg>}
    </>
  );
};

SelectBox.propTypes = {
  /** SelectBox를 구분하기 위한 name */
  name: PropTypes.string.isRequired,
  /** SelectBox height */
  h: PropTypes.number,
  /** 값 선택 필수 유무 */
  required: PropTypes.bool,
  /** Disabled 여부 */
  disabled: PropTypes.bool,
  /** Scroll 존재 유무 */
  hasScroll: PropTypes.node,
  /** SelectBox의 초기 label(guide), 목록 (list)값이 있는 Object */
  options: PropTypes.object.isRequired,
  /** 커스텀 스타일 오브젝트 */
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  /** 유효성 검사 에러 메세지 */
  errorMessage: PropTypes.string,
};

export default SelectBox;
