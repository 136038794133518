import { Outlet } from 'react-router-dom';
import { Div } from '@components/index';
import styled from 'styled-components';
import MainAppBar from '../MainAppBar';

const Wrapper = styled(Div)`
  min-height: 100%;
  height: 100%;
  /* overflow: hidden; */
`;
const Container = styled(Div)`
  display: flex;
  min-width: 100%;
  height: 100%;
  justify-content: center;
  padding-top: 100px;
  background-color: #272727;
`;

const Layout = () => {
  return (
    <Wrapper>
      <MainAppBar />
      <Container>
        <Outlet />
      </Container>
    </Wrapper>
  );
};

export default Layout;
