import { createSlice } from '@reduxjs/toolkit';
import { findMaxNv } from '@utils';

const initialState = {
  list: [],
  history: [],
  fileMetaData: {
    lastModified: '',
    name: '',
    size: '',
    type: '',
  },
  currentMasterId: null, // null이면 import image가 나오고, 값이 있으면 AiScan run 활성화됨
  aiData: {
    masterInfo: null,
    emi2dGraphList: null,
    rejectionMessage: '',
    emiHPeakList: [],
    emiVPeakList: [],
    processId: null,
    comment: null,
  },
  currentProcess: null,
  selectedTableRow: { frq: 0, NF: 0, Fv: 0, Fh: 0, Gv: 0, Gh: 0, passFail: '', id: null },
  selectedDebuggingTableRow: { frq: 0, id: null },
  predictProcessList: {},
  nfOriginalData: [],
  nfMaxData: [],
  nfOriginalCoordinate: {
    id: 0,
    masterId: 0,
    x_pos: '',
    y_pos: '',
  },
  commandLineParam: {},
};

// Working with Non-Serializable Data File객체와 같이 Non-Serializable Data는
// 상태로 관리하지 않는 것이 원칙임 데이터의 신뢰도를 확보하기 어렵다는 것이 Redux 권고사항
let fileList = [];

/* eslint-disable no-param-reassign */
const emi = createSlice({
  name: 'emi',
  initialState,
  reducers: {
    setEmiList: (state, action) => {
      state.list = action.payload;
    },
    setEmiHistory: (state, action) => {
      state.history = action.payload;
    },
    setEmiFileMetaData: (state, action) => {
      fileList = action.payload; // Save the FileList outside the state
      [state.fileMetaData] = Array.from(action.payload).map((file) => ({
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified,
      }));
    },
    setCurrentMasterId: (state, action) => {
      state.currentMasterId = action.payload;
      state.currentProcess = 'IMPORT';
    },
    setNfOriginalData: (state, action) => {
      state.nfOriginalData = action.payload.emiScannerDataList;
      state.nfOriginalCoordinate = action.payload.emiCoordinate;
      state.nfMaxData = findMaxNv(action.payload.emiScannerDataList);
      state.aiData = {
        masterInfo: null,
        emi2dGraphList: null,
        rejectionMessage: '',
        emiHPeakList: [],
        emiVPeakList: [],
        processId: null,
        comment: null,
      };
    },
    setAiData: (state, action) => {
      state.aiData.masterInfo = action.payload.masterInfo;
      state.aiData.emi2dGraphList = action.payload.emi2dGraphList;
      state.aiData.rejectionMessage = action.payload.rejectionMessage;
      state.aiData.emiVPeakList = action.payload.emiVPeakList;
      state.aiData.emiHPeakList = action.payload.emiHPeakList;
      state.aiData.processId = action.payload.processId;
      state.aiData.comment = action.payload.comment;
      state.currentProcess = 'AI_SCAN';
    },
    setCurrentProcess: (state, action) => {
      state.currentProcess = action.payload;
    },
    setSelectedTableRow: (state, action) => {
      state.selectedTableRow = action.payload;
    },
    setFrqValue: (state, action) => {
      // if (typeof state.selectedTableRow?.frq !== 'undefined') {
      state.selectedTableRow.frq = action.payload?.frq ?? 0;
      state.selectedTableRow.NF = action.payload.NF;
      state.selectedTableRow.Fv = action.payload.Fv;
      state.selectedTableRow.Fh = action.payload.Fh;
      state.selectedTableRow.Gv = action.payload.Gv;
      state.selectedTableRow.Gh = action.payload.Gh;
      state.selectedTableRow.id = action.payload.id;
      // }
    },
    setDebuggingFrqValue: (state, action) => {
      if (typeof state.selectedTableRow?.frq !== 'undefined') {
        state.selectedDebuggingTableRow.id = action.payload.id;
        state.selectedDebuggingTableRow.frq = action.payload.frq;
      }
    },
    setPredictProcessList: (state, action) => {
      state.predictProcessList = action.payload;
    },
    setGraphDataByProcessId: (state, action) => {
      state.aiData.masterInfo = action.payload.masterInfo;
      state.aiData.emi2dGraphList = action.payload.emi2dGraphList;
      state.aiData.rejectionMessage = action.payload.rejectionMessage;
      state.aiData.emiVPeakList = action.payload.emiVPeakList;
      state.aiData.emiHPeakList = action.payload.emiHPeakList;
      state.aiData.processId = action.payload.processId;
      state.aiData.comment = action.payload.comment;
      state.currentProcess = 'AI_SCAN';
    },
    setCommandLineParameter: (state, action) => {
      state.commandLineParam = action.payload;
    },
    // eslint-disable-next-line no-unused-vars
    resetState: (state, action) => {
      // aiData
      state.aiData.masterInfo = null;
      state.aiData.emi2dGraphList = null;
      state.aiData.rejectionMessage = '';
      state.aiData.emiHPeakList = [];
      state.aiData.emiVPeakList = [];
      state.aiData.processId = null;
      state.aiData.comment = null;

      // nfOriginalData
      state.nfOriginalData = [];
      state.nfMaxData = [];
      state.nfOriginalCoordinate.id = 0;
      state.nfOriginalCoordinate.masterId = 0;
      state.nfOriginalCoordinate.x_pos = '';
      state.nfOriginalCoordinate.y_pos = '';

      // currentProcess
      state.currentProcess = null;

      // currentMasterId
      state.currentMasterId = null;
    },
  },
});

export const getFileList = () => fileList;

export const {
  setEmiLis,
  setEmiHistory,
  setEmiFileMetaData,
  setCurrentMasterId,
  setNfOriginalData,
  setAiData,
  setCurrentProcess,
  setSelectedTableRow,
  setFrqValue,
  setPredictProcessList,
  setGraphDataByProcessId,
  setDebuggingFrqValue,
  setCommandLineParameter,
  resetState,
} = emi.actions;
export default emi.reducer;
