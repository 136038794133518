import { useRef, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as echarts from 'echarts';

import { setFrqValue } from '@reducer/emiSlice';
import { Div } from '@components/index';
import { useSelector, useDispatch } from 'react-redux';

const LineChartContainer = styled(Div)`
  background-color: #2a2a2a;
  height: 420px;
  width: 730px;
`;

const EmiLineChart = ({
  id,
  title = '',
  xAxisType,
  lineData,
  limitData,
  fvData,
  fhData,
  gvData,
  ghData,
  vPeak,
  hPeak,
  legendStatus,
  remoteTooltipControl = false,
  rowSelection,
}) => {
  const dispatch = useDispatch();
  const lineChartRef = useRef(null);
  const controllableRef = useRef(null);
  const currentProcess = useSelector((state) => state.emi.currentProcess);
  // eslint-disable-next-line
  const [linChartOption, setLinChartOption] = useState(() => {
    const original = [];
    const limit = [];
    const fv = [];
    const fh = [];
    const gv = [];
    const gh = [];

    const option = {
      animation: false, // 애니메이션 비활성화
      legend: {
        show: false,

        selected: {
          NF: true,
          LimitLine: false,
          FF: true,
          GT: true,
        },
        data: [],
        textStyle: {
          color: 'white',
        },
      },
      grid: {
        show: true,
        top: '10px',
        bottom: '30px',
        left: '30px',
        right: '20px',
        backgroundColor: '#000000',
        borderColor: '#000000',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
        },
        formatter: (params) => {
          const axisValue = params[0].axisValue;
          const seriesInfo = params
            .filter((item) => item.seriesName !== 'LIMITLINE')
            .map((item) => {
              return `${item.marker} ${item.seriesName}: ${item.data[1]}`;
            })
            .join('<br/>');

          return `
              <div>
                  <div><strong> ${axisValue}</strong></div>
                  <div>${seriesInfo}</div>
              </div>
          `;
        },
        backgroundColor: 'rgba(50,50,50,0.7)',
        borderWidth: 0,
        textStyle: {
          color: 'white',
        },
      },
      xAxis: {
        type: 'value',
        min: 30,
        max: 1000,
        axisLabel: {
          show: true,
          color: '#F9F9F9',
          fontSize: 16,
          fontFamily: 'Pretendard',
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#000000',
            width: 1,
            type: 'solid',
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#444444',
            width: 1,
            type: 'solid',
          },
        },
      },
      yAxis: {
        type: 'value',
        min: 0,
        max: 55,
        axisLabel: {
          show: true,
          color: '#F9F9F9',
          fontSize: 16,
          fontFamily: 'Pretendard',
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#000000',
            width: 1,
            type: 'solid',
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#444444',
            width: 1,
            type: 'solid',
          },
        },
      },

      dataZoom: [
        {
          id: 'dataZoomX',
          type: 'inside',
          xAxisIndex: 0,
          start: 0,
          end: 100,
        },
        {
          id: 'dataZoomY',
          type: 'inside',
          yAxisIndex: [0],
          filterMode: 'filter',
        },
      ],
      series: [
        {
          name: 'NF',
          type: 'line',
          showSymbol: false,
          data: original,
          colorBy: 'data',
          triggerLineEvent: true,
          triggerAreaEvent: true,
          itemStyle: {
            color: '#366EFF',
          },
          lineStyle: {
            width: 2,
            color: '#366EFF',
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                { offset: 0, color: 'rgba(0, 0, 0,0)' },
                { offset: 1, color: 'rgba(0, 0, 0,0)' },
              ],
              global: false,
            },
          },
          markPoint: {
            data: [
              {
                type: 'max',
              },
            ],
            symbol: 'pin',
          },
          markLine: {
            show: true,
            symbol: false,
            symbolSize: 0,

            lineStyle: {
              type: 'solid',
              color: '#444444',
            },
            label: {
              color: '#F9F9F9',
              fontSize: 16,
              fontFamily: 'Pretendard',
            },
            data: [
              { xAxis: 50, label: { show: true, position: 'start' } },
              { xAxis: 300, label: { show: true, position: 'start' } },
              { xAxis: 500, label: { show: true, position: 'start' } },
            ],
          },
        },
        {
          name: 'LIMITLINE',
          type: 'line',
          symbol: 'none',
          colorBy: 'series',
          triggerLineEvent: true,
          triggerAreaEvent: true,
          itemStyle: {
            show: false,
            color: '#fff',
          },
          lineStyle: {
            width: 1,
            color: '#FF0303',
            type: 'dashed',
          },
          data: limit,
        },
        {
          name: 'Fv',
          type: 'line',
          showSymbol: false,
          data: fv,
          colorBy: 'data',
          triggerLineEvent: true,
          triggerAreaEvent: true,
          itemStyle: {
            color: '#FF0000',
          },
          lineStyle: {
            width: 2,
            color: '#FF0000',
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                { offset: 0, color: 'rgba(0, 0, 0,0)' },
                { offset: 1, color: 'rgba(0, 0, 0,0)' },
              ],
              global: false,
            },
          },
          markPoint: {
            data: [
              {
                type: 'max',
                symbol: 'pin',
              },
            ],
          },
        },
        {
          name: 'Fh',
          type: 'line',
          showSymbol: false,
          data: fh,
          colorBy: 'data',
          triggerLineEvent: true,
          triggerAreaEvent: true,
          itemStyle: {
            color: '#05FF00',
          },
          lineStyle: {
            width: 2,
            color: '#05FF00',
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                { offset: 0, color: 'rgba(0, 0, 0,0)' },
                { offset: 1, color: 'rgba(0, 0, 0,0)' },
              ],
              global: false,
            },
          },
          markPoint: {
            data: [
              {
                type: 'max',
                symbol: 'pin',
              },
            ],
          },
        },
        {
          name: 'Gv',
          type: 'line',
          showSymbol: false,
          data: gv,
          colorBy: 'data',
          triggerLineEvent: true,
          triggerAreaEvent: true,
          itemStyle: {
            color: '#fff',
          },
          lineStyle: {
            width: 2,
            color: '#fff',
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                { offset: 0, color: 'rgba(0, 0, 0,0)' },
                { offset: 1, color: 'rgba(0, 0, 0,0)' },
              ],
              global: false,
            },
          },
          markPoint: {
            data: [
              {
                type: 'max',
              },
            ],
            symbol: 'pin',
          },
        },
        {
          name: 'Gh',
          type: 'line',
          showSymbol: false,
          data: gh,
          colorBy: 'data',
          triggerLineEvent: true,
          triggerAreaEvent: true,
          itemStyle: {
            color: '#FAFF00',
          },
          lineStyle: {
            width: 2,
            color: '#FAFF00',
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                { offset: 0, color: 'rgba(0, 0, 0,0)' },
                { offset: 1, color: 'rgba(0, 0, 0,0)' },
              ],
              global: false,
            },
          },
          markPoint: {
            data: [
              {
                type: 'max',
              },
            ],
            symbol: 'pin',
          },
        },
      ],
    };
    return option;
  });
  const chartInstance = useRef(null);
  useEffect(() => {
    if (lineChartRef.current) {
      chartInstance.current = echarts;
      lineChartRef.current = chartInstance.current.init(lineChartRef.current);
      const updatedOption = { ...linChartOption };
      if (Array.isArray(lineData) && lineData.length > 0) {
        updatedOption.series[0].data = lineData;
        const nfIndex = updatedOption.legend.data.indexOf('NF');
        if (nfIndex === -1) {
          updatedOption.legend.data.push('NF');
        }
      } else {
        updatedOption.series[0].data = [];
      }

      if (Array.isArray(limitData)) {
        updatedOption.yAxis.name = 'Far-Field [dB(uV/m)]';
        if (limitData.length > 0) {
          updatedOption.series[1].data = limitData;
          const limitLineIndex = updatedOption.legend.data.indexOf('LIMITLINE');
          if (limitLineIndex === -1) {
            updatedOption.legend.data.push('LIMITLINE');
          }
        } else {
          updatedOption.series[1].data = [];
        }
      }
      if (Array.isArray(fvData)) {
        if (fvData.length > 0) {
          updatedOption.series[2].data = fvData;
          updatedOption.legend.data.push('Fv');
          const fvIndex = updatedOption.legend.data.indexOf('Fv');
          if (fvIndex === -1) {
            updatedOption.legend.data.push('Fv');
          }
          if (vPeak && vPeak.length > 0) {
            const defaultData = [
              {
                type: 'max',
                symbol: 'pin',
              },
            ];
            updatedOption.series[2].markPoint.data = defaultData;
            vPeak.forEach((item) => {
              const targetIndex = fvData.findIndex((el) => el[0] === item.frq);
              if (targetIndex > -1) {
                updatedOption.series[2].markPoint.data.push({
                  symbol: 'triangle',
                  symbolRotate: 180,
                  symbolSize: 8,
                  symbolOffset: [0, -8],
                  coord: [item.frq, item.peak_v],

                  itemStyle: {
                    color: '#ff0000',
                  },
                });
              }
            });
          }
        } else {
          updatedOption.series[2].data = [];
          const aiDataIndex = updatedOption.legend.data.indexOf('Fv');
          const defaultData = [
            {
              type: 'max',
              symbol: 'pin',
            },
          ];
          updatedOption.series[2].markPoint.data = defaultData;
          if (aiDataIndex > -1) {
            updatedOption.legend.data.splice(aiDataIndex, 1);
          }
        }
      }
      if (Array.isArray(fhData)) {
        if (fhData.length > 0) {
          updatedOption.series[3].data = fhData;
          const fhIndex = updatedOption.legend.data.indexOf('Fh');
          if (fhIndex === -1) {
            updatedOption.legend.data.push('Fh');
          }
          if (hPeak && hPeak.length > 0) {
            const defaultData = [
              {
                type: 'max',
                symbol: 'pin',
              },
            ];
            updatedOption.series[3].markPoint.data = defaultData;
            hPeak.forEach((item) => {
              const targetIndex = fhData.findIndex((el) => el[0] === item.frq);
              if (targetIndex > -1) {
                updatedOption.series[3].markPoint.data.push({
                  symbol: 'triangle',
                  symbolRotate: 180,
                  symbolSize: 8,
                  symbolOffset: [0, -8],
                  coord: [item.frq, item.peak_h],
                  itemStyle: {
                    color: '#05ff00',
                  },
                });
              }
            });
          }
        } else {
          updatedOption.series[3].data = [];
          const aiDataIndex = updatedOption.legend.data.indexOf('Fh');
          const defaultData = [
            {
              type: 'max',
              symbol: 'pin',
            },
          ];
          updatedOption.series[3].markPoint.data = defaultData;
          if (aiDataIndex > -1) {
            updatedOption.legend.data.splice(aiDataIndex, 1);
          }
        }
      }
      if (Array.isArray(gvData)) {
        if (gvData.length > 0) {
          updatedOption.series[4].data = gvData;
          const gvIndex = updatedOption.legend.data.indexOf('Gv');
          if (gvIndex === -1) {
            updatedOption.legend.data.push('Gv');
          }
        } else {
          updatedOption.series[4].data = [];
          const gtDataIndex = updatedOption.legend.data.indexOf('Gv');
          if (gtDataIndex > -1) {
            updatedOption.legend.data.splice(gtDataIndex, 1);
          }
        }
      }
      if (Array.isArray(ghData)) {
        if (ghData.length > 0) {
          updatedOption.series[5].data = ghData;
          const ghIndex = updatedOption.legend.data.indexOf('Gh');
          if (ghIndex === -1) {
            updatedOption.legend.data.push('Gh');
          }
        } else {
          updatedOption.series[5].data = [];
          const gtDataIndex = updatedOption.legend.data.indexOf('Gh');
          if (gtDataIndex > -1) {
            updatedOption.legend.data.splice(gtDataIndex, 1);
          }
        }
      }
      if (legendStatus) {
        updatedOption.legend.selected = legendStatus;
      }
      if (xAxisType) {
        if (xAxisType.type === 'value') {
          updatedOption.xAxis = xAxisType;
          updatedOption.series[0].markLine = {};
        } else {
          updatedOption.xAxis = xAxisType;
          updatedOption.series[0].markLine = {
            show: true,
            symbol: false,
            symbolSize: 0,

            lineStyle: {
              type: 'solid',
              color: '#444444',
            },
            label: {
              color: '#F9F9F9',
              fontSize: 16,
              fontFamily: 'Pretendard',
            },
            data: [
              { xAxis: 50, label: { show: true, position: 'start' } },
              { xAxis: 300, label: { show: true, position: 'start' } },
              { xAxis: 500, label: { show: true, position: 'start' } },
            ],
          };
        }
      }
      lineChartRef.current.setOption(updatedOption);
      lineChartRef.current.on('click', (params) => {
        const { offsetX, offsetY } = params.event;
        let dataPoint;
        if (typeof lineChartRef.current.convertFromPixel === 'function') {
          dataPoint = lineChartRef.current.convertFromPixel({ seriesIndex: params.seriesIndex }, [offsetX, offsetY]);
        } else {
          const instance = chartInstance.current.getInstanceByDom(lineChartRef.current);
          dataPoint = instance.convertFromPixel({ seriesIndex: params.seriesIndex }, [offsetX, offsetY]);
        }
        const xAxisIndex = Math.round(dataPoint[0]);
        const xAxisData = updatedOption.series[params.seriesIndex].data.map((item) => {
          return item[0];
        });

        const findClosestIndex = (arr, target) =>
          arr.reduce(
            (closestIndex, num, index, array) =>
              Math.abs(num - target) < Math.abs(array[closestIndex] - target) ? index : closestIndex,
            0
          );
        const clickedIndex = findClosestIndex(xAxisData, xAxisIndex);
        if (typeof rowSelection === 'function') {
          const origin = rowSelection(clickedIndex.toString());

          if (origin) {
            dispatch(
              setFrqValue({
                frq: xAxisData[clickedIndex],
                NF: Number(origin.NF),
                Fv: Number(origin.Fv),
                Fh: Number(origin.Fh),
                Gv: Number(origin.Gv),
                Gh: Number(origin.Gh),
                id: clickedIndex,
              })
            );
          }
        }
      });
    }
    controllableRef.current = lineChartRef.current;

    return () => {
      if (chartInstance.current && lineChartRef.current) {
        chartInstance.current.dispose(lineChartRef.current);
      }
    };
  }, [
    id,
    title,
    xAxisType,
    lineData,
    limitData,
    fvData,
    fhData,
    gvData,
    ghData,
    vPeak,
    hPeak,
    linChartOption,
    currentProcess,
    legendStatus,
    rowSelection,
  ]);

  useEffect(() => {
    if (controllableRef.current && remoteTooltipControl) {
      controllableRef.current.dispatchAction(remoteTooltipControl);
    }
  }, [remoteTooltipControl]);

  return <LineChartContainer id={id} ref={lineChartRef} />;
};

EmiLineChart.propTypes = {
  /** Eventlistener를 부착할 id */
  id: PropTypes.string.isRequired,
  /** 차트 상단에 노출될 그래프 이름 */
  title: PropTypes.string,
  /** X축 눈금 type ex) value, time */
  xAxisType: PropTypes.object,
  /** 그래프 데이터 */
  lineData: PropTypes.array,
  /** 서브 그래프 데이터 */
  limitData: PropTypes.array,
  /** Ai 그래프 데이터 */
  fvData: PropTypes.array,
  /** Ai 그래프 데이터 */
  fhData: PropTypes.array,
  /** Chember 그래프 데이터 */
  gvData: PropTypes.array,
  /** Chember 그래프 데이터 */
  ghData: PropTypes.array,
  /** Fv Peak Data */
  vPeak: PropTypes.array,
  /** Fh Peak Data */
  hPeak: PropTypes.array,
  /** 그래프 Tooltip을 외부 상태로 제어함 */
  remoteTooltipControl: PropTypes.shape({
    type: PropTypes.string,
    x: PropTypes.number,
  }),
  /** 라인 노출여부를 외부 상태로 제어함 */
  legendStatus: PropTypes.shape({
    NF: PropTypes.bool,
    LIMITLINE: PropTypes.bool,
    FF: PropTypes.bool,
    GT: PropTypes.bool,
  }),
  rowSelection: PropTypes.func,
};

export default EmiLineChart;
