import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import { Button, Div, Text } from '@components/index';

const Container = styled(Div)`
  margin-top: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
`;

const Message = styled(Text)`
  font-size: 40px;
  color: white;
`;

const ButtonContainer = styled(Div)`
  margin-top: 40px;
  display: flex;
  column-gap: 20px;
`;

const NavigateButton = styled(Button)`
  background-color: #ccc;
  text-decoration: none;
  border-radius: 10px;
  padding: 12px 20px;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: white;
    text-decoration: none;
  }
`;

const NotFound = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    path && navigate(path);
  };

  return (
    <Container>
      <Message inner='페이지를 찾을 수 없습니다.' />
      <ButtonContainer>
        <NavigateButton onClick={() => handleNavigate('/')}>홈으로 가기</NavigateButton>
        <NavigateButton onClick={() => handleNavigate(-1)}>뒤로 가기</NavigateButton>
      </ButtonContainer>
    </Container>
  );
};

export default NotFound;
