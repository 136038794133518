import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Div, Img } from '@components/index';
import { LoadingAnimation } from '@images/index';

const Container = styled(Div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999999;
`;

const Background = styled(Div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.6;
`;

const LoadingContainer = styled(Div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - (160px * 2));
  height: calc(100% - (140px * 2));
  padding: 80px 100px;
  z-index: 999;
  background-color: #000000;
  border-radius: 10px;
`;

const Loading = styled(Img)`
  width: 400px;
  aspect-ratio: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const LoadingModal = ({ children, showLoading = true }) => {
  return (
    <Container>
      <Background />
      <LoadingContainer>
        {children}
        {showLoading && <Loading src={LoadingAnimation} />}
      </LoadingContainer>
    </Container>
  );
};

LoadingModal.propTypes = {
  children: PropTypes.node,
  showLoading: PropTypes.bool,
};

export default LoadingModal;
