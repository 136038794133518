import { useState, useCallback, useMemo, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';

import styled from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { t } from 'i18next';

import { resetState, setNfOriginalData, setEmiFileMetaData } from '@reducer/emiSlice';
import { get2dGraphDataByProcessIdAPI } from '@action/emi/emiAction';
import { Div, ModalContext } from '@components/index';
import { BASE_URL, HEADER } from '@TAxiosConfig';
import ErrorModal from '@SAM/components/Modal/ErrorModal';
import EmiSolutionMain from './module/EmiSolutionMain';

const MainContainer = styled(Div)`
  flex: 1;
  background-color: #272727;
`;

const HiddenInput = styled.input`
  display: none;
`;

const MainContentArea = styled(Div)`
  background-color: #2b2b29;
  position: relative;
  display: flex;
`;
const DataGridArea = styled(Div)`
  position: relative;
  flex: 1;
  background-color: #272727;
`;

const FORM_KEYS = Object.freeze({
  Frq: 'Frq',
  NF: 'NF',
  FV: 'FV',
  FH: 'FH',
  GV: 'GV',
  GH: 'GH',
  NFIMPORT: 'NFIMPORT',
});

const schema = yup.object().shape({
  [FORM_KEYS.ETA]: yup.number(),
  [FORM_KEYS.Weight]: yup.number(),
  [FORM_KEYS.NPeak]: yup.number(),
  [FORM_KEYS.ThresholdUpper]: yup.number(),
  [FORM_KEYS.ThresholdLower]: yup.number(),
});

const EmiSolution = () => {
  const dispatch = useDispatch();
  const { showModal } = useContext(ModalContext);

  const [searchParams] = useSearchParams();
  const masterId = searchParams.get('masterId');
  const processId = searchParams.get('processId');

  const nfOriginalData = useSelector((state) => state.emi.nfOriginalData);
  const aiData = useSelector((state) => state.emi.aiData.emi2dGraphList);
  const emiVPeakList = useSelector((state) => state.emi.aiData.emiVPeakList);
  const emiHPeakList = useSelector((state) => state.emi.aiData.emiHPeakList);
  const currentProcess = useSelector((state) => state.emi.currentProcess);
  const currentMasterId = useSelector((state) => state.emi.currentMasterId);
  const methods = useForm({ resolver: yupResolver(schema) });
  const {
    formState: { errors },
  } = methods;

  const [fileKey, setFileKey] = useState(0); // 동일한 file을 업로드 할 경우 input의 onChange가 호출되지 않아 추가함

  const handleFileChange = useCallback(
    (event) => {
      const file = event.target.files;
      const reader = new FileReader();
      reader.onload = () => {
        dispatch(setEmiFileMetaData(file));
      };
      reader.readAsText(file[0]);
    },
    [fileKey]
  );

  /* eslint-disable camelcase */
  const emiData = useMemo(() => {
    const NF = [];
    const LIMITLINE = [];
    const Fv = [];
    const Fh = [];
    const Gv = [];
    const Gh = [];
    const TableData = [];

    let vPeak = [];
    let hPeak = [];
    let peakData = [];

    const TempMargin_V = [];
    const TempMargin_H = [];

    if (aiData) {
      aiData.forEach((data) => {
        const frq = Number(data.frequency);
        const scannerNvMax = Number(data.scannerNvMax);
        const vPredictionNvMax = Number(data.vPredictionNvMax);
        const hPredictionNvMax = Number(data.hPredictionNvMax);
        const vChamberNvMax = Number(data.vChamberNvMax);
        const hChamberNvMax = Number(data.hChamberNvMax);
        NF.push([frq, scannerNvMax]);

        Fv.push([frq, vPredictionNvMax]);
        Fh.push([frq, hPredictionNvMax]);
        Gv.push([frq, vChamberNvMax]);
        Gh.push([frq, hChamberNvMax]);
        if (frq >= 30 && frq <= 230) {
          LIMITLINE.push([frq, 30]);
        } else if (frq <= 1000) {
          LIMITLINE.push([frq, 37]);
        }
        let passFail;

        if (frq >= 30 && frq <= 230) {
          passFail = vPredictionNvMax < 30 && hPredictionNvMax < 30 ? 'pass' : 'fail';
          TempMargin_V.push({
            frq,
            peak_v: vPredictionNvMax,
            margin_v: Number((30 - vPredictionNvMax).toFixed(2)),
            passFail: vPredictionNvMax < 30 ? 'pass' : 'fail',
          });
          TempMargin_H.push({
            frq,
            peak_h: hPredictionNvMax,
            margin_h: Number((30 - hPredictionNvMax).toFixed(2)),
            passFail: hPredictionNvMax < 30 ? 'pass' : 'fail',
          });
        } else if (frq <= 1000) {
          passFail = vPredictionNvMax < 37 && hPredictionNvMax < 37 ? 'pass' : 'fail';
          TempMargin_V.push({
            frq,
            peak_v: vPredictionNvMax,
            margin_v: Number((37 - vPredictionNvMax).toFixed(2)),
            passFail: vPredictionNvMax < 37 ? 'pass' : 'fail',
          });
          TempMargin_H.push({
            frq,
            peak_h: hPredictionNvMax,
            margin_h: Number((37 - hPredictionNvMax).toFixed(2)),
            passFail: hPredictionNvMax < 37 ? 'pass' : 'fail',
          });
        }

        TableData.push({
          frq,
          Fv: vPredictionNvMax,
          Fh: hPredictionNvMax,
          passFail,
          Gv: vChamberNvMax,
          Gh: hChamberNvMax,
          NF: scannerNvMax,
        });
      });
    } else if (nfOriginalData) {
      nfOriginalData.forEach((data) => {
        const frq = Number(data.frequency);
        const scannerNvMax = Number(data.nv_max);
        NF.push([frq, scannerNvMax]);
        TableData.push({ frq, Fv, Fh, passFail: '', Gv, Gh, NF: scannerNvMax });
      });
    }

    if (aiData && emiVPeakList?.length > 0 && emiHPeakList?.length) {
      vPeak = emiVPeakList.map((v) => {
        return {
          frq: v.frequency,
          peak_v: v.peak_v,
          margin_v: v.margin,
          passFail: v.margin_v <= 0 ? 'fail' : 'pass',
        };
      });
      hPeak = emiHPeakList.map((h) => {
        return {
          frq: h.frequency,
          peak_h: h.peak_h,
          margin_h: h.margin,
          passFail: h.margin_h <= 0 ? 'fail' : 'pass',
        };
      });

      peakData = vPeak.map((item, index) => {
        return {
          frq: item.frq,
          peak_v: item.peak_v,
          margin_v: item.margin_v,
          peak_h: hPeak[index]?.peak_h,
          margin_h: hPeak[index]?.margin_h,
        };
      });

      const top5LowestMarginV = TempMargin_V.sort((a, b) => a.margin_v - b.margin_v) // margin_v 값이 낮은 순서로 정렬
        .slice(0, 5); // 상위 5개 객체 추출
      const top5LowestMarginH = TempMargin_H.sort((a, b) => a.margin_h - b.margin_h) // margin_h 값이 낮은 순서로 정렬
        .slice(0, 5); // 상위 5개 객체 추출
      const updateOrAddData = (top5, other) => {
        const frequencyMap = new Map();

        // `otherData`의 `frequency` 값을 키로 해서 Map에 저장
        other.forEach((item) => frequencyMap.set(item.frq, item));

        // `top5LowestMarginV`의 `frequency` 값을 기준으로 교체 또는 추가
        top5.forEach((item) => {
          if (frequencyMap.has(item.frq)) {
            // `frequency`가 이미 존재하면 데이터를 교체
            frequencyMap.set(item.frq, item);
          } else {
            // `frequency`가 존재하지 않으면 데이터를 추가
            frequencyMap.set(item.frq, item);
          }
        });

        // Map에서 객체를 배열로 변환하여 반환
        return Array.from(frequencyMap.values());
      };

      vPeak = updateOrAddData(top5LowestMarginV, vPeak).sort((a, b) => a.frq - b.frq);
      hPeak = updateOrAddData(top5LowestMarginH, hPeak).sort((a, b) => a.frq - b.frq);
    }
    return { NF, LIMITLINE, Fv, Fh, Gv, Gh, TableData, vPeak, hPeak, peakData };
  }, [aiData, nfOriginalData, currentProcess, emiVPeakList, emiHPeakList]);
  /* eslint-enable camelcase */

  useEffect(() => {
    const graphDataParam = {
      masterId,
      processId,
    };
    // masterId와 processId가 동시에 null이 아닌 경우: DataManagement에서 Table Row를 선택하여 솔루션에 진입한 경우 or url 입력
    if (masterId !== null && processId !== null) {
      get2dGraphDataByProcessIdAPI(dispatch, graphDataParam, (resResult) => {
        if (resResult?.status === false) {
          showModal(<ErrorModal errorMessage={t(resResult?.message) ?? ''} />);
        }
      });
    } else if (
      // processId가 null이면서 currentMasterId / masterId 둘 중 하나만 null이 아닌 경우
      // CASE1: currentMasterId만 null이 아닌 경우:
      //        1. debugging 화면에서 csv업로드 후 솔루션에 진입한 경우
      //        2. solution에서 csv 업로드
      // CASE2: masterId만 null이 아닌 경우: solution 업로드
      processId === null &&
      ((currentMasterId !== null && masterId === null) || (currentMasterId === null && masterId !== null))
    ) {
      console.log('###', masterId, currentMasterId);
      axios
        .get(
          `${BASE_URL}/api/emi/getEMIScannerDataByMasterId`,
          { params: { masterId: masterId ?? currentMasterId } },
          HEADER.GET
        )
        .then((nfDataResponse) => {
          const nfOriginal = { ...nfDataResponse.data.body };

          // Function to replace positions with sequential numbers
          const replaceWithSequentialNumbers = (positions) => {
            const posArray = positions
              .split(',')
              .map((str) => str.trim())
              .filter((str) => str.length > 0);
            const posMap = new Map();
            let counter = 0;

            return posArray.map((pos) => {
              if (!posMap.has(pos)) {
                posMap.set(pos, counter);
                counter++;
              }
              return posMap.get(pos);
            });
          };

          const xPosReplaced = replaceWithSequentialNumbers(nfOriginal.emiCoordinate.x_pos);
          const yPosReplaced = replaceWithSequentialNumbers(nfOriginal.emiCoordinate.y_pos);

          const coordinates = xPosReplaced.map((x, index) => [x, yPosReplaced[index]]);
          nfOriginal.coordinates = coordinates;
          const ret = nfOriginal.emiScannerDataList.map((item) => {
            const arr = item.nv_data
              .split(',')
              .map((str) => str.trim())
              .filter((str) => str.length > 0);
            const nvObj = arr.reduce((obj, value, index) => {
              obj[`nv${index + 1}`] = value;
              return obj;
            }, {});
            return { ...{ frequency: item.frequency, nv_max: item.nv_max }, ...nvObj };
          });
          nfOriginal.emiScannerDataList = ret;
          dispatch(setNfOriginalData(nfOriginal));
        });
    } else {
      dispatch(resetState());
    }
  }, [masterId, processId, currentMasterId]);

  return (
    <FormProvider {...methods}>
      <MainContainer>
        <MainContentArea>
          <DataGridArea>
            <HiddenInput
              key={fileKey}
              id='nf-import'
              name={FORM_KEYS}
              type='file'
              accept='.csv'
              onChange={(e) => {
                handleFileChange(e);
                setFileKey((prevKey) => prevKey + 1);
              }}
            />
            <EmiSolutionMain emiData={emiData} FORM_KEYS={FORM_KEYS} errors={errors} />
          </DataGridArea>
        </MainContentArea>
      </MainContainer>
    </FormProvider>
  );
};

EmiSolution.propTypes = {};

export default EmiSolution;
