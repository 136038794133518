import { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { setBikeFileMetaData } from '@reducer/bikeSlice';
import { Div } from '@components/index';
import BikeSolutionMain from './module/BikeSolutionMain';

const MainContainer = styled(Div)`
  flex: 1;
  background-color: #272727;
`;

const HiddenInput = styled.input`
  display: none;
`;

const MainContentArea = styled(Div)`
  background-color: #2b2b29;
  position: relative;
  display: flex;
`;
const DataGridArea = styled(Div)`
  position: relative;
  flex: 1;
  background-color: #272727;
`;

const BikeSolution = () => {
  const dispatch = useDispatch();
  const bikeOriginalData = useSelector((state) => state.bike.bikeOriginalData);
  const bikeResultList = useSelector((state) => state.bike.bikeResultList);

  const [fileKey, setFileKey] = useState(0); // 동일한 file을 업로드 할 경우 input의 onChange가 호출되지 않아 추가함

  const handleFileChange = useCallback(
    (event) => {
      const file = event.target.files;
      const reader = new FileReader();
      reader.onload = () => {
        dispatch(setBikeFileMetaData(file));
      };
      reader.readAsText(file[0]);
    },
    [fileKey]
  );

  const bikeData = useMemo(() => {
    let chartMaxY = 2;
    const bikeOriginalObj = {
      season: [],
      holiday: [],
      workingDay: [],
      weather: [],
      temp: [],
      atemp: [],
      humidity: [],
      windSpeed: [],
      casual: [],
      sinHours: [],
      cosHours: [],
    };

    const bikeAiDataObj = {
      gtCount: [],
      predictionCount: [],
    };

    // FIXME: 정규화 확인 필요
    const nomalizeLine = ['atemp', 'casual', 'humidity', 'temp', 'windSpeed'];
    const minMaxValues = {
      temp: { min: -10, max: 40 },
      atemp: { min: -10, max: 50 },
      humidity: { min: 0, max: 100 },
      windSpeed: { min: 0, max: 50 },
      casual: { min: 0, max: 100 },
    };

    const normalize = (value, min, max) => {
      return Math.floor(((value - min) / (max - min)) * 100) / 100;
    };

    // 시간 x 값 생성 (0부터 23까지)
    const hours = Array.from({ length: 24 }, (_, i) => i);

    if (Array.isArray(bikeOriginalData) && bikeOriginalData.length > 0) {
      chartMaxY = bikeOriginalData[0].season !== 1 ? bikeOriginalData[0].season : 2;
      bikeOriginalData.forEach((data, index) => {
        Object.keys(data).forEach((key) => {
          if (bikeOriginalObj[key]) {
            if (nomalizeLine.includes(key)) {
              bikeOriginalObj[key].push([index, normalize(data[key], minMaxValues[key].min, minMaxValues[key].max)]);
            } else {
              bikeOriginalObj[key].push([index, data[key]]);
            }
          }
        });
      });
      bikeOriginalObj.sinHours = hours.map((hour, index) => [index, Math.sin((2 * Math.PI * hour) / 24)]);
      bikeOriginalObj.cosHours = hours.map((hour, index) => [index, Math.cos((2 * Math.PI * hour) / 24)]);
    }

    if (Array.isArray(bikeResultList) && bikeResultList.length > 0) {
      bikeResultList.forEach((data, index) => {
        bikeAiDataObj.gtCount.push([index, data.gtCount]);
        bikeAiDataObj.predictionCount.push([index, data.predictionCount]);
      });
    }
    return { bikeOriginalObj, bikeAiDataObj, chartMaxY };
  }, [bikeResultList, bikeOriginalData]);

  return (
    <MainContainer>
      <MainContentArea>
        <DataGridArea>
          <HiddenInput
            key={fileKey}
            id='bike-import'
            type='file'
            accept='.csv'
            onChange={(e) => {
              handleFileChange(e);
              setFileKey((prevKey) => prevKey + 1);
            }}
          />
          <BikeSolutionMain bikeData={bikeData} />
        </DataGridArea>
      </MainContentArea>
    </MainContainer>
  );
};

export default BikeSolution;
