import { Outlet } from 'react-router-dom';
import { Div, AppBar } from '@components/index';
import styled from 'styled-components';

const Container = styled(Div)`
  display: flex;
  flex-direction: column;
`;

const Footer = styled(Div)`
  display: flex;
  justify-content: center;
  padding: 50px 0;
  color: #fff;
  font-size: 13px;
  list-style-type: none;
  text-decoration: none;
  letter-spacing: -1px;
  text-transform: uppercase;
`;

const LandingLayout = () => {
  return (
    <>
      <AppBar />
      <Container>
        <Outlet />
        <Footer>Copyright © 2024. All rights reserved.</Footer>
      </Container>
    </>
  );
};

export default LandingLayout;
