import { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';

import axios from 'axios';
import styled, { css } from 'styled-components';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';

import { BASE_URL, HEADER } from '@TAxiosConfig';
import { getFileList, setCurrentMasterId, setNfOriginalData } from '@reducer/emiSlice';
import { convertDateToString } from '@utils';
import { Div, Img, ModalContext, Text, Modal, Input, Button, TextArea } from '@components/index';
import { NFImportBg } from '@SAM/images';
import ErrorModal from '@SAM/components/Modal/ErrorModal';
import { useTranslation } from 'react-i18next';
import i18n from '@i18n';

const ImportContainer = styled(Div)`
  display: flex;
  row-gap: 20px;
  width: 800px;
  height: 440px;
`;

const disabledStyle = css`
  background-color: #353535;
  color: #c1c1c1;
  height: 35px;
  border-radius: 5px;
  border: 0px;
  width: 215px;
  border-radius: 5px;
  border: 0px;
  :hover {
    border-radius: 5px;
    border: 0px;
  }
`;

const InputFormStyleModelName = css`
  background-color: #e0e0e0;
  color: #5e5e5e;
  height: 35px;
  border-radius: 5px;
  border: 0px;
  width: 340px;

  border-radius: 5px;
  border: 0px;
  :hover {
    border-radius: 5px;
    border: 0px;
  }
`;
const InputFormStyleInch = css`
  background-color: #e0e0e0;
  color: #5e5e5e;
  height: 35px;
  border-radius: 5px;
  border: 0px;
  width: 90px;

  border-radius: 5px;
  border: 0px;
  :hover {
    border-radius: 5px;
    border: 0px;
  }
`;
const FileFormStyle = css`
  background-color: #e0e0e0;
  color: #5e5e5e;
  height: 35px;
  border-radius: 5px;
  border: 0px;
  flex: 1;
  width: 300px;
  border-radius: 5px;
  border: 0px;
  :hover {
    border-radius: 5px;
    border: 0px;
  }
`;

const ImgContainer = styled(Div)`
  position: relative;
  color: #fff;
  background: #20b9fd;
`;
const ImportText = styled(Text)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 45px;
  font-weight: 600;
`;
const FormContainer = styled(Div)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
`;

const FormRow = styled(Div)`
  display: flex;
  background-color: #262626;
  gap: 10px;
`;

const ImportImg = styled(Img)`
  width: 300px;
  height: 390px;
  opacity: 0.15;
`;

const NFButton = styled(Button)`
  background-color: #20b9fd;
  height: 35px;
  text-decoration: none;
  border-radius: 5px;
  padding: 16px 0;
  color: #fff;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  &:hover {
    text-decoration: none;
    color: white;
    font-weight: 700;
  }
`;
const SelectFile = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #20b9fd;
  height: 35px;
  width: 130px;
  text-decoration: none;
  border-radius: 5px;
  padding: 16px 0;
  color: #fff;

  &:hover {
    text-decoration: none;
    color: #fff;
    font-weight: 700;
  }
`;

const BGColor = css`
  background-color: #262626;
  flex: 1;
  padding: 0;
`;

const ModalBody = css`
  padding: 0;
`;

const FORM_KEYS = Object.freeze({
  USERID: 'USERID',
  DATE: 'DATE',
  FILENAME: 'FILENAME',
  MODELNAME: 'MODELNAME',
  INCH: 'INCH',
  MEMO: 'MEMO',
});

const Label = styled.label`
  cursor: pointer;
`;

const schema = yup.object().shape({
  [FORM_KEYS.USERID]: yup.string(),
  [FORM_KEYS.DATE]: yup.string(),
  [FORM_KEYS.FILENAME]: yup.string(),
  [FORM_KEYS.MODELNAME]: yup.string().required(i18n.t('VALDATION.REQUIRED')),
  [FORM_KEYS.INCH]: yup.string().required(i18n.t('VALDATION.REQUIRED')),
  [FORM_KEYS.MEMO]: yup.string(),
});

const LoginForm = ({ handleNavigate }) => {
  const dispatch = useDispatch();
  const { showModal, closeModal } = useContext(ModalContext);
  const fileMetaData = useSelector((state) => state.emi.fileMetaData);
  const methods = useForm({ resolver: yupResolver(schema) });
  const {
    handleSubmit,
    formState: { errors },
  } = methods;
  const { t } = useTranslation();

  const submitCallback = useCallback(
    (data) => {
      const targetFile = getFileList()[0];
      const formData = new FormData();
      formData.append('modelName', data?.MODELNAME);
      formData.append('inch', data.INCH.toString());
      formData.append('memo', data.MEMO);
      formData.append('file', targetFile);
      axios
        .post(`${BASE_URL}/api/emi/uploadInputFile`, formData, HEADER.MULTIPART)
        .then((res) => {
          const masterId = res.data.body.masterId;
          if (handleNavigate) {
            handleNavigate({ search: `?masterId=${masterId}` });
          }
          // 업로드 시 반환받은 targetFile의 id를 가지고 서버에 저장된 원본 데이터를 불러옴
          axios
            .get(`${BASE_URL}/api/emi/getEMIScannerDataByMasterId`, { params: { masterId } }, HEADER.GET)
            .then((nfDataResponse) => {
              const nfOriginal = { ...nfDataResponse.data.body };

              // Function to replace positions with sequential numbers
              const replaceWithSequentialNumbers = (positions) => {
                const posArray = positions
                  .split(',')
                  .map((str) => str.trim())
                  .filter((str) => str.length > 0);
                const posMap = new Map();
                let counter = 0;

                return posArray.map((pos) => {
                  if (!posMap.has(pos)) {
                    posMap.set(pos, counter);
                    counter++;
                  }
                  return posMap.get(pos);
                });
              };

              const xPosReplaced = replaceWithSequentialNumbers(nfOriginal.emiCoordinate.x_pos);
              const yPosReplaced = replaceWithSequentialNumbers(nfOriginal.emiCoordinate.y_pos);

              const coordinates = xPosReplaced.map((x, index) => [x, yPosReplaced[index]]);
              nfOriginal.coordinates = coordinates;
              const ret = nfOriginal.emiScannerDataList.map((item) => {
                const arr = item.nv_data
                  .split(',')
                  .map((str) => str.trim())
                  .filter((str) => str.length > 0);
                const nvObj = arr.reduce((obj, value, index) => {
                  obj[`nv${index + 1}`] = value;
                  return obj;
                }, {});
                return { ...{ frequency: item.frequency, nv_max: item.nv_max }, ...nvObj };
              });
              nfOriginal.emiScannerDataList = ret;
              dispatch(setNfOriginalData(nfOriginal));
              dispatch(setCurrentMasterId(masterId));
            });
          closeModal();
        })
        .catch((err) => {
          showModal(<ErrorModal errorMessage={t(err.response?.data?.message) ?? ''} />);
        });
    },
    [fileMetaData]
  );

  return (
    <FormProvider {...methods}>
      <FormContainer>
        <FormRow>
          <Input
            name={FORM_KEYS.USERID}
            classes={{ Variant: disabledStyle }}
            type='text'
            value='chamberless@samsung.com'
          />
          <Input
            name={FORM_KEYS.DATE}
            classes={{ Variant: disabledStyle }}
            type='text'
            required
            value={convertDateToString(new Date(), 'dot')}
          />
        </FormRow>
        <FormRow>
          <Input
            name={FORM_KEYS.FILENAME}
            classes={{ Variant: FileFormStyle }}
            type='text'
            errorMessage={errors[FORM_KEYS.FILENAME]?.message}
            required
            value={fileMetaData.name}
            placeholder={t('PLACEHOLDER.FILE')}
            readOnly
          />
          <Label htmlFor='nf-import'>
            <SelectFile>{t('COMMON.BUTTON.FILE')}</SelectFile>
          </Label>
        </FormRow>
        <FormRow>
          <Input
            name={FORM_KEYS.MODELNAME}
            classes={{ Variant: InputFormStyleModelName }}
            type='text'
            errorMessage={errors[FORM_KEYS.MODELNAME]?.message}
            placeholder={t('PLACEHOLDER.MODEL')}
            required
          />
          <Input
            name={FORM_KEYS.INCH}
            classes={{ Variant: InputFormStyleInch }}
            type='text'
            errorMessage={errors[FORM_KEYS.INCH]?.message}
            placeholder='inch'
            required
          />
        </FormRow>
        <FormRow>
          <TextArea name={FORM_KEYS.MEMO} errorMessage={errors[FORM_KEYS.MEMO]?.message} placeholder='Memo' />
        </FormRow>
        <NFButton onClick={handleSubmit(submitCallback)}>{t('COMMON.BUTTON.CONFIRM')}</NFButton>
      </FormContainer>
    </FormProvider>
  );
};

LoginForm.propTypes = {
  handleNavigate: PropTypes.func,
};

const EmiImportModal = ({ handleNavigate }) => {
  return (
    <ImportContainer>
      <ImgContainer>
        <ImportText inner='NF Import' />
        <ImportImg src={NFImportBg} />
      </ImgContainer>
      <Modal
        classes={{ Container: BGColor, Header: BGColor, Body: ModalBody }}
        body={<LoginForm handleNavigate={handleNavigate} />}
      />
    </ImportContainer>
  );
};

EmiImportModal.propTypes = {
  handleNavigate: PropTypes.func,
};

export default EmiImportModal;
