import { useMemo, useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { setFrqValue } from '@reducer/emiSlice';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { Div, Text, Icon, Button, ModalContext } from '@components/index';
import { excelDownload, handleImgDownload, startJob } from '@utils';
import { AIScan, NFImport } from '@SAM/images';
import EmiProgressModal from './EmiProgressModal';
import EmiImportModal from './EmiImportModal';

const Container = styled(Div)`
  display: flex;
  height: calc(100vh - 100px);
  flex-direction: column;
  padding: 30px 30px 0 30px;
  background-color: #1d1d1b;
  width: 330px;
  border: 1px solid #444444;
  border-top: none;
  border-bottom: none;
`;

const FullButtonStyle = css`
  text-decoration: none;
  color: #fff;
  background-color: #366eff;
  height: 45px;
  border-radius: 100px;
  width: 100%;
  margin: 0;
  background-color: ${({ runStatus }) => typeof runStatus !== 'undefined' && !runStatus && '#A1A1A1'};
  :hover {
    text-decoration: none;
    color: #fff;
  }
`;
const AIRunButtonStatus = styled(Text)``;

const MenuBlock = styled(Div)`
  ${({ classes }) => classes?.MenuBlockStyle && classes.MenuBlockStyle}
`;

const BorderBottomStyle = css`
  padding-bottom: 20px;
`;

const NFImportStyle = css`
  border-top: 1px solid #494949;
`;

const BottomButtonStyle = css`
  flex: 1;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 15px;
  padding-top: 20px;
  padding-bottom: 10px;
  cursor: pointer;
`;

const FlexRow = styled(Div)`
  display: flex;
  position: relative;
  gap: 10px;
  margin-bottom: 10px;
  ${({ classes }) => classes && classes.Variant && classes.Variant}
`;
const FlexRowBottomStyle = css`
  margin-bottom: 30px;
  margin-top: 10px;
`;

const FlexRowMaxWidth = styled(FlexRow)`
  width: 100%;
`;

const FlexBox = styled(Div)`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ImportControllerDataMaxWidth = styled(Div)`
  background-color: #2a2a2a;
  border: solid 1px #444444;
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  flex: 1;
  height: 40px;
  width: 158px;
  ${({ classes }) => classes && classes.Variant}
`;

const AiScanLabel = styled(Div)`
  display: flex;
  justify-content: space-between;
  padding-top: 0px;
  padding-bottom: 10px;
`;

const ScanRunBox = styled(Div)`
  display: flex;
  align-items: center;
  gap: 10px;
`;

// 서버에 보낼 input이 아님
const AiScanInput = styled.input`
  background-color: #2a2a2a;
  height: 30px;
  width: 60px;
  border-radius: 5px;
  border: none;
  text-align: end;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  &:hover {
    border: none;
  }
`;

const DebuggingBtnStyle = css`
  background-color: #366eff;
  margin: 0;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  background-color: ${({ runStatus }) => typeof runStatus !== 'undefined' && !runStatus && '#444444'};

  :hover {
    text-decoration: none;
    color: #fff;
  }
`;

const ScaleButtonArea = styled(Div)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ScaleButton = styled(Button)`
  background-color: #444444;
  text-decoration: none;
  color: #fff;
  width: 100%;
  margin: 0;
  margin-bottom: 10px;
  height: 40px;
  border-radius: 100px;
  ${({ type, selectedScale }) => type === selectedScale && `background-color: #366EFF;`}
  background-color: ${({ runStatus }) => typeof runStatus !== 'undefined' && !runStatus && '#444444'};
  &:hover {
    text-decoration: none;
    color: rgba(255, 255, 255);
  }
`;

const EmiSideController = ({
  handleClickXAxis,
  tableData,
  rowSelection,
  graphRef,
  emiData,
  masterInfoList,
  rejectionMessage,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchParamsMasterId = searchParams.get('masterId');
  const { showModal } = useContext(ModalContext);
  const currentMasterId = useSelector((state) => state.emi.currentMasterId);
  const isScanReady =
    (typeof currentMasterId !== 'undefined' && currentMasterId !== null) || searchParamsMasterId !== null;

  const selectedTableRow = useSelector((state) => state.emi.selectedTableRow);
  const masterInfo = useSelector((state) => state.emi.aiData.masterInfo);
  const nfOriginalData = useSelector((state) => state.emi.nfOriginalData);
  const [selectedScale, setSelectedScale] = useState('Log');
  const [isShowEmiProgressModal, setIsShowEmiProgressModal] = useState(false);

  const EXCEL_KEYS = Object.freeze({
    Frequency_MHz: 'frq',
    NF: 'NF',
    Fv: 'Fv',
    Fh: 'Fh',
    Gv: 'Gv',
    Gh: 'Gh',
    PassFail_1: 'passFail1',
    Frequency_MhZ_2: 'frequency2',
    Magin_v: 'margin_v',
    PassFail_2: 'passFail2',
    Frequency_MhZ_3: 'frequency3',
    margin_h: 'margin_h',
    PassFail_3: 'passFail3',
    rejectionMessage: 'rejectionMessage',
    memo: 'memo',
    comment: 'comment',
    modelName: 'modelName',
    inch: 'inch',
  });

  const mergeExcel = (table) => {
    return new Promise((resolve) => {
      const tableRow = table.map((item, index) => ({
        frq: item.frq || '',
        NF: item.NF || '',
        Fv: item.Fv || '',
        Fh: item.Fh || '',
        Gv: item.Gv || '',
        Gh: item.Gh || '',
        passFail1: item.passFail,
        frequency2: emiData.vPeak[index]?.frequency || '',
        margin_v: emiData.vPeak[index]?.margin_v || '',
        passFail2: emiData.vPeak[index]?.passFail || '',
        frequency3: emiData.hPeak[index]?.frequency || '',
        margin_h: emiData.hPeak[index]?.margin_h || '',
        passFail3: emiData.hPeak[index]?.passFail || '',
        rejectionMessage: index === 0 ? rejectionMessage : '',
        comment: index === 0 ? masterInfoList?.comment : '',
        memo: index === 0 ? masterInfoList?.memo : '',
        modelName: index === 0 ? masterInfoList?.modelName : '',
        inch: index === 0 ? masterInfoList?.inch : '',
      }));

      resolve(tableRow);
    });
  };
  const handleNavigate = useCallback((value) => {
    navigate(value);
  }, []);
  const handleEmiImportModal = useCallback(() => {
    showModal(<EmiImportModal handleNavigate={handleNavigate} />);
  }, []);

  const handleXAxisCallback = useCallback((type) => {
    handleClickXAxis(type);
    setSelectedScale(type);
  }, []);

  const handleDebugging = useCallback(() => {
    if (currentMasterId !== null || searchParamsMasterId !== null) {
      navigate(`/debugging/emi3d?masterId=${currentMasterId ?? searchParamsMasterId}`);
    }
  }, [nfOriginalData, currentMasterId, searchParamsMasterId]);

  const handleExport = useCallback(async () => {
    handleImgDownload(graphRef);
    const mergedTableData = await mergeExcel(tableData);
    excelDownload(mergedTableData, 'emi_data', EXCEL_KEYS);
  }, [tableData]);

  const handleAiScan = useCallback(() => {
    if (currentMasterId !== null || searchParamsMasterId !== null) {
      setIsShowEmiProgressModal(true);
    }
  }, [currentMasterId, searchParamsMasterId]);

  const frqArr = useMemo(() => {
    return tableData.map((data) => Number(data.frq));
  }, [tableData]);

  // Ai SCAN Frq란에 입력한 숫자 기준으로 가장 가까운 frequency를 찾아 반환함
  const findClosestIndex = (arr, target) =>
    arr.reduce(
      (closestIndex, num, index, array) =>
        Math.abs(num - target) < Math.abs(array[closestIndex] - target) ? index : closestIndex,
      0
    );

  const handleFrqInput = useCallback(
    (e) => {
      const closestIndex = findClosestIndex(frqArr, Number(e.target.value));
      if (/^\d*\.?\d*$|^\.$/.test(e.target.value)) {
        const origin = rowSelection(closestIndex.toString());
        dispatch(
          setFrqValue({
            frq: Number(e.target.value),
            NF: Number(origin.NF),
            Fv: Number(origin.Fv),
            Fh: Number(origin.Fh),
            Gv: Number(origin.Gv),
            Gh: Number(origin.Gh),
            id: closestIndex,
          })
        );
      }
    },
    [rowSelection]
  );

  const handleKeyDown = useCallback(
    (e) => {
      const closestIndex = findClosestIndex(frqArr, Number(e.target.value));
      if (/^\d*\.?\d*$|^\.$/.test(e.target.value) && e.key === 'Enter') {
        const origin = rowSelection(closestIndex.toString());
        startJob(
          `setSideController-data`,
          () =>
            dispatch(
              setFrqValue({
                frq: Number(origin.frq),
                NF: Number(origin.NF),
                Fv: Number(origin.Fv),
                Fh: Number(origin.Fh),
                Gv: Number(origin.Gv),
                Gh: Number(origin.Gh),
                id: closestIndex,
              })
            ),
          100
        );
      }
    },
    [rowSelection]
  );

  return (
    <Container>
      <MenuBlock classes={{ MenuBlockStyle: BorderBottomStyle }}>
        <Text inner='Prediction Display' fontSize={28} fontWeight={600} color='#F5F5F5' />
      </MenuBlock>
      <MenuBlock classes={{ MenuBlockStyle: NFImportStyle }}>
        <Label>
          <Icon icon={NFImport} iconWidth={25} iconHeight={25} />
          <Text inner='NF Import' fontSize={22} fontWeight={600} color='#F5F5F5' />
        </Label>
        <FlexRow classes={{ Variant: FlexRowBottomStyle }}>
          <Button classes={{ Button: FullButtonStyle }} onClick={handleEmiImportModal}>
            <Text fontSize={18} fontWeight={600} inner='IMPORT File' />
          </Button>
        </FlexRow>
        <FlexRow>
          <ImportControllerDataMaxWidth>
            <Text fontSize={18} fontWeight={600} color='#fff' inner='Model Name' />
            <FlexBox>
              <AiScanInput type='text' required disabled value={masterInfo?.modelName ?? ''} />
            </FlexBox>
          </ImportControllerDataMaxWidth>
        </FlexRow>
        <FlexRow>
          <ImportControllerDataMaxWidth>
            <Text fontSize={18} fontWeight={600} color='#fff' inner='Inch' />
            <FlexBox>
              <AiScanInput type='text' required disabled value={masterInfo?.inch ?? ''} />
            </FlexBox>
          </ImportControllerDataMaxWidth>
        </FlexRow>
        <AiScanLabel>
          <ScanRunBox>
            <Icon icon={AIScan} iconWidth={27} iconHeight={26} />
            <Text inner='Ai SCAN' fontSize={24} fontWeight={600} color='#fff' />
          </ScanRunBox>
        </AiScanLabel>
        <FlexRow classes={{ Variant: FlexRowBottomStyle }}>
          <Button classes={{ Button: FullButtonStyle }} runStatus={isScanReady} onClick={handleAiScan}>
            <AIRunButtonStatus fontSize={18} fontWeight={600} inner='RUN' />
          </Button>
        </FlexRow>
        <FlexRow>
          <ImportControllerDataMaxWidth>
            <Text fontSize={18} fontWeight={600} color='#F1F1F1' inner='Freqeuncy' />
            <FlexBox>
              <AiScanInput
                type='text'
                disabled={!isScanReady}
                value={selectedTableRow?.frq ?? 0}
                onChange={handleFrqInput}
                onKeyDown={handleKeyDown}
              />
              <Text fontSize={16} fontWeight={400} color='#888888' inner='MHz' />
            </FlexBox>
          </ImportControllerDataMaxWidth>
        </FlexRow>
        <FlexRow>
          <ImportControllerDataMaxWidth>
            <Text fontSize={18} fontWeight={600} color='#F1F1F1' inner='Near-Field' />
            <FlexBox>
              <AiScanInput type='text' required disabled value={selectedTableRow?.NF ?? 0} />
              <Text fontSize={16} fontWeight={400} color='#888888' inner='dB(uV)' />
            </FlexBox>
          </ImportControllerDataMaxWidth>
        </FlexRow>
        <FlexRowMaxWidth>
          <ImportControllerDataMaxWidth>
            <Text fontSize={18} fontWeight={600} color='#F1F1F1' inner='Fv' />
            <AiScanInput type='text' required disabled value={selectedTableRow?.Fv ?? 0} />
          </ImportControllerDataMaxWidth>
          <ImportControllerDataMaxWidth>
            <Text fontSize={18} fontWeight={600} color='#F1F1F1' inner='Fh' />

            <AiScanInput type='text' required disabled value={selectedTableRow?.Fh ?? 0} />
          </ImportControllerDataMaxWidth>
        </FlexRowMaxWidth>
        <FlexRowMaxWidth>
          <ImportControllerDataMaxWidth>
            <Text fontSize={18} fontWeight={600} color='#F1F1F1' inner='Gv' />
            <AiScanInput type='text' required disabled value={selectedTableRow?.Gv ?? 0} />
          </ImportControllerDataMaxWidth>
          <ImportControllerDataMaxWidth>
            <Text fontSize={18} fontWeight={600} color='#F1F1F1' inner='Gh' />
            <AiScanInput type='text' required disabled value={selectedTableRow?.Gh ?? 0} />
          </ImportControllerDataMaxWidth>
        </FlexRowMaxWidth>
        <ScaleButtonArea>
          <ScaleButton
            type='Log'
            selectedScale={selectedScale}
            runStatus={isScanReady}
            onClick={() => handleXAxisCallback('Log')}
          >
            <Text inner='Log Scale' fontSize={18} fontWeight={500} color='#fff' />
          </ScaleButton>
          <ScaleButton
            type='Line'
            selectedScale={selectedScale}
            runStatus={isScanReady}
            onClick={() => handleXAxisCallback('Line')}
          >
            <Text inner='Linear' fontSize={18} fontWeight={500} color='#fff' />
          </ScaleButton>
        </ScaleButtonArea>
      </MenuBlock>
      <MenuBlock classes={{ MenuBlockStyle: BottomButtonStyle }}>
        <Button classes={{ Button: DebuggingBtnStyle }} runStatus={isScanReady} onClick={handleDebugging}>
          <Text inner='Debugging' fontSize={18} fontWeight={500} color='#fff' />
        </Button>
        <Button classes={{ Button: DebuggingBtnStyle }} runStatus={false} onClick={handleExport}>
          <Text inner='Export' fontSize={18} fontWeight={500} color='#fff' />
        </Button>
      </MenuBlock>
      {(currentMasterId || searchParamsMasterId) && isShowEmiProgressModal ? (
        <EmiProgressModal setShowProgressModal={setIsShowEmiProgressModal} />
      ) : null}
    </Container>
  );
};

EmiSideController.propTypes = {
  FORM_KEYS: PropTypes.shape({
    Frq: PropTypes.string,
    Level: PropTypes.string,
    NF: PropTypes.string,
    FV: PropTypes.string,
    FH: PropTypes.string,
    GV: PropTypes.string,
    GH: PropTypes.string,
    NFIMPORT: PropTypes.string,
  }),
  handleClickXAxis: PropTypes.func,
  /* emi 테이블 데이터 */
  tableData: PropTypes.array,
  /* 테이블 데이터 set 함수 */
  rowSelection: PropTypes.func,
  /* 차트 영역 ref */
  graphRef: PropTypes.any,
  /* emi 데이터 */
  emiData: PropTypes.object,
  /* emi masterInfoList 데이터 */
  masterInfoList: PropTypes.object,
  /* emi rejectionMessage 데이터 */
  rejectionMessage: PropTypes.string,
};

export default EmiSideController;
