import { forwardRef, createElement } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Skeleton from './Skeleton';

const getFontSize = ({ fontSize }) => `${fontSize || 16}px`;
const getFont = ({ font }) => `${font || 'body'}`; // TODO: apple-system 참고 문서
const getFontWeight = ({ fontWeight }) => `${fontWeight || 400}`;
const getColor = ({ color }) => `${color}`;
const getWidth = ({ width }) => width && `${width}px`;

const styles = css`
  display: block;
  font-size: ${getFontSize};
  font: -apple-system-${getFont};
  font-weight: ${getFontWeight};
  width: ${getWidth};
  color: ${getColor};
  font-family: 'Pretendard';
  ${({ classes }) => classes && classes.Text}
`;

const StyledText = styled(
  // eslint-disable-next-line react/prop-types
  forwardRef(({ isLoading = false, loader = {}, ...props }, ref) =>
    isLoading ? (
      <Skeleton variant={loader.variant || 'text'} width={loader.width} height={loader.height || 20} />
    ) : (
      // eslint-disable-next-line react/prop-types
      createElement(`${props.type || 'p'}`, { ...props, ref }, props.children)
    )
  )
)`
  ${styles}
`;

const Text = forwardRef(
  (
    {
      children,
      type,
      fontSize,
      font,
      fontWeight,
      width,
      color,
      isLoading,
      className,
      classes,
      inner,
      innerHtml,
      ...rest
    },
    ref
  ) => {
    if (inner || innerHtml) {
      return (
        <StyledText
          type={type}
          fontSize={fontSize}
          font={font}
          fontWeight={fontWeight}
          width={width}
          color={color}
          isLoading={isLoading}
          className={className}
          classes={classes}
          ref={ref}
          dangerouslySetInnerHTML={{
            __html: inner || innerHtml,
          }}
          {...rest}
        />
      );
    }

    return (
      <StyledText
        ref={ref}
        type={type}
        fontSize={fontSize}
        font={font}
        fontWeight={fontWeight}
        color={color}
        isLoading={isLoading}
        className={className}
        classes={classes}
        {...rest}
      >
        {children}
      </StyledText>
    );
  }
);

Text.propTypes = {
  /** Children */
  children: PropTypes.node,
  /** Text 타입 */
  type: PropTypes.string,
  /** Font 사이즈(px) */
  fontSize: PropTypes.number,
  /** Dynamic font(apple system font) */
  font: PropTypes.string,
  /** Font weight */
  fontWeight: PropTypes.number,
  /** Width */
  width: PropTypes.number,
  /** Font 색상 */
  color: PropTypes.string,
  /** Loading 화면 표시 */
  isLoading: PropTypes.bool,
  /** Loader 설정 */
  loader: PropTypes.shape({
    variant: PropTypes.oneOf(['text', 'rectangular', 'rounded', 'circular']).isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  /** ClassName */
  className: PropTypes.string,
  /** 커스텀 스타일 오브젝트 */
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  /** Text 컴포넌트에 직접 넣을 inner */
  inner: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),

  /** Text 컴포넌트에 직접 넣을 innerHtml */
  innerHtml: PropTypes.string,
};

export default Text;
