import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import i18n from '@i18n';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import styled, { css } from 'styled-components';

import { updateEmiCommandLineParameterAPI } from '@action/emi/emiAction';
import { FindPwBg } from '@images/index';
import { Div, Input, Modal, Button, Img, Text, ModalContext } from '@components';
import ErrorModal from '@SAM/components/Modal/ErrorModal';

const LoginContainer = styled(Div)`
  display: flex;
  width: 850px;
  height: 480px;
`;

const ImgContainer = styled(Div)`
  position: relative;

  color: #fff;
  background: linear-gradient(to right, #4a67fb, #24d8ed);
`;

const LoginText = styled(Text)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  font-weight: 600;
`;

const LoginImg = styled(Img)`
  width: 300px;
  height: 400px;
  opacity: 0.15;
`;
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #262626;
  row-gap: 10px;
`;

const LoginButton = styled(Button)`
  background-color: #20b9fd;
  height: 40px;
  text-decoration: none;
  border-radius: 5px;
  padding: 16px 0;
  color: #fff;

  &:hover {
    text-decoration: none;
    color: #fff;
  }
`;
const BGColor = css`
  background-color: #262626;
  flex: 1;
  padding: 0;
`;

const InputFormStyle = css`
  background-color: #363636;
  color: #c1c1c1;
  height: 40px;
  border-radius: 5px;
  border: 0px;
`;

const FORM_KEYS = Object.freeze({
  VARIATION: 'variation',
  H_UPPER: 'h_reject_upper',
  H_LOWER: 'h_reject_lower',
  V_UPPER: 'v_reject_upper',
  V_LOWER: 'v_reject_lower',
});

const VALIDATION_REGEXES = {
  VARIATION: /^\d+(\.\d+)?$/,
  H_UPPER: /^\d+(\.\d+)?$/,
  H_LOWER: /^\d+(\.\d+)?$/,
  V_UPPER: /^\d+(\.\d+)?$/,
  V_LOWER: /^\d+(\.\d+)?$/,
};

const schema = yup.object().shape({
  [FORM_KEYS.VARIATION]: yup
    .string()
    .matches(VALIDATION_REGEXES.VARIATION, i18n.t('VALIDATION.ONLY_NUMBER'))
    .required(i18n.t('VALIDATION.REQUIRED')),
  [FORM_KEYS.H_UPPER]: yup
    .string()
    .matches(VALIDATION_REGEXES.H_UPPER, i18n.t('VALIDATION.ONLY_NUMBER'))
    .required(i18n.t('VALIDATION.REQUIRED')),
  [FORM_KEYS.H_LOWER]: yup
    .string()
    .matches(VALIDATION_REGEXES.H_LOWER, i18n.t('VALIDATION.ONLY_NUMBER'))
    .required(i18n.t('VALIDATION.REQUIRED')),
  [FORM_KEYS.V_UPPER]: yup
    .string()
    .matches(VALIDATION_REGEXES.V_UPPER, i18n.t('VALIDATION.ONLY_NUMBER'))
    .required(i18n.t('VALIDATION.REQUIRED')),
  [FORM_KEYS.V_LOWER]: yup
    .string()
    .matches(VALIDATION_REGEXES.V_LOWER, i18n.t('VALIDATION.ONLY_NUMBER'))
    .required(i18n.t('VALIDATION.REQUIRED')),
});

const ModuleParamForm = () => {
  const dispatch = useDispatch();
  const moduleParameter = useSelector((state) => state.emi.commandLineParam);
  const { showModal, closeModal } = useContext(ModalContext);

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      [FORM_KEYS.VARIATION]: moduleParameter.variation,
      [FORM_KEYS.H_UPPER]: moduleParameter.h_reject_upper,
      [FORM_KEYS.H_LOWER]: moduleParameter.h_reject_lower,
      [FORM_KEYS.V_UPPER]: moduleParameter.v_reject_upper,
      [FORM_KEYS.V_LOWER]: moduleParameter.v_reject_lower,
    },
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;
  const { t } = useTranslation();

  const handleModuleParam = (data) => {
    const param = {
      variation: Number(data.variation) ?? 0,
      h_reject_upper: Number(data.h_reject_upper) ?? 0,
      h_reject_lower: Number(data.h_reject_lower) ?? 0,
      v_reject_upper: Number(data.v_reject_upper) ?? 0,
      v_reject_lower: Number(data.v_reject_lower) ?? 0,
    };
    updateEmiCommandLineParameterAPI(dispatch, param, closeModal, (resResult) => {
      if (resResult?.status === false) {
        showModal(<ErrorModal errorMessage={t(resResult?.message) ?? ''} />);
      } else {
        closeModal();
      }
    });
  };

  return (
    <FormProvider {...methods}>
      <FormContainer>
        <Input
          name={FORM_KEYS.VARIATION}
          classes={{ Variant: InputFormStyle }}
          type='text'
          label={FORM_KEYS.VARIATION}
          errorMessage={errors[FORM_KEYS.VARIATION]?.message}
          placeholder='variation'
          required
        />
        <Input
          name={FORM_KEYS.H_UPPER}
          classes={{ Variant: InputFormStyle }}
          type='text'
          label={FORM_KEYS.H_UPPER}
          errorMessage={errors[FORM_KEYS.VARIATION]?.message}
          placeholder='h_reject_upper'
          required
        />
        <Input
          name={FORM_KEYS.H_LOWER}
          classes={{ Variant: InputFormStyle }}
          type='text'
          label={FORM_KEYS.H_LOWER}
          errorMessage={errors[FORM_KEYS.VARIATION]?.message}
          placeholder='h_reject_lower'
          required
        />

        <Input
          name={FORM_KEYS.V_UPPER}
          classes={{ Variant: InputFormStyle }}
          type='text'
          label={FORM_KEYS.V_UPPER}
          errorMessage={errors[FORM_KEYS.VARIATION]?.message}
          placeholder='v_reject_upper'
          required
        />
        <Input
          name={FORM_KEYS.V_LOWER}
          classes={{ Variant: InputFormStyle }}
          type='text'
          label={FORM_KEYS.V_LOWER}
          errorMessage={errors[FORM_KEYS.VARIATION]?.message}
          placeholder='v_reject_lower'
          required
        />
        <LoginButton onClick={handleSubmit(handleModuleParam)}>{t('COMMON.EDIT_EMI_MODULE_BUTTON')}</LoginButton>
      </FormContainer>
    </FormProvider>
  );
};

const ModuleParamModal = () => {
  return (
    <LoginContainer>
      <ImgContainer>
        <LoginText inner='MODULE PARAMETER' />
        <LoginImg src={FindPwBg} />
      </ImgContainer>
      <Modal classes={{ Container: BGColor, Header: BGColor }} body={<ModuleParamForm />} />
    </LoginContainer>
  );
};

export default ModuleParamModal;
