// eslint-disable-next-line import/no-extraneous-dependencies
import ExcelJS from 'exceljs';
// eslint-disable-next-line import/no-extraneous-dependencies
import { saveAs } from 'file-saver';

const appOS = (() => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const targetAppOS = userAgent.indexOf('android') === -1 ? 'IOS' : 'AOS';
  // FIXME: PC/Mobile 분기필요
  return targetAppOS;
})();

// wait 함수 lint 예외처리 필요할 듯
// eslint-disable-next-line
const wait = (time) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
};

/* eslint-disable  */
// params to getParameter
const createQueryString = (object) => {
  const parts = [];
  for (const key of Object.getOwnPropertyNames(object)) {
    if (object[key] !== null && object[key] !== undefined && object[key] !== '') {
      parts.push(`${key}=${encodeURIComponent(object[key])}`);
    }
  }
  return parts.join('&');
};

/** NOTE: UTF-8 방식 byte 구하는 함수 */
const LINE_FEED = 10; // '\n'

const getByteLength = (decimal) => {
  // eslint-disable-next-line
  return decimal >> 7 || LINE_FEED === decimal ? 2 : 1;
};

const getByte = (str) => {
  return str
    .split('')
    .map((s) => s.charCodeAt(0))
    .reduce((prev, unicodeDecimalValue) => prev + getByteLength(unicodeDecimalValue), 0);
};

// NOTE: 지정해준 byte number 만큼만 출력해주는 함수
const getLimitedByteText = (inputText, maxByte) => {
  const characters = inputText.split('');
  let validText = '';
  let totalByte = 0;

  for (let i = 0; i < characters.length; i += 1) {
    const character = characters[i];
    const decimal = character.charCodeAt(0);
    const byte = getByteLength(decimal); // NOTE: 글자 한 개가 몇 바이트 길이인지 구해주기

    // NOTE: 현재까지의 바이트 길이와 더해 최대 바이트 길이를 넘지 않으면
    if (totalByte + byte <= maxByte) {
      totalByte += byte; // NOTE: 바이트 길이 값을 더해 현재까지의 총 바이트 길이 값을 구함
      validText += character; // NOTE: 글자를 더해 현재까지의 총 문자열 값을 구함
    } else {
      // NOTE: 최대 바이트 길이를 넘으면
      break; // NOTE: for 루프 종료
    }
  }

  return validText;
};

import { BASE_URL } from '@TAxiosConfig';
import html2canvas from 'html2canvas';

const loadImageUrl = (FILE_KEY) => {
  return `${BASE_URL}/api/1.0/app/common/attachment/loadImage/${FILE_KEY}`;
};

const DATE_CONVERTER_CONDITIONS = Object.freeze({
  DASH: 'dash',
  DOT: 'dot',
  KO: 'ko',
});

/**
 * Date "YYYY-MM-DD"
 *
 * @example
 *   import { dateConverter } from '@utils';
 *
 *   const { method, conditions } = dateConverter;
 *   method(new Date('2021-09-01'), conditions.DOT); // "2021.09.01"
 *
 * @param {Date} date - Date
 * @param {'dash' | 'dot' | 'ko' | string} [separator] - 구분자로 기본 값은 'dash'
 * @returns {string} YYYY-MM-DD
 */
const convertDateToString = (date, separator = DATE_CONVERTER_CONDITIONS.DASH) => {
  // eslint-disable-next-line
  if (!date || !(date instanceof Date)) return console.error('Invalid Date');

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const monthString = month.toString().padStart(2, '0');
  const day = date.getDate();
  const dayString = day.toString().padStart(2, '0');

  switch (separator) {
    case 'dash':
      return `${year}-${monthString}-${dayString}`;
    case 'dot':
      return `${year}.${monthString}.${dayString}`;
    case 'ko':
      return `${year}년 ${monthString}월 ${dayString}일`;
    default:
      return `${year}${separator}${monthString}${separator}${dayString}`;
  }
};

const dateConverter = Object.freeze({
  conditions: DATE_CONVERTER_CONDITIONS,
  method: convertDateToString,
});

const DATE_CALCULATOR_CONTIDIONS = Object.freeze({
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  THIS_WEEK: 'thisWeek',
  LAST_WEEK: 'lastWeek',
  THIS_MONTH: 'thisMonth',
  LAST_MONTH: 'lastMonth',
  RECENT_7_DAYS: 'recent7Days',
  RECENT_30_DAYS: 'recent30Days',
});

/**
 * 조건에 따른 날짜 범위를 계산해주는 함수
 *
 * @example
 *   import { dateCalculator } from '@utils';
 *
 *   const { method, conditions } = dateCalculator;
 *   method(new Date(), conditions.THIS_MONTH);
 *   // 오늘이 2024년 4월 어느 날짜인 경우
 *   // { startDate: new Date('2024-04-01'), endDate: new Date('2024-04-30') }
 *
 * @param {Date} date - 계산의 기준이 되는 날짜
 * @param {'today'
 *   | 'yesterday'
 *   | 'thisWeek'
 *   | 'lastWeek'
 *   | 'thisMonth'
 *   | 'lastMonth'
 *   | 'recent7Days'
 *   | 'recent30Days'} condition
 *   - 어느 범위의 날짜를 계산할지 정해주는 조건 (conditions 객체 사용)
 *
 * @returns {{ startDate: Date; endDate: Date }} 계산된 날짜 범위의 시작일과 종료일을 반환
 */
const dateCalculatorMethod = (date, condition) => {
  const startDate = new Date(date);
  const endDate = new Date(date);

  switch (condition) {
    case DATE_CALCULATOR_CONTIDIONS.TODAY:
      break;
    case DATE_CALCULATOR_CONTIDIONS.YESTERDAY:
      startDate.setDate(startDate.getDate() - 1);
      endDate.setDate(endDate.getDate() - 1);
      break;
    case DATE_CALCULATOR_CONTIDIONS.THIS_WEEK:
      startDate.setDate(startDate.getDate() - startDate.getDay());
      endDate.setDate(endDate.getDate() + (6 - endDate.getDay()));
      break;
    case DATE_CALCULATOR_CONTIDIONS.LAST_WEEK:
      startDate.setDate(startDate.getDate() - startDate.getDay() - 7);
      endDate.setDate(endDate.getDate() + (6 - endDate.getDay() - 7));
      break;
    case DATE_CALCULATOR_CONTIDIONS.THIS_MONTH:
      startDate.setDate(1);
      endDate.setDate(new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0).getDate());
      break;
    case DATE_CALCULATOR_CONTIDIONS.LAST_MONTH:
      startDate.setDate(1);
      startDate.setMonth(startDate.getMonth() - 1);
      endDate.setDate(0);
      break;
    case DATE_CALCULATOR_CONTIDIONS.RECENT_7_DAYS:
      startDate.setDate(startDate.getDate() - 6);
      break;
    case DATE_CALCULATOR_CONTIDIONS.RECENT_30_DAYS:
      startDate.setDate(startDate.getDate() - 29);
      break;
    default:
      break;
  }

  return { startDate, endDate };
};

const dateCalculator = Object.freeze({
  conditions: DATE_CALCULATOR_CONTIDIONS,
  method: dateCalculatorMethod,
});

/** NOTE: Debounce 로직 */

const JOB_HASH = {};

export const stopJob = (jobId) => {
  if (JOB_HASH[jobId]) {
    clearTimeout(JOB_HASH[jobId]);
    delete JOB_HASH[jobId];
  }
};

const startJob = (jobId, func, delay = 100) => {
  stopJob(jobId);
  const jobKey = setTimeout(func, delay);
  JOB_HASH[jobId] = jobKey;
};

const findMaxNv = (data) => {
  const NV_LENGTH = 145;
  const nvMaxValues = Array(NV_LENGTH).fill(-Infinity);

  data.forEach((entry) => {
    for (let i = 0; i < NV_LENGTH; i++) {
      const nvKey = `nv${i + 1}`;
      const nvValue = parseFloat(entry[nvKey]);

      if (nvValue > nvMaxValues[i]) {
        nvMaxValues[i] = nvValue;
      }
    }
  });

  return nvMaxValues;
};

/**
 * @example
 *   excelDownload(data: jobLogList, tableName: FIELD_NAME, tableKey: TABLE_KEYS )
 *
 * @param {array} strings.data - Api 통신으로 받아오는 origin 데이터
 * @param {string} strings.tableName - 엑셀 출력할 테이블 이름
 * @param {string} strings.tableKey - 엑셀에 출력할 테이블 column 이름
 * @returns {string} 엑셀 다운로드
 */
const excelDownload = async (data, tableName, tableKey) => {
  try {
    // NOTE: workbook 생성
    const wb = new ExcelJS.Workbook();
    // NOTE: sheet 생성
    const sheet = wb.addWorksheet(tableName);

    const headers = Object.values(tableKey);

    // NOTE: 상단 헤더(TH) 추가
    const headerRow = sheet.addRow(headers);
    // NOTE: 헤더의 높이값 지정
    headerRow.height = 30.75;

    data.forEach((item, rowIndex) => {
      const rowDatas = headers.map((key) => (key === 'NO' ? data.length - rowIndex : item[key]));
      return sheet.addRow(rowDatas);
    });

    // NOTE: 파일 생성
    const fileData = await wb.xlsx.writeBuffer();
    // NOTE: writeBuffer는 프로미스를 반환하므로 async-await을 사용해야 한다.
    const blob = new Blob([fileData], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, `${tableName}`);
  } catch (error) {
    console.log('excel downLoad error', error);
  }
};

/**
 * @example
 *   imgDownload(ref: ref)
 *
 * @param {func} func - 이미지 원하는 영역 ref
 */
const handleImgDownload = async (ref) => {
  if (!ref.current) return;

  try {
    const container = ref.current;
    const canvas = await html2canvas(container, { scale: 2, backgroundColor: null });
    canvas.toBlob((blob) => {
      if (blob !== null) {
        saveAs(blob, 'graph.png');
      }
    });
  } catch (error) {
    console.log('image downLoad error', error);
  }
};

/**
 * Data를 localStorage에 저장
 *
 * @param {string} key
 * @param {any} value
 */
const setLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

/**
 * LocalStorage에서 key에 해당하는 값 가져오기
 *
 * @param {string} key
 * @returns {string} Key에 해당하는 localStorage 값
 */
const getLocalStorage = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

export {
  wait,
  appOS,
  createQueryString,
  getByte,
  getLimitedByteText,
  loadImageUrl,
  dateCalculatorMethod,
  dateCalculator,
  convertDateToString,
  startJob,
  findMaxNv,
  excelDownload,
  handleImgDownload,
  setLocalStorage,
  getLocalStorage,
};
