import { useCallback, useContext, useMemo } from 'react';

import styled from 'styled-components';

import Landing from '@SAM/components/Landing/Landing';
import { Div } from '@components/index';
import LandingContent from '@SAM/components/Landing/LandingContent';
import GlobalContext from '@components/Context/GlobalProvider';
import CustomSwiper from '@components/DataDisplay/CustomSwiper';
import { useTranslation } from 'react-i18next';
import { emiImg, rfImg, bikeImg, emiVideo, rfVideo, bikeVideo } from '../../../assets/videos';

const HomeContainer = styled(Div)`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Home = () => {
  const { currentIndex, handleCurrentIndex } = useContext(GlobalContext);
  const { t } = useTranslation();

  const onSlideChange = useCallback(
    (swiper) => {
      handleCurrentIndex(swiper.activeIndex);
    },
    [handleCurrentIndex]
  );

  const landingData = useMemo(() => {
    return [
      { title: t('HOME.001'), currentIndex, img: emiImg, video: emiVideo },
      { title: t('HOME.002'), img: rfImg, video: rfVideo },
      { title: t('HOME.003'), currentIndex, img: bikeImg, video: bikeVideo },
    ];
  }, [currentIndex]);

  return (
    <HomeContainer>
      <CustomSwiper onSlideChange={onSlideChange}>
        {landingData.map((slide) => {
          return <Landing key={slide.title} title={slide.title} img={slide.img} video={slide.video} />;
        })}
      </CustomSwiper>
      <LandingContent currentIndex={currentIndex} />
    </HomeContainer>
  );
};

Home.propTypes = {};

export default Home;
