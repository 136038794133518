import { useLocation, useNavigate } from 'react-router-dom';
import { memo, useCallback, useContext, useEffect, useState } from 'react';

import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import GlobalContext from '@components/Context/GlobalProvider';
import routerStructure from '@router/routerStructure';
import { Img } from '@components/Atoms/Atoms';
import { Burger, LOGO, RfLOGO, BikeLOGO } from '@SAM/images';
import SlideMenu from './SlideMenu';

const hoverColor = css`
  background-image: linear-gradient(to right, #e35cad, #ce99c8, #8fa5dd, #aed5f3);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
`;

const Container = styled.div`
  --container-height: 120px;
  --container-padding: 40px 60px 0;
  --container-grid-column-frs: 1fr 8fr 1fr;
  --menu-container-padding: 30px 40px;

  z-index: 99999;
  width: 100%;
  height: var(--container-height);
  position: fixed;
  left: 0;
  top: 0;
  padding: var(--container-padding);
  display: grid;
  grid-template-columns: var(--container-grid-column-frs);
  align-items: center;
  background-color: #000000;
  transition: background-color 0.2s ease-in-out;

  ${({ classes }) => classes && classes.AppBar};
`;

const AppBarLogo = styled(Img)`
  cursor: pointer;
  justify-self: start;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
`;

const MenuContainer = styled.div`
  font-size: 20px;
  margin: 0 350px;
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  justify-items: center;
`;

const Menu = styled.p`
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0;
  transition: text-shadow 0.3s ease-in-out;
  user-select: none;
  cursor: pointer;
  ${({ isSelected }) => isSelected && hoverColor};

  &:hover {
    ${hoverColor}
  }
`;

const HeaderRightControls = styled.div`
  display: flex;
  color: #fff;
  opacity: 0.5;
  justify-content: end;
  flex-grow: 0;
  flex-basis: auto;
  flex-shrink: 1;
  &:hover {
    opacity: 1;
  }
`;

const DropDownContainer = styled.div`
  width: 100%;
  padding: 0 60px;
  position: absolute;
  top: var(--container-height);
  left: 0;
  display: ${({ hover }) => (hover ? 'grid' : 'none')};
  grid-template-columns: var(--container-grid-column-frs);
  background-color: rgba(0, 0, 0, 0.8);
`;

const SubMenuContainer = styled.div`
  margin: 0 350px;
  grid-column: 2 / 3;
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  justify-items: center;
`;

const SectionContainer = styled.div`
  text-align: center;
`;

const SubMenu = styled.p`
  padding: 10px 0;
  cursor: pointer;
  text-transform: uppercase;
  color: white;
  ${({ isSelected }) => isSelected && hoverColor};

  &:hover {
    ${hoverColor}
  }
`;
const BurgerMenu = styled(Img)`
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  -webkit-user-drag: none;
  -moz-user-drag: none;
  -ms-user-drag: none;
  -o-user-drag: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

const HEADER_KEYS = Object.keys(routerStructure);

const AppBar = memo(({ classes }) => {
  const { currentIndex } = useContext(GlobalContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuHover, setIsMenuHover] = useState(false);
  const [isOpenSlide, setIsOpenSlide] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [_, selectedMenuPath, selectedSubMenuPath] = location.pathname.split('/');

  const handleMenuHover = () => {
    setIsMenuHover(true);
  };

  const handleMuenuLeave = () => {
    setIsMenuHover(false);
  };

  const handleSlide = useCallback((value) => {
    setIsOpenSlide(value);
  }, []);

  const handleNavigate = (pathname) => {
    navigate(`${pathname}`);
  };

  const getLogo = () => {
    switch (currentIndex) {
      case 0:
        return LOGO;
      case 1:
        return RfLOGO;
      case 2:
        return BikeLOGO;
      default:
        return LOGO;
    }
  };

  useEffect(() => {
    const checkScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', checkScroll);

    return () => {
      window.removeEventListener('scroll', checkScroll);
    };
  }, []);

  return (
    <Container classes={classes} onMouseLeave={handleMuenuLeave} isScrolled={isScrolled > 0}>
      <AppBarLogo src={getLogo()} width={108} height={48} onClick={() => handleNavigate('/')} />
      <MenuContainer>
        {HEADER_KEYS.map((key) => (
          <Menu
            key={key}
            isSelected={selectedMenuPath === routerStructure[key].pathname}
            onMouseEnter={handleMenuHover}
            onClick={() => key !== 'solution' && handleNavigate(`/${routerStructure[key].pathname}`)}
          >
            {routerStructure[key].name}
          </Menu>
        ))}
      </MenuContainer>
      <HeaderRightControls>
        <BurgerMenu src={Burger} onClick={() => handleSlide(true)} />
      </HeaderRightControls>
      <SlideMenu isOpen={isOpenSlide} handleSlide={handleSlide} />
      <DropDownContainer hover={isMenuHover}>
        <SubMenuContainer>
          {HEADER_KEYS.map((key) => (
            <SectionContainer key={key}>
              {routerStructure[key].routes.map(({ name, pathname }) => (
                <SubMenu
                  key={pathname}
                  isSelected={selectedSubMenuPath === pathname}
                  onClick={() => handleNavigate(`/${routerStructure[key].pathname}/${pathname}`)}
                >
                  {name}
                </SubMenu>
              ))}
            </SectionContainer>
          ))}
        </SubMenuContainer>
      </DropDownContainer>
    </Container>
  );
});

AppBar.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

AppBar.displayName = 'AppBar';

export default AppBar;
