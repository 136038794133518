import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Div from '@components/Atoms/Div';

const width = ({ iconWidth }) => `${iconWidth || 17}px`;
const height = ({ iconHeight }) => `${iconHeight || 17}px`;
const classesIcon = ({ classes }) => classes && classes.Icon;
const opacity = ({ disabled }) => (disabled ? '.333333;' : '');
const background = ({ isLoading, icon, leftIcon, rightIcon }) =>
  !isLoading && `background-image: url(${icon || leftIcon || rightIcon});`;
const hasCursor = ({ pointer, disabled }) => pointer && !disabled && `cursor:pointer`;

const StyledIcon = styled(Div)`
  display: inline-block;
  vertical-align: middle;
  border: 0;
  padding: 0px;
  font-size: 0;
  width: ${width};
  height: ${height};
  background: no-repeat center/cover;
  ${background}
  opacity: ${opacity};
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  ${hasCursor}
  ${classesIcon}
`;

const Icon = forwardRef(({ isLoading = false, ...props }, ref) => (
  <StyledIcon ref={ref} isLoading={isLoading} {...props} />
));

Icon.propTypes = {
  /** 이미지 아이콘 width */
  iconWidth: PropTypes.number,
  /** 이미지 아이콘 height */
  iconHeight: PropTypes.number,
  /** 버튼 커스텀 스타일 오브젝트 */
  disabled: PropTypes.bool,
  /** Loading 화면 표시 */
  isLoading: PropTypes.bool,
};

export default Icon;
