import { useCallback, useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import PropTypes from 'prop-types';

import { getEmiCommandLineParameterAPI } from '@action/emi/emiAction';
import { BoldX, SlideBg } from '@SAM/images';
import { doLogoutAPI } from '@action/user/loginAction';
import { Div, Img, ModalContext, LanguageContext } from '@components';
import ErrorModal from '@SAM/components/Modal/ErrorModal';
import LoginModal from '../../modules/SAM/components/Modal/LoginModal';
import JoinModal from '../../modules/SAM/components/Modal/JoinModal';
import FindPasswordModal from '../../modules/SAM/components/Modal/FindPasswordModal';
import ModuleParamModal from '../../modules/SAM/components/Modal/ModuleParamModal';

const MenuContainer = styled(Div)`
  position: fixed;
  top: 0;
  right: ${({ isOpen }) => (isOpen ? '0' : '-510px')};
  width: 510px;
  height: 100%;
  background-color: #333;
  color: white;
  transition: right 0.3s ease;
  z-index: 2;
  background: linear-gradient(to bottom, #24d8ed 0%, #4a67fb 100%);
`;

const MenuContents = styled(Div)`
  display: flex;
  flex-direction: column;
  background-image: url(${SlideBg});
  height: 100%;
  opacity: 0.7;
  justify-content: space-between;
  padding-left: 54px;
`;

const TopButtonContainer = styled(Div)`
  display: flex;
  justify-content: space-between;
  padding: 34px 60px;
  padding-left: 0px;
  margin-bottom: 110px;
`;

const Language = styled(Div)`
  width: 70px;
  height: 100%;
  border: 1px solid #fff;
  border-radius: 100px;
  font-weight: 700;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  text-transform: uppercase;
`;

const CloseBtn = styled(Img)`
  opacity: 0.5;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;

const NavigationBtnContainer = styled(Div)`
  display: flex;
  flex-direction: column;
  gap: 33px;
`;

const NavigationBtn = styled(Div)`
  font-size: 35px;
  font-weight: 200;
  &:hover {
    cursor: pointer;
    font-weight: 800;
  }
`;

const NavigationSubBox = styled(Div)`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const NavigationSubBtn = styled(Div)`
  font-size: 25px;
  font-weight: 200;
  &:hover {
    /* color: red; */
    cursor: pointer;
    font-weight: 800;
  }
`;

const UserBtn = styled(Div)`
  font-size: 20px;
  font-weight: 200;
  &:hover {
    cursor: pointer;
    font-weight: 800;
  }
`;

const DimmedOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  z-index: 1;
`;

const Top = styled(Div)`
  display: flex;
  flex-direction: column;
`;

const Bottom = styled(Div)`
  display: flex;
  margin-bottom: 100px;
  row-gap: 10px;
  column-gap: 34px;
  flex-wrap: wrap;
`;

const SlideMenu = ({ isOpen, handleSlide }) => {
  const dispatch = useDispatch();
  const isLogined = useSelector((state) => state.user.isLogined);

  const navigate = useNavigate();

  const methods = useForm();
  const { handleSubmit } = methods;

  const { showModal, closeModal } = useContext(ModalContext);
  const { language, setCurrentLanguage } = useContext(LanguageContext);
  const {
    t,
    i18n: { changeLanguage },
  } = useTranslation();

  const handleNavigate = useCallback((path) => {
    path && navigate(path);
    handleSlide(false);
  }, []);

  const handleLanguageClick = () => {
    if (language === 'ko') {
      setCurrentLanguage('en');
      changeLanguage('en');
      return;
    }
    setCurrentLanguage('ko');
    changeLanguage('ko');
  };

  const handleLoginClick = () => {
    showModal(<LoginModal />);
  };

  const handleJoinClick = () => {
    showModal(<JoinModal />);
  };

  const handleFindPasswordClick = () => {
    showModal(<FindPasswordModal />);
  };

  const handleModuleParamClick = () => {
    showModal(<ModuleParamModal />);
  };

  const handleLogout = useCallback(() => {
    doLogoutAPI(dispatch, (resResult) => {
      if (resResult?.status === false) {
        showModal(<ErrorModal errorMessage={t(resResult?.message) ?? ''} />);
      } else {
        closeModal();
      }
    });
    handleNavigate('/');
  }, []);

  useEffect(() => {
    getEmiCommandLineParameterAPI(dispatch, {}, (resResult) => {
      if (resResult?.status === false) {
        showModal(<ErrorModal errorMessage={t(resResult?.message) ?? ''} />);
      } else {
        closeModal();
      }
    });
  }, []);

  return (
    <FormProvider {...methods}>
      <DimmedOverlay isOpen={isOpen} onClick={() => handleSlide(false)} />
      <MenuContainer isOpen={isOpen}>
        <MenuContents>
          <Top>
            <TopButtonContainer>
              <Language onClick={handleLanguageClick}>{language === 'ko' ? 'kr' : language}</Language>
              <CloseBtn src={BoldX} width={32} height={32} onClick={() => handleSlide(false)} />
            </TopButtonContainer>
            <NavigationBtnContainer>
              <NavigationBtn onClick={() => handleNavigate('/')}>HOME</NavigationBtn>
              <NavigationBtn>SOLUTION</NavigationBtn>
              <NavigationSubBox>
                <NavigationSubBtn onClick={() => handleNavigate('/solution/emi')}>- EMI</NavigationSubBtn>
                <NavigationSubBtn onClick={() => handleNavigate('/solution/rf')}>- RF</NavigationSubBtn>
                <NavigationSubBtn onClick={() => handleNavigate('/solution/bike')}>- BIKE</NavigationSubBtn>
              </NavigationSubBox>
              <NavigationBtn onClick={() => handleNavigate('/debugging/emi3d')}>DEBUGGING</NavigationBtn>
              <NavigationBtn onClick={() => handleNavigate('/datamanagement')}>DATA MANAGEMENT</NavigationBtn>
            </NavigationBtnContainer>
          </Top>
          <Bottom>
            {isLogined ? (
              <UserBtn onClick={handleSubmit(handleLogout)}>LOGOUT</UserBtn>
            ) : (
              <UserBtn onClick={handleLoginClick}>LOGIN</UserBtn>
            )}

            <UserBtn onClick={handleJoinClick}>REGISTER</UserBtn>
            <UserBtn onClick={handleFindPasswordClick}>FIND PASSWORD</UserBtn>
            <UserBtn onClick={handleModuleParamClick}>MODULE PARAMETER</UserBtn>
          </Bottom>
        </MenuContents>
      </MenuContainer>
    </FormProvider>
  );
};

SlideMenu.propTypes = {
  /** Slide open status */
  isOpen: PropTypes.bool,
  /** Slide close */
  handleSlide: PropTypes.func,
};

export default SlideMenu;
