import { createGlobalStyle } from 'styled-components';

const GlobalFont = createGlobalStyle`
    @font-face {
        font-family: 'Pretendard';
        src: url('/assets/fonts/Pretendart-Light.woff') format('woff');
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: 'Pretendard';
        src: url('/assets/fonts/Pretendart-Regular.woff') format('woff');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'Pretendard';
        src: url('/assets/fonts/Pretendart-Medium.woff') format('woff');
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: 'Pretendard';
        src: url('/assets/fonts/Pretendart-SemiBold.woff') format('woff');
        font-weight: 600;
        font-style: normal;
    }
    @font-face {
        font-family: 'Pretendard';
        src: url('/assets/fonts/Pretendart-Bold.woff') format('woff');
        font-weight: 700;
        font-style: normal;
    }
`;

export default GlobalFont;
