import { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { Div, Text, Icon, Button, ModalContext } from '@components/index';
import { NFImport } from '@SAM/images';
import EmiImportModal from './EmiImportModal';

const Container = styled(Div)`
  display: flex;
  height: calc(100vh - 100px);
  flex-direction: column;
  padding: 30px 30px 0 30px;
  background-color: #1d1d1b;
  width: 330px;
  border: 1px solid #444444;
  border-top: none;
  border-bottom: none;
`;

const FullButtonStyle = css`
  text-decoration: none;
  color: #fff;
  background-color: #366eff;
  height: 45px;
  border-radius: 100px;
  width: 100%;
  margin: 0;
  background-color: ${({ runStatus }) => typeof runStatus !== 'undefined' && !runStatus && '#A1A1A1'};
  :hover {
    text-decoration: none;
    color: #fff;
  }
`;

const MenuBlock = styled(Div)`
  ${({ classes }) => classes?.MenuBlockStyle && classes.MenuBlockStyle}
`;

const BorderBottomStyle = css`
  border-bottom: 1px solid #494949;
  padding-bottom: 20px;
`;

const NFImportStyle = css`
  border-bottom: 1px solid #494949;
  border-top: 1px solid #494949;
`;

const BottomButtonStyle = css`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  :hover {
    text-decoration: none;
    color: #fff;
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 15px;
  padding-top: 20px;
  padding-bottom: 10px;
  cursor: pointer;
`;

const FlexRow = styled(Div)`
  display: flex;
  position: relative;
  gap: 10px;
  margin-bottom: 10px;
  ${({ classes }) => classes && classes.Variant && classes.Variant}
`;
const FlexRowBottomStyle = css`
  margin-bottom: 30px;
`;

const DebuggingBtnStyle = css`
  background-color: #414141;
  margin: 0;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  :hover {
    text-decoration: none;
    color: #fff;
  }
`;

const DebuggingSideController = ({ handleClickedData }) => {
  const navigate = useNavigate();
  const currentFormData = useFormContext();
  const { showModal } = useContext(ModalContext);
  const nfMaxData = useSelector((state) => state.emi.nfMaxData);

  const handleBack = useCallback(() => {
    navigate(-1);
  }, []);

  const handleEmiImportModal = useCallback(() => {
    const test = currentFormData;
    showModal(<EmiImportModal data={test} type='2D' />);
  }, [currentFormData]);

  const handleMaxLevelClick = useCallback(() => {
    handleClickedData && handleClickedData(nfMaxData);
  }, [nfMaxData]);

  return (
    <Container>
      <MenuBlock classes={{ MenuBlockStyle: BorderBottomStyle }}>
        <Text inner='Prediction Display' fontSize={28} fontWeight={600} color='#F5F5F5' />
      </MenuBlock>
      <MenuBlock classes={{ MenuBlockStyle: NFImportStyle }}>
        <Label>
          <Icon icon={NFImport} iconWidth={25} iconHeight={25} />
          <Text inner='NF Import' fontSize={22} fontWeight={600} color='#fff' />
        </Label>
        <FlexRow classes={{ Variant: FlexRowBottomStyle }}>
          <Button classes={{ Button: FullButtonStyle }} onClick={handleEmiImportModal}>
            <Text fontSize={18} fontWeight={600} inner='IMPORT File' />
          </Button>
        </FlexRow>
      </MenuBlock>
      <MenuBlock classes={{ MenuBlockStyle: BottomButtonStyle }}>
        <Button classes={{ Button: DebuggingBtnStyle }} onClick={handleMaxLevelClick}>
          <Text inner='MAX LEVEL' fontSize={18} fontWeight={500} color='#fff' />
        </Button>
        <Button classes={{ Button: DebuggingBtnStyle }} onClick={handleBack}>
          <Text inner='Back' fontSize={18} fontWeight={500} color='#fff' />
        </Button>
      </MenuBlock>
    </Container>
  );
};

DebuggingSideController.propTypes = {
  handleClickedData: PropTypes.func,
};

export default DebuggingSideController;
