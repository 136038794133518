import { createContext, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { getLocalStorage, setLocalStorage } from '@utils';

const LanguageContext = createContext('ko');

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('ko');
  const setCurrentLanguage = (currentLanguage) => {
    setLanguage(currentLanguage);
    setLocalStorage('language', currentLanguage);
  };
  const contextValues = useMemo(() => ({ language, setCurrentLanguage }), [language, setLanguage]);

  useEffect(() => {
    const currentLanguage = getLocalStorage('language');

    if (!currentLanguage) {
      setLocalStorage('language', 'ko');
      return;
    }
    setLanguage(currentLanguage);
  }, []);

  return <LanguageContext.Provider value={contextValues}>{children}</LanguageContext.Provider>;
};

LanguageProvider.propTypes = {
  /** 자식 컴포넌트 */
  children: PropTypes.node,
};

export { LanguageContext, LanguageProvider };
