import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Skeleton from './Skeleton';

const Span = styled.span`
  ${({ classes }) => classes && classes.Span}
`;

const Container = forwardRef(
  ({ className, classes, innerHtml = null, isLoading = false, loader = {}, children, ...props }, ref) =>
    innerHtml && !isLoading ? (
      <Span
        ref={ref}
        className={className}
        classes={classes}
        dangerouslySetInnerHTML={{
          __html: innerHtml,
        }}
        {...props}
      />
    ) : (
      <Span ref={ref} className={className} classes={classes} {...props}>
        {isLoading ? (
          <Skeleton variant={loader.variant || 'text'} width={loader.width} height={loader.height} />
        ) : (
          children
        )}
      </Span>
    )
);

Container.propTypes = {
  /** Children */
  children: PropTypes.node,
  /** Loading 화면 표시 */
  isLoading: PropTypes.bool,
  /** Loader 설정 */
  loader: PropTypes.shape({
    variant: PropTypes.oneOf(['text', 'rectangular', 'rounded', 'circular']).isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  /** ClassName */
  className: PropTypes.string,
  /** 커스텀 스타일 오브젝트 */
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  /** Text 컴포넌트에 직접 넣을 innerHtml */
  innerHtml: PropTypes.string,
};

export default Container;
